import { call, takeLatest, put, delay } from "redux-saga/effects";
import { toast } from "react-toastify";

import { loginUserApi, signupUserApi } from "../../api/login";
import {
  ADD_ADMIN_MANAGER_ACCOUNT_REQUEST,
  ADD_ADMIN_MANAGER_ACCOUNT_SUCCESS,
  ADD_STORE_ACCOUNT_REQUEST,
  ADD_STORE_ACCOUNT_SUCCESS,
  RESET_ADD_ACCOUNT_SUCCESS_STATE,
  RESET_ADD_MANAGER_ACCOUNT_SUCCESS_STATE,
  // RESET_ADD_ACCOUNT_SUCCESS_STATE,
  USER_LOGIN_FAILURE,
  USER_LOGIN_REQUEST,
  USER_SIGNUP_FAILURE,
  USER_SIGNUP_REQUEST
} from "../types/auth";
import { addAdminStoreAccountApi } from "../../api/admin.addStoreAccount";
import { addAdminManagerAccountApi } from "../../api/admin.addManagerAccount";
import { parseJwt } from "../../utils/helpers";

function* watchAuthSaga() {
  yield takeLatest(USER_LOGIN_REQUEST, function* login({ payload }) {
    try {
      const history = payload.history;
      const data = yield call(loginUserApi, {
        userName: payload.userName,
        password: payload.password
      });
      const tokenObj = data.value;
      const parsedToken = parseJwt(tokenObj);
      if (["admin", "ceo"].includes(parsedToken?.Role?.toLowerCase())) {
        localStorage.setItem("user", tokenObj);
        if (parsedToken?.Role?.toLowerCase() === "ceo") {
          history.push("/daily-business-summary");
        } else {
          history.push("/");
        }
      } else {
        throw "Access Denied: Only Admins are Allowed to Access";
      }
    } catch (e) {
      yield put({ type: USER_LOGIN_FAILURE });
      toast.error(
        typeof e === "string"
          ? e
          : e?.response?.data?.value || "Username or Password is incorrect."
      );
    }
  });

  yield takeLatest(USER_SIGNUP_REQUEST, function* signup({ payload }) {
    try {
      const history = payload.history;
      yield call(signupUserApi, {
        userName: payload.userName,
        password: payload.password
      });
      history.push("/login");
    } catch (e) {
      yield put({ type: USER_SIGNUP_FAILURE });
      toast.error(e?.response?.data?.value || "Something went wrong");
    }
  });

  yield takeLatest(
    ADD_STORE_ACCOUNT_REQUEST,
    function* addStoreAccount({ payload }) {
      try {
        yield call(addAdminStoreAccountApi, payload);
        toast.success("Store account has been created successfully");
        yield put({ type: ADD_STORE_ACCOUNT_SUCCESS });
        yield delay(3000);
        yield put({ type: RESET_ADD_ACCOUNT_SUCCESS_STATE });
      } catch (e) {
        toast.error(
          e?.response?.data?.value ||
            "Something went wrong while creating store account"
        );
      }
    }
  );
  yield takeLatest(
    ADD_ADMIN_MANAGER_ACCOUNT_REQUEST,
    function* addManagerAccount({ payload }) {
      try {
        yield call(addAdminManagerAccountApi, payload);
        toast.success("Manager account has been created successfully");
        yield put({ type: ADD_ADMIN_MANAGER_ACCOUNT_SUCCESS });
        yield delay(3000);
        yield put({ type: RESET_ADD_MANAGER_ACCOUNT_SUCCESS_STATE });
      } catch (e) {
        toast.error(
          e?.response?.data?.value ||
            "Something went wrong while creating manager account"
        );
      }
    }
  );
}

export default function* authSaga() {
  yield call(watchAuthSaga);
}
