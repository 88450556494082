import { toast } from "react-toastify";
import { call, takeLatest, put } from "redux-saga/effects";
import { getAdminForecastAccuracyAPI } from "../../api/admin.forecastAccuracy";
import {
  GET_ADMIN_FORECAST_ACCURACY_FAILED,
  GET_ADMIN_FORECAST_ACCURACY_REQUEST,
  GET_ADMIN_FORECAST_ACCURACY_SUCCESS
} from "../types/admin.forecastAccuracy";

function* watchAdminForecastAccuracySaga() {
  /**
   * GET FORECAST ACCURACY
   */
  yield takeLatest(
    GET_ADMIN_FORECAST_ACCURACY_REQUEST,
    function* mForecastAccuracySaga({ payload }) {
      try {
        const data = yield call(getAdminForecastAccuracyAPI, payload);
        if (data) {
          yield put({
            type: GET_ADMIN_FORECAST_ACCURACY_SUCCESS,
            payload: data
          });
        }
      } catch (e) {
        console.log("e ===>", e);
        yield put({ type: GET_ADMIN_FORECAST_ACCURACY_FAILED });
        toast.error(
          "Something went wrong while fetching admin forecast accuracy"
        );
      }
    }
  );
}

export default function* adminForecastAccuracySaga() {
  yield call(watchAdminForecastAccuracySaga);
}
