import {
  GET_ADMIN_STORE_SUMMARY_FAILED,
  GET_ADMIN_STORE_SUMMARY_REQUEST,
  GET_ADMIN_STORE_SUMMARY_SUCCESS
} from "../types/admin.storeSummary";

const initialState = {
  adminStoreSummary: [],
  loadingAdminStoreSummary: false,
  selectedStore: null
};

export const adminStoreSummaryReducer = (
  state = initialState,
  { type, payload }
) => {
  switch (type) {
    case GET_ADMIN_STORE_SUMMARY_REQUEST:
      return {
        ...state,
        adminStoreSummary: [],
        loadingAdminStoreSummary: true
      };
    case GET_ADMIN_STORE_SUMMARY_SUCCESS:
      return {
        ...state,
        adminStoreSummary: payload,
        loadingAdminStoreSummary: false
      };
    case GET_ADMIN_STORE_SUMMARY_FAILED:
      return {
        ...state,
        adminStoreSummary: [],
        loadingAdminStoreSummary: false
      };
    default:
      return state;
  }
};
