import React, { useState, useEffect, useMemo, useRef } from "react";
import PropTypes from "prop-types";
import DatePicker from "react-datepicker";
import Sidebar from "react-sidebar";
import Select from "react-select";

import { connect } from "react-redux";
import { noop } from "lodash";
import SidebarContent from "../../components/SidebarContent/SidebarContent";

import { BsCaretLeftFill, BsCaretRightFill } from "react-icons/bs";

import Navbar from "../../components/Navbar";
import { isSidebarOpenSelector } from "../../redux/selectors/dashboard";
import { toggleSidebarAction } from "../../redux/actions/dashboard";
import OverviewCard from "../../components/OverviewCard/OverviewCard";
import { getDailyBusinessSummaryAction } from "../../redux/actions/dailyBusinessSummary";
import TableLoader from "../../components/SkeletonLoader/TableLoader";
import {
  dailyBusinessSummarySelector,
  isDailyBusinessSummaryLoadingSelector
} from "../../redux/selectors/dailyBusinessSummary";
import {
  addCommas,
  exportToExcel,
  getUptoZeroDecimalPoints
} from "../../utils/helpers";
import "./styles.scss";
import {
  DATA_SYNC_FREQUENCIES,
  SIDEBAR_STYLING
} from "../../configs/constants/constants";
import RenderNumber from "../../components/RenderNumber";
import { sumBy } from "lodash";
import {
  calculateAPC,
  calculateExpression
} from "../../api/dailyBusinessSummary";


const AdminForecastAccuracy = ({
  isSidebarOpen,
  toggleSidebarAction,
  isDailyBusinessSummaryLoading,
  getDailyBusinessSummary,
  dailyBusinessSummary
}) => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [intervalId, setIntervalId] = useState();
  const [dataSyncInterval, setDataSyncInterval] = useState(
    DATA_SYNC_FREQUENCIES[1]
  );
  const [divHeight, setDivHeight] = useState(111);
  const divRef = useRef(null);

  useEffect(() => {
    // Function to update the height state
    const updateDivHeight = () => {
      if (divRef.current) {
        const height = divRef.current.clientHeight + 40;
        setDivHeight(12);
        console.log(height);
      }
    };

    // Call the function initially and whenever the window resizes
    updateDivHeight();
    window.addEventListener("scroll", updateDivHeight);

    // Cleanup function to remove the event listener
    return () => {
      window.removeEventListener("scroll", updateDivHeight);
    };
  }, []);



  useEffect(() => {
    getDailyBusinessSummary({ date: new Date() });
    resyncDailyBusinessSummaryOnInterval();
    return () => {
      clearInterval(intervalId);
    };
  }, [selectedDate]);

  Date.prototype.addDays = function add(days) {
    const date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
  };

  const resyncDailyBusinessSummaryOnInterval = () => {
    clearInterval(intervalId);
    const currentIntervalId = setInterval(() => {
      getDailyBusinessSummary({ date: selectedDate, skipLoader: true });
    }, dataSyncInterval?.value);
    setIntervalId(currentIntervalId);
  };

  const goToPrevDate = () => {
    setSelectedDate(new Date());
  };

  const goToNextDate = () => {
    setSelectedDate(new Date());
    setSelectedDate(new Date());
  };

  const { storeCountSum, twSalesSum, lwSalesSum, salesSesAvg, apcTW, apcLW } =
    useMemo(() => {
      if (dailyBusinessSummary.length === 0) {
        return {};
      }
      const totalSalesTW = sumBy(dailyBusinessSummary, "todaySales");
      const totalSalesLW = sumBy(dailyBusinessSummary, "lastWeekSales");
      const apcTW = sumBy(dailyBusinessSummary, "todayTx");
      const apcLW = sumBy(dailyBusinessSummary, "lastWeekTx");
      return {
        storeCountSum: sumBy(dailyBusinessSummary, "storeCount"),
        twSalesSum: sumBy(dailyBusinessSummary, "todaySales"),
        lwSalesSum: sumBy(dailyBusinessSummary, "lastWeekSales"),
        salesSesAvg: calculateExpression(totalSalesTW, totalSalesLW),
        apcTW: calculateAPC(totalSalesTW, apcTW),
        apcLW: calculateAPC(totalSalesLW, apcLW)
      };
    }, [dailyBusinessSummary]);

  return (
    <div>
      {isSidebarOpen && (
        <Sidebar
          sidebar={<SidebarContent />}
          open={isSidebarOpen}
          pullRight
          styles={{
            sidebar: SIDEBAR_STYLING
          }}
          shadow={false}
        />
      )}
      <Navbar toggleSidebarAction={toggleSidebarAction} isStickyNavbar={true} />
      <div
        ref={divRef}
        className="d-flex justify-content-start flex-wrap daily-business-summary__row-gap-24 daily-business-summary__layout_size daily-business-summary__card-wrapper"
      >
        <OverviewCard
          title="Total Store"
          setActiveCard={() => { }}
          count={storeCountSum}
          isLoading={isDailyBusinessSummaryLoading}
          hidePointer={true}
        />
        <OverviewCard
          title="Current Sale"
          setActiveCard={() => { }}
          count={addCommas(getUptoZeroDecimalPoints(twSalesSum))}
          isLoading={isDailyBusinessSummaryLoading}
          hidePointer={true}
        />
        <OverviewCard
          title="SDLW"
          setActiveCard={() => { }}
          count={addCommas(getUptoZeroDecimalPoints(lwSalesSum))}
          isLoading={isDailyBusinessSummaryLoading}
          hidePointer={true}
        />
        <OverviewCard
          title="Current-APC"
          setActiveCard={() => { }}
          count={addCommas(getUptoZeroDecimalPoints(apcTW))}
          isLoading={isDailyBusinessSummaryLoading}
          hidePointer={true}
          countClassName={apcTW >= 0 ? "text-success" : "text-danger"}
        />
        <OverviewCard
          title="SDLW-APC"
          setActiveCard={() => { }}
          count={addCommas(getUptoZeroDecimalPoints(apcLW))}
          isLoading={isDailyBusinessSummaryLoading}
          hidePointer={true}
          countClassName={apcLW >= 0 ? "text-success" : "text-danger"}
        />
        <OverviewCard
          title="SALE-SEQ"
          setActiveCard={() => { }}
          count={addCommas(getUptoZeroDecimalPoints(salesSesAvg))}
          isLoading={isDailyBusinessSummaryLoading}
          hidePointer={true}
          countClassName={salesSesAvg >= 0 ? "text-success" : "text-danger"}
        />

      </div>
      <div className="d-flex justify-content-center align-items-center">
        <table
          className="table table-striped table-bordered daily-business-summary__table-container"
          style={{ fontSize: "14px" }}
        >
          <thead
            className="daily-business-summary__table-header"
            style={{ top: `${divHeight}rem` }}
          >
            <tr>
              <th>State-Name</th>
              <th>Store Count</th>
              <th>Current Sale</th>
              <th>SDLW</th>
              <th>Current ADS</th>
              <th>SDLW ADS</th>
              <th>Current-APC</th>
              <th>SDLW-APC</th>
              <th>Current TXN</th>
              <th>SDLW Txn</th>
              <th>SALE SEQ</th>
              <th>APC-SEQ</th>
              <th>TXN-SEQ</th>
            </tr>
          </thead>
          {isDailyBusinessSummaryLoading ? (
            <TableLoader noOfColumn={13} noOfRow={15} />
          ) : (
            <tbody>
              {dailyBusinessSummary.length ? (
                dailyBusinessSummary.map((item, index) => (
                  <tr key={index}>
                    <td>{item.state}</td>
                    <td style={{ textAlign: "right" }}>{item.storeCount}</td>
                    <td style={{ textAlign: "right" }}>
                      {addCommas(getUptoZeroDecimalPoints(item.todaySales))}
                    </td>
                    <td style={{ textAlign: "right" }}>
                      {addCommas(getUptoZeroDecimalPoints(item.lastWeekSales))}
                    </td>
                    <td style={{ textAlign: "right" }}>
                      {addCommas(getUptoZeroDecimalPoints(item.currentADS))}
                    </td>
                    <td style={{ textAlign: "right" }}>
                      {addCommas(getUptoZeroDecimalPoints(item.sdlwADS))}
                    </td>
                    <td style={{ textAlign: "right" }}>
                      {addCommas(getUptoZeroDecimalPoints(item.todayAPC))}
                    </td>
                    <td style={{ textAlign: "right" }}>
                      {addCommas(getUptoZeroDecimalPoints(item.lastWeekAPC))}
                    </td>
                    <td style={{ textAlign: "right" }}>
                      {addCommas(getUptoZeroDecimalPoints(item.todayTx))}
                    </td>
                    <td style={{ textAlign: "right" }}>
                      {addCommas(getUptoZeroDecimalPoints(item.lastWeekTx))}
                    </td>
                    <td style={{ textAlign: "right" }}>
                      <RenderNumber
                        value={addCommas(
                          getUptoZeroDecimalPoints(item.salesSeq)
                        )}
                      />
                    </td>
                    <td style={{ textAlign: "right" }}>
                      <RenderNumber
                        value={addCommas(getUptoZeroDecimalPoints(item.apcSeq))}
                      />
                    </td>
                    <td style={{ textAlign: "right" }}>
                      <RenderNumber
                        value={addCommas(getUptoZeroDecimalPoints(item.txSeq))}
                      />
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={11} align="center">
                    No data available
                  </td>
                </tr>
              )}
            </tbody>
          )}
        </table>
      </div>
      <div className="d-flex align-items-center justify-content-between mt-3 daily-business-summary__layout_size">
        <div className="d-flex align-items-center">
          <div className="d-flex align-items-center daily-business-summary__date-picker">
            <span
              className="daily-business-summary__date-picker-arrows"
              onClick={goToPrevDate}
            >
              <BsCaretLeftFill />
            </span>
            <DatePicker
              className="form-control forecast-header__date-picker"
              readOnly={true}
              disabled={true}
              selected={selectedDate}
              onChange={(update) => {
                setSelectedDate(update);
              }}
              onChangeRaw={(e) => e.preventDefault()}
              maxDate={new Date()}
              minDate={new Date()}
            />
            <span
              className="daily-business-summary__date-picker-arrows"
              onClick={goToNextDate}
            >
              <BsCaretRightFill />
            </span>
          </div>
          <div>
            <Select
              value={dataSyncInterval}
              options={DATA_SYNC_FREQUENCIES}
              className="daily-business-summary__dropdown"
              style={{ zIndex: 9999 }}
              onChange={(value) => setDataSyncInterval(value)}
            />
          </div>
        </div>
        <button
          disabled={
            dailyBusinessSummary.length === 0 || isDailyBusinessSummaryLoading
          }
          className="btn btn-dark"
          onClick={() => {
            // CUSTOM HEADER
            const header = {
              state: "State-Name",
              storeCount: "Store Count",
              todaySales: "TW-SALE",
              lastWeekSales: "LW-SALE",
              todayADS: "TW-ADS",
              lastWeekADS: "LW-ADS",
              todayAPC: "TW-APC",
              lastWeekAPC: "LW-APC",
              todayTx: "TW TXN",
              lastWeekTx: "LW TXN",
              salesSeq: "SALE SEQ",
              apcSeq: "APC-SEQ",
              txSeq: "TXN-SEQ"
            };

            const data = dailyBusinessSummary.map((obj) => {
              const newObj = {};
              Object.keys(header).forEach((item) => {
                newObj[header[item]] = obj[item];
              });
              return newObj;
            });

            exportToExcel(data, `Daily_Business_Summary_${selectedDate}.xlsx`);
          }}
        >
          Export to Excel
        </button>
      </div>
    </div>
  );
};

AdminForecastAccuracy.defaultProps = {
  toggleSidebarAction: noop,
  isSidebarOpen: false,
  forecastAccuracy: [],
  isForecastAccuracyLoading: false
};

AdminForecastAccuracy.propTypes = {
  toggleSidebarAction: PropTypes.func,
  isSidebarOpen: PropTypes.bool
};

const mapStateToProps = (state) => ({
  isSidebarOpen: isSidebarOpenSelector(state),
  dailyBusinessSummary: dailyBusinessSummarySelector(state),
  isDailyBusinessSummaryLoading: isDailyBusinessSummaryLoadingSelector(state)
});

const mapDispatchToProps = {
  toggleSidebarAction: toggleSidebarAction,
  getDailyBusinessSummary: getDailyBusinessSummaryAction
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminForecastAccuracy);
