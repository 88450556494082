import React from "react";
import { Button } from "react-bootstrap";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import TableLoader from "../../components/SkeletonLoader/TableLoader";
import TableRow from "./TableRow";
import {
  updateAdminUserEmailAction,
  updateAdminUserPasswordAction
} from "../../redux/actions/admin.userList";
import "./AdminJobStatusTable.scss";
import useTablePagination from "../../customHooks/TablePagination";

const headerStyle = {
  backgroundColor: "#0A4091",
  color: "#fff",
  padding: "10px",
  textAlign: "left"
};

const AdminJobStatusTable = ({ isPageLoading, tableInitialData, jobType }) => {
  const {
    currentPage,
    totalPages,
    getCurrentPageData,
    nextPage,
    prevPage,
    isFirstPage,
    isLastPage
  } = useTablePagination(tableInitialData, 50);

  const tableData = getCurrentPageData();
  return (
    <>
      <table style={{ width: "100%", borderCollapse: "collapse" }}>
        <thead>
          <tr>
            <th style={headerStyle}>Store Code</th>
            <th style={headerStyle}>Created At</th>
            <th style={headerStyle}>Success</th>
            <th style={headerStyle}>Action</th>
          </tr>
        </thead>
        {isPageLoading ? (
          <TableLoader noOfColumn={4} noOfRow={20} />
        ) : (
          <tbody>
            {tableData.map(({ storeCode, createdDate, success }) => (
              <TableRow
                key={`${storeCode}_${createdDate}`}
                storeCode={storeCode}
                createdDate={createdDate}
                success={success}
                jobType={jobType}
              />
            ))}
          </tbody>
        )}
      </table>
      {tableData && tableData.length ? (
        <div className="mt-3 mb-5 d-flex justify-content-center align-items-center">
          <Button variant="dark" disabled={isFirstPage} onClick={prevPage}>
            Previous Page
          </Button>
          <span className="mx-2">
            {currentPage}/{totalPages}
          </span>
          <Button variant="dark" disabled={isLastPage} onClick={nextPage}>
            Next Page
          </Button>
        </div>
      ) : null}
    </>
  );
};

AdminJobStatusTable.propTypes = {
  tableInitialData: PropTypes.array,
  jobType: PropTypes.string,
  isPageLoading: PropTypes.bool
};

const mapDispatchToProps = {
  updateAdminUserPassword: updateAdminUserPasswordAction,
  updateAdminUserEmail: updateAdminUserEmailAction
};

export default connect(null, mapDispatchToProps)(AdminJobStatusTable);
