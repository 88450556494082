import React, { useState } from "react";
import PropTypes, { bool } from "prop-types";
import { Button } from "react-bootstrap";
import { RiDeleteBin7Line } from "react-icons/ri";
import { noop } from "lodash";
import { connect } from "react-redux";
import useTablePagination from "../../customHooks/TablePagination";
import AddStoreUserModal from "./AddStoreUserModal/AddStoreUserModal";
import DeleteStoreUserModal from "./DeleteStoreUserModal/DeleteStoreUserModal";
import TableLoader from "../../components/SkeletonLoader/TableLoader";
import { deleteAdminStoreUserAction } from "../../redux/actions/admin.storeUsers";
import "./AdminStoreUsersTable.scss";
// import EditItemModal from "./EditItemModal/EditItemModal";

const headerStyle = {
  backgroundColor: "#0A4091",
  color: "#fff",
  padding: "10px",
  textAlign: "left"
};

const cellStyleCenter = {
  border: "1px solid #ddd",
  padding: "10px",
  textAlign: "center"
};

const cellStyleLeft = {
  border: "1px solid #ddd",
  padding: "10px",
  textAlign: "left"
};

const AdminStoresTable = ({
  adminStoreUsers,
  deleteAdminStoreUserAction,
  isAdminStoreUsersLoading,
  isAddingNewStore,
  setIsAddingNewStore
}) => {
  const [isEditStoreModalOpen, setIsEditStoreModalOpen] = useState(false);
  const [StoreSelectedForEdit, setStoreSelectedForEdit] = useState({});
  const [isDeleteStoreModalOpen, setIsDeleteStoreModalOpen] = useState(false);
  const [userSelectedToDelete, setUserSelectedToDelete] = useState("");

  const {
    currentPage,
    totalPages,
    getCurrentPageData,
    nextPage,
    prevPage,
    isFirstPage,
    isLastPage
  } = useTablePagination(adminStoreUsers, 10);

  const tableData = getCurrentPageData();
  const handleEditStoreModalClose = () => {
    setIsEditStoreModalOpen(false);
    setStoreSelectedForEdit({});
    setIsAddingNewStore(false);
  };

  const handleStoreUserDelete = () => {
    deleteAdminStoreUserAction(userSelectedToDelete);
    setIsDeleteStoreModalOpen(false);
  };

  const handleDeleteStoreModalOpen = (storeCode, email) => {
    setIsDeleteStoreModalOpen(true);
    setUserSelectedToDelete({ storeCode, email });
  };

  const handleDeleteStoreModalClose = () => {
    setIsDeleteStoreModalOpen(false);
    setUserSelectedToDelete("");
  };

  return (
    <>
      <table style={{ width: "100%", borderCollapse: "collapse" }}>
        <thead>
          <tr>
            <th style={headerStyle}>Store Code</th>
            <th style={headerStyle}>Email</th>
            <th style={headerStyle}>Action</th>
          </tr>
        </thead>
        {isAdminStoreUsersLoading ? (
          <TableLoader noOfColumn={3} noOfRow={10} />
        ) : (
          <tbody>
            {tableData.map(({ storeCode, email }) => (
              <tr key={`${email}_${storeCode}`}>
                <td style={cellStyleCenter}>{storeCode}</td>
                <td style={cellStyleLeft}>{email}</td>
                <td style={cellStyleCenter}>
                  <span className="m-1 admin-store-table__delete_icon">
                    <RiDeleteBin7Line
                      onClick={() => {
                        handleDeleteStoreModalOpen(storeCode, email);
                      }}
                    />
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        )}
      </table>
      {tableData && tableData.length ? (
        <div className="mt-3 d-flex justify-content-center align-items-center">
          <Button variant="dark" disabled={isFirstPage} onClick={prevPage}>
            Previous Page
          </Button>
          <span className="mx-2">
            {currentPage}/{totalPages}
          </span>
          <Button variant="dark" disabled={isLastPage} onClick={nextPage}>
            Next Page
          </Button>
        </div>
      ) : null}
      {(isEditStoreModalOpen || isAddingNewStore) && (
        <AddStoreUserModal
          isEditStoreModalOpen={isEditStoreModalOpen || isAddingNewStore}
          handleModalClose={handleEditStoreModalClose}
          store={StoreSelectedForEdit}
          isAddingNewStore={isAddingNewStore}
        />
      )}
      {isDeleteStoreModalOpen && (
        <DeleteStoreUserModal
          isOpen={isDeleteStoreModalOpen}
          onConfirm={handleStoreUserDelete}
          onRequestClose={handleDeleteStoreModalClose}
        />
      )}
    </>
  );
};

AdminStoresTable.defaultProps = {
  adminStoreUsers: [],
  deleteAdminStoreUserAction: noop,
  isAdminStoreUsersLoading: bool,
  isAddingNewStore: bool,
  setIsAddingNewStore: noop
};

AdminStoresTable.propTypes = {
  adminStoreUsers: PropTypes.array,
  deleteAdminStoreUserAction: PropTypes.func,
  isAdminStoreUsersLoading: PropTypes.bool,
  isAddingNewStore: PropTypes.bool,
  setIsAddingNewStore: PropTypes.func
};

const mapDispatchToProps = {
  deleteAdminStoreUserAction: deleteAdminStoreUserAction
};

export default connect(null, mapDispatchToProps)(AdminStoresTable);
