import axiosInstance from "../configs/axios";
import { getNumberInTwoDigit, getTime } from "../utils/helpers";
import { v4 as uuidv4 } from "uuid";

const GET_ACTIVE_INACTIVE_STORES_ENDPOINT =
  "/api/admin/sales/ActiveInActiveStore";
const GET_STORE_HISTORY_ENDPOINT = "/api/admin/sales/StoreSalesHistory";
const GET_STORES_LAST_TX_ENDPOINT = "/api/admin/sales/StoresLastTx";
const GET_STORES_CURRENT_SALES_ENDPOINT = "/api/admin/sales/StoresCurrentSales";
const GET_TOTAL_STORE_SALE_ENDPOINT = "/api/admin/sales/StoreSales";
const GET_AVAILABLE_STORE_CODES = "/api/admin/auth/store";

function ActiveInactiveStore(storeSummary) {
  this.activeStoresCount = storeSummary.active;
  this.inactiveStoresCount = storeSummary.inActive;
  this.totalStore = storeSummary.totalStore;
  this.activeStores = storeSummary.activeStores;
  this.inactiveStores = storeSummary.inActiveStore;
}

export const getActiveInactiveStoresApi = (payload) => {
  const date = payload ? payload.date : new Date();
  const year = date.getFullYear();
  const month = getNumberInTwoDigit(date.getMonth() + 1);
  const d = getNumberInTwoDigit(date.getDate());
  const hours = getNumberInTwoDigit(date.getHours());
  const minutes = getNumberInTwoDigit(date.getMinutes());
  const seconds = getNumberInTwoDigit(date.getSeconds());
  return axiosInstance
    .get(
      GET_ACTIVE_INACTIVE_STORES_ENDPOINT +
        `?currentDate=${year}-${month}-${d} ${hours}:${minutes}:${seconds}`
    )
    .then(({ data }) => new ActiveInactiveStore(data));
};

function StoreSalesHistory(history) {
  this.storeCode = history.storeCode;
  this.actualSales = history.actualSales;
  this.adminSales = history.adminSales;
}

export const getStoreSalesHistoryApi = () => {
  return axiosInstance
    .get(GET_STORE_HISTORY_ENDPOINT)
    .then(({ data }) => data.map((obj) => new StoreSalesHistory(obj)));
};

function storeLastTx(txObj) {
  this.id = uuidv4();
  this.storeCode = txObj.storeCode;
  this.time = getTime(txObj.time);
  this.address = txObj.address;
}

export const getStoreLastTxApi = () => {
  return axiosInstance
    .get(GET_STORES_LAST_TX_ENDPOINT)
    .then(({ data }) => data.map((obj) => new storeLastTx(obj)));
};

function storeCurrentSale(saleObj) {
  this.storeCode = saleObj.storeCode;
  this.actualSales = saleObj.actualSales;
  this.forecastSales = saleObj.forcastSales;
  this.adminSales = saleObj.managerSales;
  this.adminAccuracy = saleObj.managerAccuracy;
  this.systemAccuracy = saleObj.systemAccuracy;
  this.systemVariance = saleObj.systemVariance;
  this.adminVariance = saleObj.managerVariance;
}

export const getStoresCurrentSalesApi = (payload) => {
  const date = payload ? payload.date : new Date();
  const year = date.getFullYear();
  const month = getNumberInTwoDigit(date.getMonth() + 1);
  const d = getNumberInTwoDigit(date.getDate());
  const hours = getNumberInTwoDigit(date.getHours());
  const minutes = getNumberInTwoDigit(date.getMinutes());
  const seconds = getNumberInTwoDigit(date.getSeconds());

  return axiosInstance
    .get(
      GET_STORES_CURRENT_SALES_ENDPOINT +
        `?currentDate=${year}-${month}-${d} ${hours}:${minutes}:${seconds}`
    )
    .then(({ data }) => data.map((obj) => new storeCurrentSale(obj)));
};

export const getTotalStoreSalesApi = (payload) => {
  const date = payload ? payload.date : new Date();
  const year = date.getFullYear();
  const month = getNumberInTwoDigit(date.getMonth() + 1);
  const d = getNumberInTwoDigit(date.getDate());
  const hours = getNumberInTwoDigit(date.getHours());
  const minutes = getNumberInTwoDigit(date.getMinutes());
  const seconds = getNumberInTwoDigit(date.getSeconds());

  return axiosInstance
    .get(
      GET_TOTAL_STORE_SALE_ENDPOINT +
        `?currenDate=${year}-${month}-${d} ${hours}:${minutes}:${seconds}`
    )
    .then(({ data }) => data);
};

export const getAvailableStoreCodesApi = () => {
  return axiosInstance.get(GET_AVAILABLE_STORE_CODES).then(({ data }) => data);
};
