import React, { useState } from "react";
import PropTypes from "prop-types";

import PageLoader from "../../components/PageLoader/PageLoader";
import Navbar from "../../components/Navbar";
import Sidebar from "react-sidebar";
import SidebarContent from "../../components/SidebarContent/SidebarContent";
import { connect } from "react-redux";
import { isSidebarOpenSelector } from "../../redux/selectors/dashboard";
import { toggleSidebarAction } from "../../redux/actions/dashboard";
import DashboardOverview from "../../containers/DashboardOverview";

import "./AdminDashboard.scss";
// import BarChartForAdminDashboard from "../../containers/BarChartForAdminDashboard/BarChartForAdminDashboard";
import TableForAdminDashboard from "../../containers/TableForAdminDashboard/TableForAdminDashboard";
import StoreCurrentSalesTableForAdminDashboard from "../../containers/StoreCurrentSalesTableForAdminDashboard/StoreCurrentSalesTableForAdminDashboard";
import { SIDEBAR_STYLING } from "../../configs/constants/constants";

const AdminDashboard = ({ isSidebarOpen, toggleSidebarAction }) => {
  const [isPageLoading, setIsPageLoading] = useState(false);

  // eslint-disable-next-line no-console
  console.log("setIsPageLoading ===>", setIsPageLoading);

  return (
    <div className="admin-dashboard">
      {isPageLoading && <PageLoader />}
      <Navbar toggleSidebarAction={toggleSidebarAction} />
      {isSidebarOpen && (
        <Sidebar
          sidebar={<SidebarContent />}
          open={isSidebarOpen}
          pullRight
          styles={{ sidebar: SIDEBAR_STYLING }}
          shadow={false}
        />
      )}
      <main>
        <div className="mt-5 container">
          <DashboardOverview />
        </div>
        {/* <div className="admin-dashboard__barchart-container container mt-5">
            <BarChartForAdminDashboard />
        </div> */}

        <div className="container mt-5">
          <StoreCurrentSalesTableForAdminDashboard />
        </div>
        <div className="container mt-5">
          <TableForAdminDashboard />
        </div>
      </main>
    </div>
  );
};

AdminDashboard.defaultProps = {
  isSidebarOpen: false,
  toggleSidebarAction: () => {}
};

AdminDashboard.propTypes = {
  isSidebarOpen: PropTypes.bool,
  toggleSidebarAction: PropTypes.func
};

const mapStateToProps = (state) => ({
  isSidebarOpen: isSidebarOpenSelector(state)
});

const mapDispatchToProps = {
  toggleSidebarAction: toggleSidebarAction
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminDashboard);
