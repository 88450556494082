import { takeLatest, call, put } from "redux-saga/effects";
import { toast } from "react-toastify";
import {
  GET_ADMIN_USER_LIST_FAILURE,
  GET_ADMIN_USER_LIST_REQUEST,
  GET_ADMIN_USER_LIST_SUCCESS,
  UPDATE_ADMIN_USER_EMAIL_REQUEST,
  UPDATE_ADMIN_USER_PASSWORD_REQUEST,
  UPDATE_ADMIN_USER_PRINT_STATUS,
  UPDATE_ADMIN_USER_STATUS
} from "../types/admin.userList";
import {
  getAdminUserListApi,
  updateAdminUserEmailApi,
  updateAdminUserPasswordAPI,
  updateUserPrintStatusApi,
  updateUserStatusApi
} from "../../api/admin.userList";

function* watchAdminUserListSaga() {
  yield takeLatest(
    GET_ADMIN_USER_LIST_REQUEST,
    function* getAdminUserList({ payload }) {
      try {
        const data = yield call(getAdminUserListApi, payload);
        if (data) {
          yield put({ type: GET_ADMIN_USER_LIST_SUCCESS, payload: data });
        }
      } catch (e) {
        yield put({ type: GET_ADMIN_USER_LIST_FAILURE });
        toast.error("Something went wrong while fetching user list");
      }
    }
  );
  yield takeLatest(
    UPDATE_ADMIN_USER_STATUS,
    function* updateAdminUserStatus({ payload }) {
      try {
        yield call(updateUserStatusApi, payload);
        try {
          const newUserList = yield call(getAdminUserListApi, {
            startIndex: payload.startIndex
          });
          toast.success(
            `User has been ${
              payload.isEnable ? "disabled" : "enabled"
            } successfully`
          );
          if (newUserList) {
            yield put({
              type: GET_ADMIN_USER_LIST_SUCCESS,
              payload: newUserList
            });
          }
        } catch (e) {
          yield put({ type: GET_ADMIN_USER_LIST_FAILURE });
          toast.error("Something went wrong while fetching store data");
        }
      } catch (e) {
        toast.error("Something went wrong while updating store");
      }
    }
  );
  yield takeLatest(
    UPDATE_ADMIN_USER_PRINT_STATUS,
    function* updateAdminUserPrintStatus({ payload }) {
      try {
        try {
          yield call(updateUserPrintStatusApi, payload);
          toast.success(
            `Print has been ${
              payload.isEnable ? "disabled" : "enabled"
            } successfully for the user`
          );
        } catch (error) {
          toast.error("Something went wrong while updating user settings");
        }
        const newUserList = yield call(getAdminUserListApi, {
          startIndex: payload.startIndex
        });
        if (newUserList) {
          yield put({
            type: GET_ADMIN_USER_LIST_SUCCESS,
            payload: newUserList
          });
        }
      } catch (e) {
        toast.error("Something went wrong while updating store");
        yield put({ type: GET_ADMIN_USER_LIST_FAILURE });
      }
    }
  );
  yield takeLatest(
    UPDATE_ADMIN_USER_PASSWORD_REQUEST,
    function* updateAdminUserPassword({ payload }) {
      try {
        yield call(updateAdminUserPasswordAPI, payload);
        toast.success(`Password has been reset successfully`);
      } catch (e) {
        toast.error("Something went wrong while updating password");
      }
    }
  );
  yield takeLatest(
    UPDATE_ADMIN_USER_EMAIL_REQUEST,
    function* updateAdminUserEmail({ payload }) {
      try {
        yield call(updateAdminUserEmailApi, payload);
        toast.success(`Email has been updated successfully`);
      } catch (e) {
        toast.error("Something went wrong while updating email");
      }
    }
  );
}

export default function* adminUserListSaga() {
  yield call(watchAdminUserListSaga);
}
