import { remove, find } from "lodash";
import { sortBatch } from "../../utils/helpers";
import {
  GET_BATCH_SUCCESS,
  GET_WASTE_REASON_SUCCESS,
  OPEN_UPDATE_BATCH_MODAL,
  REMOVE_BATCH_FROM_STORE,
  SAVE_TREND_DATA,
  UPDATE_BATCH,
  UPDATE_NEW_BATCH_DATA,
  UPDATE_ITEM_COLLAPSE_MENU,
  SAVE_TREND_TIME,
  OPEN_ADD_BATCH_MODAL,
  GET_BATCH,
  GET_BATCH_FAILURE,
  GET_ACTIVE_ITEMS_SUCCESS,
  SAVE_SIGNALR_CONNECTION,
  TOGGLE_SIDEBAR
} from "../types/dashboard";
import { CONFIRM_COMPLETE_SUCCESS } from "../types/updateBatch";

const initialState = {
  openAddBatchModal: false,
  openUpdateBatchModal: false,
  selectedBatchItem: {},
  isBatchLoading: false,
  batch: [],
  trend: [],
  wasteReasons: [],
  activeItemCollapseMenu: 0,
  trendTime: new Date(),
  batchDetailsToAdd: {},
  activeItems: [],
  itemDropQty: [],
  signalR: null,
  isSidebarOpen: false
};

export const dashboardReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case OPEN_ADD_BATCH_MODAL:
      return {
        ...state,
        openAddBatchModal: payload.open,
        batchDetailsToAdd: payload.batchDetailsToAdd
          ? payload.batchDetailsToAdd
          : {}
      };
    case OPEN_UPDATE_BATCH_MODAL:
      return {
        ...state,
        openUpdateBatchModal: payload.open,
        selectedBatchItem: payload.batchItem
      };
    case GET_BATCH:
      return { ...state, isBatchLoading: true };
    case GET_BATCH_SUCCESS:
      return { ...state, batch: payload, isBatchLoading: false };
    case GET_BATCH_FAILURE:
      return { ...state, isBatchLoading: false };
    case SAVE_TREND_DATA:
      return { ...state, trend: payload };
    case UPDATE_NEW_BATCH_DATA: {
      // GET ative item by itemId
      const activeItems = JSON.parse(JSON.stringify(state.activeItems));
      const activeItem = find(activeItems, { itemId: payload.itemId });
      const item = find(activeItem.data, { batchNo: payload.batchNo });
      if (item) {
        // Replace item from array
      } else {
        activeItem.data.push(payload);
      }
      activeItem.data = sortBatch(activeItem.data);
      return { ...state, activeItems: activeItems };
    }
    case REMOVE_BATCH_FROM_STORE: {
      const activeItems = JSON.parse(JSON.stringify(state.activeItems));
      const activeItem = find(activeItems, { itemId: payload.itemId });
      remove(activeItem.data, { batchNo: payload.batchNo });
      const sortedData = sortBatch([...activeItem.data]);
      activeItem.data = sortedData;
      return { ...state, activeItems: activeItems };
    }
    case GET_WASTE_REASON_SUCCESS:
      return { ...state, wasteReasons: payload };
    case UPDATE_BATCH: {
      const activeItems = JSON.parse(JSON.stringify(state.activeItems));
      const activeItem = find(activeItems, { itemId: payload.itemId });
      remove(activeItem.data, { batchNo: payload.batchNo });
      const sortedData = sortBatch([...activeItem.data, payload]);
      activeItem.data = sortedData;
      return { ...state, activeItems: activeItems };
    }
    case UPDATE_ITEM_COLLAPSE_MENU:
      return { ...state, activeItemCollapseMenu: payload };
    case SAVE_TREND_TIME:
      return { ...state, trendTime: payload };
    case CONFIRM_COMPLETE_SUCCESS: {
      const batchCopy = JSON.parse(JSON.stringify(state.batch));
      batchCopy.forEach((batch) => {
        if (batch.batchNo === payload.batchId) {
          batch.completed = true;
        }
      });
      return {
        ...state,
        batch: batchCopy
      };
    }
    case GET_ACTIVE_ITEMS_SUCCESS: {
      return { ...state, activeItems: payload };
    }
    case SAVE_SIGNALR_CONNECTION: {
      return { ...state, signalR: payload };
    }
    case TOGGLE_SIDEBAR: {
      return { ...state, isSidebarOpen: !state.isSidebarOpen };
    }
    default:
      return state;
  }
};
