export const headerStyle = {
  backgroundColor: "#0A4091",
  color: "#fff",
  padding: "10px",
  textAlign: "left"
};

export const cellStyleRight = {
  border: "1px solid #ddd",
  padding: "10px",
  textAlign: "right"
};

export const cellStyleCenter = {
  border: "1px solid #ddd",
  padding: "10px",
  textAlign: "center"
};

export const cellStyleLeft = {
  border: "1px solid #ddd",
  padding: "10px",
  textAlign: "left"
};
