import {
  GET_ADMIN_STORE_USERS_FAILURE,
  GET_ADMIN_STORE_USERS_REQUEST,
  GET_ADMIN_STORE_USERS_SUCCESS
} from "../types/admin.storeUsers";

const initialState = {
  loadingAdminStoreUsers: false,
  users: []
};

export const adminStoreUsersReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_ADMIN_STORE_USERS_REQUEST:
      return {
        ...state,
        loadingAdminStoreUsers: true,
        users: []
      };
    case GET_ADMIN_STORE_USERS_SUCCESS:
      return { ...state, loadingAdminStoreUsers: false, users: payload };
    case GET_ADMIN_STORE_USERS_FAILURE:
      return { ...state, loadingAdminStoreUsers: false, users: payload };
    default:
      return state;
  }
};
