import {
  DELETE_ADMIN_STORE_REQUEST,
  GET_ADMIN_STORES_REQUEST,
  UPDATE_ADMIN_STORE_REQUEST
} from "../types/admin.stores";

export const getAdminStoresAction = () => {
  return {
    type: GET_ADMIN_STORES_REQUEST
  };
};

export const updateAdminStoreAction = (payload) => {
  return {
    type: UPDATE_ADMIN_STORE_REQUEST,
    payload
  };
};


export const deleteAdminStoreAction = (payload) => {
  return {
    type: DELETE_ADMIN_STORE_REQUEST,
    payload
  };
};
