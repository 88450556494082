import React from "react";

const RenderNumber = ({ value }) => {
  return (
    <span className={`${value >= 0 ? "text-success" : "text-danger"}`}>
      {value}
    </span>
  );
};

export default RenderNumber;
