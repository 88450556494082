import {
  ADD_ADMIN_MANAGER_ACCOUNT_REQUEST,
  ADD_STORE_ACCOUNT_REQUEST,
  USER_LOGIN_REQUEST,
  USER_SIGNUP_REQUEST
} from "../types/auth";

export const loginAction = (payload) => ({
  type: USER_LOGIN_REQUEST,
  payload
});

export const signupAction = (payload) => ({
  type: USER_SIGNUP_REQUEST,
  payload
});

export const addAdminAccountAction = (payload) => {
  return {
    type: ADD_STORE_ACCOUNT_REQUEST,
    payload
  };
};

export const addAdminManagerAccountAction = (payload) => {
  return {
    type: ADD_ADMIN_MANAGER_ACCOUNT_REQUEST,
    payload
  };
};
