import React, { useEffect } from "react";
import PropTypes, { object } from "prop-types";
import { noop } from "lodash";
import { connect } from "react-redux";
import Sidebar from "react-sidebar";

import Navbar from "../../components/Navbar";
import PageLoader from "../../components/PageLoader/PageLoader";
import SidebarContent from "../../components/SidebarContent/SidebarContent";

import {
  reset15MinSalesAction,
  toggleHistoricalModalAction
} from "../../redux/actions/forecast";
import {
  getIsHistoricalModalOpen,
  isGraphDataLoadingSelector,
  isHistoricalDataLoadingSelector,
  isTableDataLoadingSelector
} from "../../redux/selectors/forecast";
import { isSidebarOpenSelector } from "../../redux/selectors/dashboard";
import { toggleSidebarAction } from "../../redux/actions/dashboard";

import "./AdminForecastEvaluator.scss";
import AdminForecastHeader from "../../components/AdminForecastHeader/AdminForecastHeader";
import {
  getAdminForecastAction,
  updateAdminForecastDateAction,
  updateAdminForecastStoreAction
} from "../../redux/actions/admin.forecast";
import AdminForecast from "../../containers/AdminForecast/AdminForecast";
import {
  getAdminForecastDateSelector,
  getAdminForecastSelectedStoreSelector,
  getAdminForecastSelector,
  getAdminForecastTableTotalRowSelector
} from "../../redux/selectors/admin.forecast";
// import { getStoresFromLocalStorage } from "../../utils/helpers";
import { getAvailableStoreCodesAction } from "../../redux/actions/admin.dashboard";
import { getAvailableStoreCodesSelector } from "../../redux/selectors/dashboard.admin";
import { SIDEBAR_STYLING } from "../../configs/constants/constants";

const ForeCastEvaluator = ({
  isGraphDataLoading,
  isTableDataLoading,
  isHistoricalDataLoading,
  setSelectedDate,
  selectedDate,
  isSidebarOpen,
  toggleSidebarAction,
  adminForecastSelectedStore,
  updateAdminForecastStoreAction,
  totalRow, // for report
  collapseData,
  getAvailableStoreCodes,
  availableStoreCodes
}) => {
  const isPageLoading =
    isGraphDataLoading || isTableDataLoading || isHistoricalDataLoading;

  const storesFromLS = availableStoreCodes;

  if (!adminForecastSelectedStore && storesFromLS.length) {
    updateAdminForecastStoreAction({
      value: storesFromLS[0],
      label: storesFromLS[0]
    });
  }
  useEffect(() => {
    getAvailableStoreCodes();
  }, []);

  return (
    <div className="admin-forecast-evaluator">
      <Navbar toggleSidebarAction={toggleSidebarAction} />
      {isSidebarOpen && (
        <Sidebar
          sidebar={<SidebarContent />}
          open={isSidebarOpen}
          pullRight
          styles={{ sidebar: SIDEBAR_STYLING }}
          shadow={false}
        />
      )}
      <AdminForecastHeader
        selectedDate={selectedDate}
        setSelectedDate={setSelectedDate}
        stores={storesFromLS}
        selectedStore={adminForecastSelectedStore}
        setSelectedStore={updateAdminForecastStoreAction}
        totalRow={totalRow}
        collapseData={collapseData}
      />
      <AdminForecast
        selectedDate={selectedDate}
        adminForecastSelectedStore={adminForecastSelectedStore}
        isGraphDataLoading={isGraphDataLoading}
      />
      {isPageLoading && <PageLoader />}
    </div>
  );
};

ForeCastEvaluator.defaultProps = {
  isGraphDataLoading: false,
  isTableDataLoading: false,
  isHistoricalDataLoading: false,
  setSelectedDate: noop,
  selectedDate: {},
  isSidebarOpen: false,
  toggleSidebarAction: noop,
  adminForecastSelectedStore: object,
  updateAdminForecastStoreAction: noop,
  totalRow: 0,
  collapseData: object
};

ForeCastEvaluator.propTypes = {
  isGraphDataLoading: PropTypes.bool,
  isTableDataLoading: PropTypes.bool,
  isHistoricalDataLoading: PropTypes.bool,
  setSelectedDate: PropTypes.func,
  selectedDate: PropTypes.object,
  isSidebarOpen: PropTypes.bool,
  toggleSidebarAction: PropTypes.func,
  adminForecastSelectedStore: PropTypes.object,
  updateAdminForecastStoreAction: PropTypes.func,
  totalRow: PropTypes.number,
  collapseData: PropTypes.object,
  getAvailableStoreCodes: PropTypes.func,
  availableStoreCodes: PropTypes.array
};

const mapStateToProps = (state) => ({
  isHistoricalModalOpen: getIsHistoricalModalOpen(state),
  isGraphDataLoading: isGraphDataLoadingSelector(state),
  isTableDataLoading: isTableDataLoadingSelector(state),
  isHistoricalDataLoading: isHistoricalDataLoadingSelector(state),
  selectedDate: getAdminForecastDateSelector(state),
  isSidebarOpen: isSidebarOpenSelector(state),
  adminForecastSelectedStore: getAdminForecastSelectedStoreSelector(state),
  totalRow: getAdminForecastTableTotalRowSelector(state),
  collapseData: getAdminForecastSelector(state),
  availableStoreCodes: getAvailableStoreCodesSelector(state)
});

const mapDispatchToProps = {
  getAdminForecast: getAdminForecastAction,
  toggleHistoricalModal: toggleHistoricalModalAction,
  setSelectedDate: updateAdminForecastDateAction,
  reset15MinSales: reset15MinSalesAction,
  toggleSidebarAction: toggleSidebarAction,
  updateAdminForecastStoreAction: updateAdminForecastStoreAction,
  getAvailableStoreCodes: getAvailableStoreCodesAction
};

export default connect(mapStateToProps, mapDispatchToProps)(ForeCastEvaluator);
