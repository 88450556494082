import {
  GET_ADMIN_CREATE_BATCH_JOB_STATUS_FAILURE,
  GET_ADMIN_CREATE_BATCH_JOB_STATUS_REQUEST,
  GET_ADMIN_CREATE_BATCH_JOB_STATUS_SUCCESS,
  GET_ADMIN_DATA_SYNC_JOB_STATUS_FAILURE,
  GET_ADMIN_DATA_SYNC_JOB_STATUS_REQUEST,
  GET_ADMIN_DATA_SYNC_JOB_STATUS_SUCCESS,
  GET_ADMIN_SALES_15_MIN_JOB_STATUS_FAILURE,
  GET_ADMIN_SALES_15_MIN_JOB_STATUS_REQUEST,
  GET_ADMIN_SALES_15_MIN_JOB_STATUS_SUCCESS,
  GET_ADMIN_SALES_HISTORY_JOB_STATUS_FAILURE,
  GET_ADMIN_SALES_HISTORY_JOB_STATUS_REQUEST,
  GET_ADMIN_SALES_HISTORY_JOB_STATUS_SUCCESS
} from "../types/admin.jobStatus";

const initialState = {
  loadingCreateBatchJobStatus: false,
  loadingSataSyncJobStatus: false,
  loadingSalesIn15MinJobStatus: false,
  loadingSalesHistoryJobStatus: false,
  createBatchJobStatus: [],
  dataSyncJobStatus: [],
  salesIn15MinJobStatus: [],
  salesHistoryJobStatus: []
};

export const adminJobStatusReducer = (
  state = initialState,
  { type, payload }
) => {
  switch (type) {
    case GET_ADMIN_CREATE_BATCH_JOB_STATUS_REQUEST:
      return {
        ...state,
        loadingCreateBatchJobStatus: true,
        createBatchJobStatus: []
      };
    case GET_ADMIN_CREATE_BATCH_JOB_STATUS_SUCCESS:
      return {
        ...state,
        loadingCreateBatchJobStatus: false,
        createBatchJobStatus: payload
      };
    case GET_ADMIN_CREATE_BATCH_JOB_STATUS_FAILURE:
      return {
        ...state,
        loadingCreateBatchJobStatus: false
      };
    case GET_ADMIN_DATA_SYNC_JOB_STATUS_REQUEST:
      return {
        ...state,
        loadingSataSyncJobStatus: true,
        dataSyncJobStatus: []
      };
    case GET_ADMIN_DATA_SYNC_JOB_STATUS_SUCCESS:
      return {
        ...state,
        loadingSataSyncJobStatus: false,
        dataSyncJobStatus: payload
      };
    case GET_ADMIN_DATA_SYNC_JOB_STATUS_FAILURE:
      return {
        ...state,
        loadingSataSyncJobStatus: false
      };
    case GET_ADMIN_SALES_15_MIN_JOB_STATUS_REQUEST:
      return {
        ...state,
        loadingSalesIn15MinJobStatus: true,
        salesIn15MinJobStatus: []
      };
    case GET_ADMIN_SALES_15_MIN_JOB_STATUS_SUCCESS:
      return {
        ...state,
        loadingSalesIn15MinJobStatus: false,
        salesIn15MinJobStatus: payload
      };
    case GET_ADMIN_SALES_15_MIN_JOB_STATUS_FAILURE:
      return {
        ...state,
        loadingSalesIn15MinJobStatus: false
      };
    case GET_ADMIN_SALES_HISTORY_JOB_STATUS_REQUEST:
      return {
        ...state,
        loadingSalesHistoryJobStatus: true,
        salesHistoryJobStatus: []
      };
    case GET_ADMIN_SALES_HISTORY_JOB_STATUS_SUCCESS:
      return {
        ...state,
        loadingSalesHistoryJobStatus: false,
        salesHistoryJobStatus: payload
      };
    case GET_ADMIN_SALES_HISTORY_JOB_STATUS_FAILURE:
      return {
        ...state,
        loadingSalesHistoryJobStatus: false
      };
    default:
      return state;
  }
};
