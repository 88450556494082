import { createSelector } from "reselect";
import { uniqBy } from "lodash";

const adminStoresState = (state) => state.adminStores;

export const adminStoresSelector = createSelector(adminStoresState, (state) =>
  uniqBy(state.adminStores, (obj) => obj.storeCode)
);

export const isAdminStoresLoadingSelector = createSelector(
  adminStoresState,
  (state) => state.loadingAdminStores
);
