import axiosInstance from "../configs/axios";

const ADD_ADMIN_STORE_ACCOUNT_ENDPOINT = "/api/Account/signup";

export const addAdminStoreAccountApi = (payload) => {
  const {
    name,
    email,
    password,
    storeCodes,
    hostName,
    DbName,
    ServerIP,
    DBUserName,
    DBPassword
  } = payload;

  return axiosInstance
    .post(ADD_ADMIN_STORE_ACCOUNT_ENDPOINT, {
      name,
      email,
      password,
      storeCodes,
      hostName,
      DbName,
      ServerIP,
      DBUserName,
      DBPassword
    })
    .then(({ data }) => data);
};
