import { takeLatest, call, put } from "redux-saga/effects";
import { toast } from "react-toastify";
import {
  getCreateBatchJobStatusApi,
  getDataSyncJobStatusApi,
  getSalesHistoryJobStatusApi,
  getSalesIn15MinJobStatusApi,
  updateCreateBatchJobStatusApi,
  updateDataSyncJobStatusApi,
  updateSalesHistoryJobStatusApi,
  updateSalesIn15MinJobStatusApi
} from "../../api/admin.jobStatus";
import {
  GET_ADMIN_CREATE_BATCH_JOB_STATUS_FAILURE,
  GET_ADMIN_CREATE_BATCH_JOB_STATUS_REQUEST,
  GET_ADMIN_CREATE_BATCH_JOB_STATUS_SUCCESS,
  GET_ADMIN_DATA_SYNC_JOB_STATUS_FAILURE,
  GET_ADMIN_DATA_SYNC_JOB_STATUS_REQUEST,
  GET_ADMIN_DATA_SYNC_JOB_STATUS_SUCCESS,
  GET_ADMIN_SALES_15_MIN_JOB_STATUS_FAILURE,
  GET_ADMIN_SALES_15_MIN_JOB_STATUS_REQUEST,
  GET_ADMIN_SALES_15_MIN_JOB_STATUS_SUCCESS,
  GET_ADMIN_SALES_HISTORY_JOB_STATUS_FAILURE,
  GET_ADMIN_SALES_HISTORY_JOB_STATUS_REQUEST,
  GET_ADMIN_SALES_HISTORY_JOB_STATUS_SUCCESS,
  UPDATE_ADMIN_CREATE_BATCH_JOB_STATUS_REQUEST,
  UPDATE_ADMIN_DATA_SYNC_JOB_STATUS_REQUEST,
  UPDATE_ADMIN_SALES_15_MIN_JOB_STATUS_REQUEST,
  UPDATE_ADMIN_SALES_HISTORY_JOB_STATUS_REQUEST
} from "../types/admin.jobStatus";

function* watchAdminJobStatusSaga() {
  yield takeLatest(
    GET_ADMIN_CREATE_BATCH_JOB_STATUS_REQUEST,
    function* getAdminCreateBatchJobStatus({ payload }) {
      try {
        const data = yield call(getCreateBatchJobStatusApi, payload);
        if (data) {
          yield put({
            type: GET_ADMIN_CREATE_BATCH_JOB_STATUS_SUCCESS,
            payload: data
          });
        }
      } catch (e) {
        yield put({ type: GET_ADMIN_CREATE_BATCH_JOB_STATUS_FAILURE });
        console.log("Request Failed => ", e);
        toast.error(
          "Something went wrong while fetching create batch job status"
        );
      }
    }
  );
  yield takeLatest(
    GET_ADMIN_DATA_SYNC_JOB_STATUS_REQUEST,
    function* getAdminDataSyncJobStatus({ payload }) {
      try {
        const data = yield call(getDataSyncJobStatusApi, payload);
        if (data) {
          yield put({
            type: GET_ADMIN_DATA_SYNC_JOB_STATUS_SUCCESS,
            payload: data
          });
        }
      } catch (e) {
        yield put({ type: GET_ADMIN_DATA_SYNC_JOB_STATUS_FAILURE });
        console.log("Request Failed => ", e);
        toast.error("Something went wrong while fetching data sync job status");
      }
    }
  );
  yield takeLatest(
    GET_ADMIN_SALES_15_MIN_JOB_STATUS_REQUEST,
    function* getAdminSales15MinJobStatus({ payload }) {
      try {
        const data = yield call(getSalesIn15MinJobStatusApi, payload);
        if (data) {
          yield put({
            type: GET_ADMIN_SALES_15_MIN_JOB_STATUS_SUCCESS,
            payload: data
          });
        }
      } catch (e) {
        yield put({ type: GET_ADMIN_SALES_15_MIN_JOB_STATUS_FAILURE });
        console.log("Request Failed => ", e);
        toast.error(
          "Something went wrong while fetching sales in 15 min job status"
        );
      }
    }
  );
  yield takeLatest(
    GET_ADMIN_SALES_HISTORY_JOB_STATUS_REQUEST,
    function* getSalesHistoryJobStatus({ payload }) {
      try {
        const data = yield call(getSalesHistoryJobStatusApi, payload);
        if (data) {
          yield put({
            type: GET_ADMIN_SALES_HISTORY_JOB_STATUS_SUCCESS,
            payload: data
          });
        }
      } catch (e) {
        yield put({ type: GET_ADMIN_SALES_HISTORY_JOB_STATUS_FAILURE });
        console.log("Request Failed => ", e);
        toast.error(
          "Something went wrong while fetching sales history job status"
        );
      }
    }
  );
  yield takeLatest(
    UPDATE_ADMIN_CREATE_BATCH_JOB_STATUS_REQUEST,
    function* updateAdminCreateBatchJobStatus({ payload }) {
      try {
        const response = yield call(updateCreateBatchJobStatusApi, payload);
        toast.success(
          response?.message ??
            "Update create batch job status has been executed successfully"
        );
      } catch (e) {
        toast.error(e?.response?.data?.value || "Something went wrong");
      }
    }
  );
  yield takeLatest(
    UPDATE_ADMIN_DATA_SYNC_JOB_STATUS_REQUEST,
    function* updateAdminDataSyncJobStatus({ payload }) {
      try {
        const response = yield call(updateDataSyncJobStatusApi, payload);
        toast.success(
          response?.message ??
            "Update data sync job status has been executed successfully"
        );
      } catch (e) {
        toast.error(e?.response?.data?.value || "Something went wrong");
      }
    }
  );
  yield takeLatest(
    UPDATE_ADMIN_SALES_15_MIN_JOB_STATUS_REQUEST,
    function* updateAdminSale15MinJobStatus({ payload }) {
      try {
        const response = yield call(updateSalesIn15MinJobStatusApi, payload);
        toast.success(
          response?.message ??
            "Update sales in 15 min job status has been executed successfully"
        );
      } catch (e) {
        toast.error(e?.response?.data?.value || "Something went wrong");
      }
    }
  );
  yield takeLatest(
    UPDATE_ADMIN_SALES_HISTORY_JOB_STATUS_REQUEST,
    function* updateAdminSaleHistoryJobStatus({ payload }) {
      try {
        const response = yield call(updateSalesHistoryJobStatusApi, payload);
        toast.success(
          response?.message ??
            "Update sales history job status has been executed successfully"
        );
      } catch (e) {
        toast.error(e?.response?.data?.value || "Something went wrong");
      }
    }
  );
}

export default function* jobStatusSaga() {
  yield call(watchAdminJobStatusSaga);
}
