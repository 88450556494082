import axiosInstance from "../configs/axios";

const ADD_ADMIN_MANAGER_ACCOUNT_ENDPOINT = "/api/Account/manager-signup";

export const addAdminManagerAccountApi = (payload) => {
  const { name, email, password, storeCodes } = payload;

  return axiosInstance
    .post(ADD_ADMIN_MANAGER_ACCOUNT_ENDPOINT, {
      name,
      email,
      password,
      storeCodes
    })
    .then(({ data }) => data);
};
