import axiosInstance from "../configs/axios";

const GET_ADMIN_USER_LIST_ENDPOINT = "/api/admin/auth/users";
const UPDATE_ADMIN_USER_STATUS_ENDPOINT = "/api/admin/auth/users/disable";
const UPDATE_ADMIN_USER_PRINT_STATUS_ENDPOINT = "/api/admin/auth/users/print";
const UPDATE_ADMIN_USER_PASSWORD_ENDPOINT =
  "/api/admin/auth/resetpassword/user";
const UPDATE_ADMIN_USER_EMAIL_ENDPOINT = "/api/admin/auth/users/Email";

function AdminStore(user) {
  this.storeCode = user.storeCode;
  this.email = user.email;
  this.createDate = user.createDate;
  this.isEnable = user.isEnable;
  this.isPrintAllow = user.isPrintAllow;
}

export const getAdminUserListApi = (payload) => {
  return axiosInstance
    .get(
      `${GET_ADMIN_USER_LIST_ENDPOINT}?from=${payload.startIndex}&size=${
        payload.itemsPerPage || 50
      }`
    )
    .then(({ data }) => {
      return {
        users: data?.data?.map((store) => new AdminStore(store)),
        totalUsers: data?.total
      };
    });
};

export const updateUserStatusApi = (payload) => {
  return axiosInstance
    .put(
      `${UPDATE_ADMIN_USER_STATUS_ENDPOINT}/${payload.email}/${payload.isEnable}`
    )
    .then(({ data }) => data);
};

export const updateUserPrintStatusApi = (payload) => {
  return axiosInstance
    .put(
      `${UPDATE_ADMIN_USER_PRINT_STATUS_ENDPOINT}/${payload.email}/${payload.isPrintAllow}`
    )
    .then(({ data }) => data);
};

export const updateAdminUserPasswordAPI = (payload) => {
  const { email, password } = payload;
  return axiosInstance
    .post(UPDATE_ADMIN_USER_PASSWORD_ENDPOINT, {
      Email: email,
      Password: password
    })
    .then(({ data }) => data);
};

export const updateAdminUserEmailApi = (payload) => {
  const { oldEmail, newEmail } = payload;
  return axiosInstance
    .put(UPDATE_ADMIN_USER_EMAIL_ENDPOINT, { oldEmail, newEmail })
    .then(({ data }) => data);
};
