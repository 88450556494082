import { map } from "lodash";

import { getNumberInTwoDigit, getTime } from "../utils/helpers";

import axiosInstance from "../configs/axios";

const GRAPH_DATA_API_ENDPOINT = "/api/Sales/GetSalesDataBy15Min";
const TABLE_DATA_API_ENDPOINT = "/api/Sales/GetCumulativeSales";
const HISTORICAL_DATA_API_ENDPOINT = "/api/Sales/GetSalesofLast6Weeks";
const UPDATE_CURRENT_SALES_API_ENDPOINT = "/api/Sales/UpdateCurrentSales";
const UPDATE_15_MIN_SALES_API_ENDPOINT = "/api/Sales/Update15minSalesData";
const UPDATE_DAY_PERIOD_SALES_API_ENDPOINT = "/api/Sales/UpdateDayPeriodSales";
const UPDATE_HOURLY_PERIOD_SALES_API_ENDPOINT = "/api/Sales/UpdateHourlySale";
const RESET_15_MIN_SALES_API_ENDPOINT = "/api/Sales/Reset15MinSalesData";

function GraphObject(data) {
  this.createDate = data.businessDay;
  this.salesTime = getTime(data.businessDay);
  this.lastYearSale = data.lastYearSale;
  this.forcastedSale = data.forcastedSale;
  this.adminEdit = data.adminEdit;
  this.actualAdminEdit = data.actualAdminEdit;
  this.salesType = data.salesType;
  this.dayPeriod = data.dayPeriod;
}

export const getGraphDataAPI = (selectedDate) => {
  const year = selectedDate.getFullYear();
  const month = getNumberInTwoDigit(selectedDate.getMonth() + 1);
  const date = getNumberInTwoDigit(selectedDate.getDate());
  const hours = getNumberInTwoDigit(selectedDate.getHours());
  const minutes = getNumberInTwoDigit(selectedDate.getMinutes());
  const seconds = getNumberInTwoDigit(selectedDate.getSeconds());
  return axiosInstance
    .post(`${GRAPH_DATA_API_ENDPOINT}`, {
      salesDate: `${year}-${month}-${date}T${hours}:${minutes}:${seconds}Z`
    })
    .then(({ data }) => map(data, (obj) => new GraphObject(obj)));
};

export const getSalesTableDataAPI = (selectedDate) => {
  const year = selectedDate.getFullYear();
  const month = getNumberInTwoDigit(selectedDate.getMonth() + 1);
  const date = getNumberInTwoDigit(selectedDate.getDate());
  const hours = getNumberInTwoDigit(selectedDate.getHours());
  const minutes = getNumberInTwoDigit(selectedDate.getMinutes());
  const seconds = getNumberInTwoDigit(selectedDate.getSeconds());
  return axiosInstance
    .put(`${TABLE_DATA_API_ENDPOINT}`, {
      salesDate: `${year}-${month}-${date}T${hours}:${minutes}:${seconds}Z`
    })
    .then(({ data }) => data);
};

export const getHistoricalDataAPI = (pDate) => {
  const payloadDate = new Date(pDate);
  const currentTime = new Date();

  const year = payloadDate.getFullYear();
  const month = getNumberInTwoDigit(payloadDate.getMonth() + 1);
  const date = getNumberInTwoDigit(payloadDate.getDate());
  const hours = getNumberInTwoDigit(currentTime.getHours());
  const minutes = getNumberInTwoDigit(currentTime.getMinutes());
  const seconds = getNumberInTwoDigit(currentTime.getSeconds());
  const qParam = `?currentDate=${year}-${month}-${date}T${hours}:${minutes}:${seconds}Z`;

  return axiosInstance
    .get(`${HISTORICAL_DATA_API_ENDPOINT}${qParam}`)
    .then(({ data }) => data);
};

export const updateCurrentSalesAPI = (request) => {
  return axiosInstance
    .post(UPDATE_CURRENT_SALES_API_ENDPOINT, request)
    .then((response) => ({ response }))
    .catch((error) => ({ error }));
};

export const update15MinSalesData = (request) => {
  return axiosInstance
    .put(UPDATE_15_MIN_SALES_API_ENDPOINT, request)
    .then((response) => ({ response }))
    .catch((error) => ({ error }));
};

export const updateDayPeriodSalesAPI = (request) => {
  return axiosInstance
    .put(UPDATE_DAY_PERIOD_SALES_API_ENDPOINT, request)
    .then((response) => ({ response }))
    .catch((error) => ({ error }));
};

export const updateHourlySalesAPI = (request) => {
  return axiosInstance
    .put(UPDATE_HOURLY_PERIOD_SALES_API_ENDPOINT, request)
    .then((response) => ({ response }))
    .catch((error) => ({ error }));
};

export const reset15MinSalesDataAPI = (request) => {
  return axiosInstance
    .put(RESET_15_MIN_SALES_API_ENDPOINT, request)
    .then((response) => ({ response }))
    .catch((error) => ({ error }));
};
