import React, { useEffect, useState } from "react";
import Sidebar from "react-sidebar";
import PropTypes, { bool } from "prop-types";
import { connect } from "react-redux";
import { noop } from "lodash";
import SidebarContent from "../../components/SidebarContent/SidebarContent";
import Navbar from "../../components/Navbar";
import { toggleSidebarAction } from "../../redux/actions/dashboard";
import { isSidebarOpenSelector } from "../../redux/selectors/dashboard";
import { getAdminStoreUsersAction } from "../../redux/actions/admin.storeUsers";
import AdminStoreUsersTable from "../../containers/AdminStoreUsersTable";
import {
  adminStoreUsersSelector,
  isAdminStoreUsersLoadingSelector
} from "../../redux/selectors/admin.storeUsers";
import { SIDEBAR_STYLING } from "../../configs/constants/constants";

const AdminStores = ({
  toggleSidebar,
  isSidebarOpen,
  adminStoreUsersData,
  isAdminStoreUsersLoading,
  getAdminStoreUsers
}) => {
  const [isAddingNewStore, setIsAddingNewStore] = useState(false);
  const [search, setSearch] = useState("");
  useEffect(() => {
    getAdminStoreUsers();
  }, []);

  const getFilteredAdminStoreUsers = () => {
    return adminStoreUsersData.filter((user) => {
      const storeCode = user.storeCode.toLowerCase();
      const email = user.email.toLowerCase();
      const searchText = search.toLowerCase();
      return storeCode?.includes(searchText) || email?.includes(searchText);
    });
  };

  return (
    <div>
      <Navbar toggleSidebarAction={toggleSidebar} />
      {isSidebarOpen && (
        <Sidebar
          sidebar={<SidebarContent />}
          open={isSidebarOpen}
          pullRight
          styles={{ sidebar: SIDEBAR_STYLING }}
          shadow={false}
        />
      )}
      <div className="mt-3 d-flex justify-content-between container">
        <div className="w-25">
          <input
            className="form-control"
            type="text"
            placeholder="Search by Store Code or email"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>

        <button
          disabled={isAdminStoreUsersLoading}
          className="btn btn-dark"
          onClick={() => {
            setIsAddingNewStore(true);
          }}
        >
          Add new store
        </button>
      </div>
      <div className="container mt-3">
        <AdminStoreUsersTable
          adminStoreUsers={getFilteredAdminStoreUsers()}
          isAdminStoreUsersLoading={isAdminStoreUsersLoading}
          isAddingNewStore={isAddingNewStore}
          setIsAddingNewStore={setIsAddingNewStore}
          search={search}
        />
      </div>
    </div>
  );
};

AdminStores.defaultProps = {
  toggleSidebar: noop,
  isSidebarOpen: false,
  adminStoreUsersData: [],
  isAdminStoreUsersLoading: bool,
  getAdminStoreUsers: noop
};

AdminStores.propTypes = {
  toggleSidebar: PropTypes.func,
  isSidebarOpen: PropTypes.bool,
  adminStoreUsersData: PropTypes.array,
  isAdminStoreUsersLoading: PropTypes.bool,
  getAdminStoreUsers: PropTypes.func
};

const mapStateToProps = (state) => ({
  isSidebarOpen: isSidebarOpenSelector(state),
  adminStoreUsersData: adminStoreUsersSelector(state),
  isAdminStoreUsersLoading: isAdminStoreUsersLoadingSelector(state)
});

const mapDispatchToProps = {
  toggleSidebar: toggleSidebarAction,
  getAdminStoreUsers: getAdminStoreUsersAction
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminStores);
