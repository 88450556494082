import {
  CONNECT_SIGNALR,
  GET_ACTIVE_ITEMS_REQUEST,
  GET_BATCH,
  GET_WASTE_REASON_REQUEST,
  OPEN_ADD_BATCH_MODAL,
  OPEN_UPDATE_BATCH_MODAL,
  REMOVE_BATCH_FROM_STORE,
  TOGGLE_SIDEBAR,
  SAVE_TREND_DATA,
  SAVE_SIGNALR_CONNECTION,
  SAVE_TREND_TIME,
  UPDATE_BATCH,
  UPDATE_ITEM_COLLAPSE_MENU,
  UPDATE_NEW_BATCH_DATA,
  UPDATE_TO_DROP_DATA_IN_ACTIVE_ITEMS,
  UPDATE_QTY_IN_BATCH,
  GET_SALES_TREND_REQUEST
} from "../types/dashboard";

export const getActiveItemsAction = (payload) => {
  return {
    type: GET_ACTIVE_ITEMS_REQUEST,
    payload
  };
};

export const toggleAddBatchModalAction = (payload) => {
  return {
    type: OPEN_ADD_BATCH_MODAL,
    payload
  };
};

export const toggleUpdateBatchModal = (payload) => {
  return {
    type: OPEN_UPDATE_BATCH_MODAL,
    payload
  };
};

export const getBatchAction = () => {
  return {
    type: GET_BATCH
  };
};

export const updateNewBatchDataAction = (payload) => {
  return {
    type: UPDATE_NEW_BATCH_DATA,
    payload
  };
};

export const saveTrendDataAction = (payload) => {
  return {
    type: SAVE_TREND_DATA,
    payload
  };
};

export const removeBatchFromStoreAction = (payload) => {
  return {
    type: REMOVE_BATCH_FROM_STORE,
    payload
  };
};

export const getWasteReasonAction = (payload) => {
  return {
    type: GET_WASTE_REASON_REQUEST,
    payload
  };
};

export const updateBatchAction = (payload) => {
  return {
    type: UPDATE_BATCH,
    payload
  };
};

export const updateCollapseMenuAction = (payload) => {
  return {
    type: UPDATE_ITEM_COLLAPSE_MENU,
    payload
  };
};

export const saveTrendTimeAction = (payload) => {
  return {
    type: SAVE_TREND_TIME,
    payload
  };
};

export const updateToDropFromSignalRAction = (payload) => {
  return {
    type: UPDATE_TO_DROP_DATA_IN_ACTIVE_ITEMS,
    payload
  };
};

export const saveSignalRConnectionAction = (payload) => {
  return {
    type: SAVE_SIGNALR_CONNECTION,
    payload
  };
};

export const connectToSignalRAction = () => {
  return {
    type: CONNECT_SIGNALR
  };
};

export const toggleSidebarAction = () => {
  return {
    type: TOGGLE_SIDEBAR
  };
};

export const updateQtyInBatchAction = (payload) => {
  return {
    type: UPDATE_QTY_IN_BATCH,
    payload
  };
};

export const getSalesTrendAction = () => {
  return {
    type: GET_SALES_TREND_REQUEST
  };
};
