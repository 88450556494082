import { createSelector } from "reselect";

const authState = (state) => state.auth;

export const isStoreAccountCreatedSelector = createSelector(
  authState,
  (state) => state.isStoreAccountCreated
);

export const isManagerAccountCreatedSelector = createSelector(
  authState,
  (state) => state.isManagerAccountCreated
);
