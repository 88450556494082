export const USER_LOGIN_REQUEST = "USER_LOGIN_REQUEST";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGIN_FAILURE = "USER_LOGIN_FAILURE";

export const USER_SIGNUP_REQUEST = "USER_SIGNUP_REQUEST";
export const USER_SIGNUP_SUCCESS = "USER_SIGNUP_SUCCESS";
export const USER_SIGNUP_FAILURE = "USER_SIGNUP_FAILURE";

export const ADD_STORE_ACCOUNT_REQUEST = "ADD_STORE_ACCOUNT_REQUEST";
export const ADD_STORE_ACCOUNT_SUCCESS = "ADD_STORE_ACCOUNT_SUCCESS";
export const RESET_ADD_ACCOUNT_SUCCESS_STATE = "RESET_ADD_ACCOUNT_SUCCESS_STATE";

export const ADD_ADMIN_MANAGER_ACCOUNT_REQUEST = "ADD_ADMIN_MANAGER_ACCOUNT_REQUEST";
export const ADD_ADMIN_MANAGER_ACCOUNT_SUCCESS = "ADD_ADMIN_MANAGER_ACCOUNT_SUCCESS";
export const RESET_ADD_MANAGER_ACCOUNT_SUCCESS_STATE = "RESET_ADD_ACCOUNT_SUCCESS_STATE";