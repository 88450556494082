import { createSelector } from "reselect";

const adminForecastAccuracyState = (state) => state.adminForecastAccuracy;

/**
 * FORECAST ACCURACY DATA : GET
 */
export const adminForecastAccuracySelector = createSelector(
  adminForecastAccuracyState,
  (FA) => FA.adminForecastAccuracy
);

/**
 * IS FORECAST ACCURACY LOADING
 */
export const isAdminForecastAccuracyLoadingSelector = createSelector(
  adminForecastAccuracyState,
  (FA) => FA.loadingAdminForecastAccuracy
);
