import React, { useEffect, useState } from "react";
import PropTypes, { bool } from "prop-types";
import Sidebar from "react-sidebar";
import { connect } from "react-redux";
import { noop } from "lodash";

import Navbar from "../../components/Navbar";
import SidebarContent from "../../components/SidebarContent/SidebarContent";

import { toggleSidebarAction } from "../../redux/actions/dashboard";
import { isSidebarOpenSelector } from "../../redux/selectors/dashboard";
import AdminItemsTable from "../../containers/AdminItemsTable";
import { getAdminItemsAction } from "../../redux/actions/admin.items";
import {
  adminItemsSelector,
  isAdminItemsLoadingSelector
} from "../../redux/selectors/admin.items";
import { isSubstringIgnoreCase } from "../../utils/helpers";
import { SIDEBAR_STYLING } from "../../configs/constants/constants";

const AdminItems = ({
  toggleSidebar,
  isSidebarOpen,
  getAdminItems,
  adminItemsData,
  isAdminItemsLoading
}) => {
  const [isAddingNewItem, setIsAddingNewItem] = useState(false);
  const [search, setSearch] = useState("");
  const [tableData, setTableData] = useState(adminItemsData);

  useEffect(() => {
    getAdminItems();
  }, []);

  useEffect(() => {
    let filteredData = adminItemsData.filter((item) => {
      let searchIn = "";
      if (item.itemId) {
        searchIn += item.itemId;
      }
      if (item.description) {
        searchIn += item.description;
      }
      return isSubstringIgnoreCase(searchIn, search);
    });
    setTableData(filteredData);
  }, [search, adminItemsData]);

  return (
    <div>
      <Navbar toggleSidebarAction={toggleSidebar} />
      {isSidebarOpen && (
        <Sidebar
          sidebar={<SidebarContent />}
          open={isSidebarOpen}
          pullRight
          styles={{ sidebar: SIDEBAR_STYLING }}
          shadow={false}
        />
      )}
      <div className="mt-3 d-flex justify-content-between container">
        <div style={{ width: "25%" }}>
          <input
            className="form-control"
            type="text"
            placeholder="Search by Item Id or Description"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
        <button
          disabled={isAdminItemsLoading}
          className="btn btn-dark"
          onClick={() => {
            setIsAddingNewItem(true);
          }}
        >
          Add new item
        </button>
      </div>
      <div className="container mt-3">
        <AdminItemsTable
          adminItems={tableData}
          isAdminItemsLoading={isAdminItemsLoading}
          isAddingNewItem={isAddingNewItem}
          setIsAddingNewItem={setIsAddingNewItem}
        />
      </div>
    </div>
  );
};

AdminItems.defaultProps = {
  toggleSidebar: noop,
  isSidebarOpen: bool,
  getAdminItems: noop,
  adminItemsData: [],
  isAdminItemsLoading: bool
};

AdminItems.propTypes = {
  toggleSidebar: PropTypes.func,
  isSidebarOpen: PropTypes.bool,
  getAdminItems: PropTypes.func,
  adminItemsData: PropTypes.array,
  isAdminItemsLoading: PropTypes.bool
};

const mapStateToProps = (state) => ({
  isSidebarOpen: isSidebarOpenSelector(state),
  adminItemsData: adminItemsSelector(state),
  isAdminItemsLoading: isAdminItemsLoadingSelector(state)
});

const mapDispatchToProps = {
  toggleSidebar: toggleSidebarAction,
  getAdminItems: getAdminItemsAction
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminItems);
