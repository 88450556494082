import { combineReducers } from "redux";

import { dashboardReducer } from "./reducers/dashboard";
import { updateBatchReducer } from "./reducers/updateBatch";
import { addBatchReducer } from "./reducers/addBatch";
import { forecastReducer } from "./reducers/forecast";
import { storeSummaryReducer } from "./reducers/storeSummary";
import { forecastAccuracyReducer } from "./reducers/forecastAccuracy";
import { adminDashboardReducer } from "./reducers/admin.dashboard";
import { adminForecastReducer } from "./reducers/admin.forecast";
import { adminStoreSummaryReducer } from "./reducers/admin.storeSummary";
import { adminForecastAccuracyReducer } from "./reducers/admin.forecastAccuracy";
import { adminItemsReducer } from "./reducers/admin.items";
import { adminStoresReducer } from "./reducers/admin.stores";
import { adminStoreUsersReducer } from "./reducers/admin.storeUsers";
import { adminUserListReducer } from "./reducers/admin.userList";
import { authReducer } from "./reducers/auth";
import { adminJobStatusReducer } from "./reducers/admin.jobStatus";
import { dailyBusinessSummaryReducer } from "./reducers/dailyBusinessSummary";

const rootReducer = combineReducers({
  dashboard: dashboardReducer,
  updateBatch: updateBatchReducer,
  addBatch: addBatchReducer,
  forecast: forecastReducer,
  storeSummary: storeSummaryReducer,
  forecastAccuracy: forecastAccuracyReducer,
  adminDashboard: adminDashboardReducer,
  adminForecast: adminForecastReducer,
  adminStoreSummary: adminStoreSummaryReducer,
  adminForecastAccuracy: adminForecastAccuracyReducer,
  adminItems: adminItemsReducer,
  adminStores: adminStoresReducer,
  adminStoreUsers: adminStoreUsersReducer,
  adminUserList: adminUserListReducer,
  auth: authReducer,
  adminJobStatus: adminJobStatusReducer,
  dailyBusinessSummary: dailyBusinessSummaryReducer
});

export default rootReducer;
