import axiosInstance from "../configs/axios";
import { getNumberInTwoDigit } from "../utils/helpers";

const FORECAST_ACCURACY_API_ENDPOINT = "/api/sales/GetStoreForcastAccuracy";

export function ForecastAccuracy(item) {
  this.salesType = item.salesType;
  this.actualSales = item.actualSales;
  this.adminAccuracy = item.adminAccuracy;
  this.adminForcast = item.adminForcast;
  this.adminVariance = item.adminVariance;
  this.systemAccuracy = item.systemAccuracy;
  this.systemForcast = item.systemForcast;
  this.systemVariance = item.systemVariance;
}

export const getForecastAccuracyAPI = (Date) => {
  const year = Date.getFullYear();
  const month = getNumberInTwoDigit(Date.getMonth() + 1);
  const date = getNumberInTwoDigit(Date.getDate());
  const hours = getNumberInTwoDigit(Date.getHours());
  const minutes = getNumberInTwoDigit(Date.getMinutes());
  const seconds = getNumberInTwoDigit(Date.getSeconds());
  return axiosInstance
    .get(
      `${FORECAST_ACCURACY_API_ENDPOINT}?currentDate=${year}-${month}-${date}T${hours}:${minutes}:${seconds}Z`
    )
    .then(({ data }) => data.map((item) => new ForecastAccuracy(item)));
};
