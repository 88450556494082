import { takeLatest, call, put } from "redux-saga/effects";
import { toast } from "react-toastify";
import {
  DELETE_ADMIN_ITEM_REQUEST,
  GET_ADMIN_ITEMS_FAILURE,
  GET_ADMIN_ITEMS_REQUEST,
  GET_ADMIN_ITEMS_SUCCESS,
  UPDATE_ADMIN_ITEM_REQUEST
} from "../types/admin.items";
import {
  deleteAdminItemApi,
  getAdminItemsApi,
  updateAdminItemApi
} from "../../api/admin.items";

function* watchAdminItemsSaga() {
  yield takeLatest(
    GET_ADMIN_ITEMS_REQUEST,
    function* getAdminForecast({ payload }) {
      try {
        const data = yield call(getAdminItemsApi, payload);
        if (data) {
          yield put({ type: GET_ADMIN_ITEMS_SUCCESS, payload: data });
        }
      } catch (e) {
        yield put({ type: GET_ADMIN_ITEMS_FAILURE });
        toast.error("Something went wrong while fetching graph data");
      }
    }
  );
  yield takeLatest(
    UPDATE_ADMIN_ITEM_REQUEST,
    function* updateAdminItem({ payload }) {
      try {
        yield call(updateAdminItemApi, payload.apiBody);
        toast.success(
          `Item has been ${
            payload.isNewItem ? "added" : "updated"
          } successfully`
        );
        try {
          const updatedItemsList = yield call(getAdminItemsApi, payload);
          if (updatedItemsList) {
            yield put({
              type: GET_ADMIN_ITEMS_SUCCESS,
              payload: updatedItemsList
            });
          }
        } catch (e) {
          yield put({ type: GET_ADMIN_ITEMS_FAILURE });
          toast.error("Something went wrong while fetching graph data");
        }
      } catch (e) {
        toast.error("Something went wrong while updating admin item");
      }
    }
  );
  yield takeLatest(
    DELETE_ADMIN_ITEM_REQUEST,
    function* deleteAdminItem({ payload }) {
      try {
        yield call(deleteAdminItemApi, payload);
        toast.success("Item has been deleted successfully");
        try {
          const updatedItemsList = yield call(getAdminItemsApi, payload);
          if (updatedItemsList) {
            yield put({
              type: GET_ADMIN_ITEMS_SUCCESS,
              payload: updatedItemsList
            });
          }
        } catch (e) {
          yield put({ type: GET_ADMIN_ITEMS_FAILURE });
          toast.error("Something went wrong while fetching graph data");
        }
      } catch (e) {
        toast.error("Something went wrong while updating admin item");
      }
    }
  );
}

export default function* adminItemsSaga() {
  yield call(watchAdminItemsSaga);
}
