import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { connect } from "react-redux";
import PropTypes, { bool } from "prop-types";
import { noop } from "lodash";
import useApiTablePagination from "../../customHooks/useApiTablePagination";
import TableLoader from "../../components/SkeletonLoader/TableLoader";
import TableRow from "./TableRow";
import ResetPasswordModal from "./ResetPasswordModal/ResetPasswordModal";
import {
  updateAdminUserEmailAction,
  updateAdminUserPasswordAction
} from "../../redux/actions/admin.userList";
import UpdateEmailModal from "./UpdateEmailModal/UpdateEmailModal";
import "./AdminUserListTable.scss";

const headerStyle = {
  backgroundColor: "#0A4091",
  color: "#fff",
  padding: "10px",
  textAlign: "left"
};

const AdminUserListTable = ({
  adminUserList,
  isAdminUserListLoading,
  updateAdminUserPassword,
  getAdminUserList,
  totalUsers,
  updateAdminUserEmail
}) => {
  const [isResetPasswordModalOpen, setIsResetPasswordModalOpen] =
    useState(false);
  const [emailSelectedForPasswordReset, setEmailSelectedForPasswordReset] =
    useState("");
  const [emailSelectedForUpdate, setEmailSelectedForUpdate] = useState("");
  const [isUpdateEmailModalOpen, setIsUpdateEmailModalOpen] = useState(false);

  const handlePageChange = (startIndex, itemsPerPage) => {
    getAdminUserList({ startIndex, itemsPerPage });
  };

  const {
    startIndex,
    currentPage,
    totalPages,
    nextPage,
    prevPage,
    isFirstPage,
    isLastPage
  } = useApiTablePagination(0, 50, totalUsers, handlePageChange);

  const handleResetModalClose = () => {
    setIsResetPasswordModalOpen(false);
    setEmailSelectedForPasswordReset("");
  };

  const handleResetModalOpen = (email) => {
    setIsResetPasswordModalOpen(true);
    setEmailSelectedForPasswordReset(email);
  };

  const handleUpdateEmailModalOpen = (email) => {
    setIsUpdateEmailModalOpen(true);
    setEmailSelectedForUpdate(email);
  };

  const handleUpdateEmailModalClose = () => {
    setIsUpdateEmailModalOpen(false);
    setEmailSelectedForUpdate("");
  };

  const handleResetPassword = (payload) => {
    updateAdminUserPassword(payload);
  };

  const handleUpdateEmail = (payload) => {
    updateAdminUserEmail(payload);
  };

  return (
    <>
      <table style={{ width: "100%", borderCollapse: "collapse" }}>
        <thead>
          <tr>
            <th style={headerStyle}>Store Code</th>
            <th style={headerStyle}>Email</th>
            <th style={headerStyle}>Created At</th>
            <th style={headerStyle}>Status</th>
            <th style={headerStyle}>Print</th>
            <th style={headerStyle}>Action</th>
          </tr>
        </thead>
        {isAdminUserListLoading ? (
          <TableLoader noOfColumn={6} noOfRow={20} />
        ) : (
          <tbody>
            {adminUserList.map(
              ({ storeCode, email, createDate, isEnable, isPrintAllow }) => (
                <TableRow
                  storeCode={storeCode}
                  email={email}
                  createDate={createDate}
                  key={`${email}_${storeCode}`}
                  isEnable={isEnable}
                  isPrintAllow={isPrintAllow}
                  startIndex={startIndex}
                  handleResetModalOpen={handleResetModalOpen}
                  handleUpdateEmailModalOpen={handleUpdateEmailModalOpen}
                />
              )
            )}
          </tbody>
        )}
      </table>
      {adminUserList && adminUserList.length ? (
        <div className="mt-3 mb-5 d-flex justify-content-center align-items-center">
          <Button variant="dark" disabled={isFirstPage} onClick={prevPage}>
            Previous Page
          </Button>
          <span className="mx-2">
            {currentPage}/{totalPages}
          </span>
          <Button variant="dark" disabled={isLastPage} onClick={nextPage}>
            Next Page
          </Button>
        </div>
      ) : null}
      {isResetPasswordModalOpen && (
        <ResetPasswordModal
          isUpdateEmailModalOpen={isResetPasswordModalOpen}
          handleModalClose={handleResetModalClose}
          email={emailSelectedForPasswordReset}
          resetPassword={handleResetPassword}
        />
      )}
      {isUpdateEmailModalOpen && (
        <UpdateEmailModal
          isUpdateEmailModalOpen={isUpdateEmailModalOpen}
          email={emailSelectedForUpdate}
          handleModalClose={handleUpdateEmailModalClose}
          updateEmail={handleUpdateEmail}
        />
      )}
    </>
  );
};

AdminUserListTable.defaultProps = {
  adminUserList: [],
  isAdminUserListLoading: bool,
  updateAdminUserPassword: noop,
  getAdminUserList: noop,
  totalUsers: 0,
  updateAdminUserEmail: noop
};

AdminUserListTable.propTypes = {
  adminUserList: PropTypes.array,
  isAdminUserListLoading: PropTypes.bool,
  updateAdminUserPassword: PropTypes.func,
  getAdminUserList: PropTypes.func,
  totalUsers: PropTypes.number,
  updateAdminUserEmail: PropTypes.func
};

const mapDispatchToProps = {
  updateAdminUserPassword: updateAdminUserPasswordAction,
  updateAdminUserEmail: updateAdminUserEmailAction
};

export default connect(null, mapDispatchToProps)(AdminUserListTable);
