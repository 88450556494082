import { takeLatest, call, put } from "redux-saga/effects";
import { toast } from "react-toastify";
import {
  DELETE_ADMIN_STORE_USER_REQUEST,
  GET_ADMIN_STORE_USERS_FAILURE,
  GET_ADMIN_STORE_USERS_REQUEST,
  GET_ADMIN_STORE_USERS_SUCCESS,
  ADD_ADMIN_STORE_USER_REQUEST
} from "../types/admin.storeUsers";
import {
  deleteAdminStoreUserApi,
  getAdminStoreUsersApi,
  updateAdminStoreUserApi
} from "../../api/admin.storeUsers";

function* watchAdminStoreUsersSaga() {
  yield takeLatest(
    GET_ADMIN_STORE_USERS_REQUEST,
    function* getAdminStoreUsersUser({ payload }) {
      try {
        const data = yield call(getAdminStoreUsersApi, payload);
        if (data) {
          yield put({ type: GET_ADMIN_STORE_USERS_SUCCESS, payload: data });
        }
      } catch (e) {
        yield put({ type: GET_ADMIN_STORE_USERS_FAILURE });
        toast.error("Something went wrong while fetching store users");
      }
    }
  );
  yield takeLatest(
    ADD_ADMIN_STORE_USER_REQUEST,
    function* updateAdminStoreUser({ payload }) {
      try {
        yield call(updateAdminStoreUserApi, payload.apiBody);
        try {
          const updatedStoreUserList = yield call(
            getAdminStoreUsersApi,
            payload
          );
          toast.success(
            `User mapping with store has been ${
              payload.isNewStore ? "added" : "updated"
            } successfully`
          );
          if (updatedStoreUserList) {
            yield put({
              type: GET_ADMIN_STORE_USERS_SUCCESS,
              payload: updatedStoreUserList
            });
          }
        } catch (e) {
          yield put({ type: GET_ADMIN_STORE_USERS_FAILURE });
          toast.error("Something went wrong while fetching store data");
        }
      } catch (e) {
        toast.error("Something went wrong while updating store");
      }
    }
  );
  yield takeLatest(
    DELETE_ADMIN_STORE_USER_REQUEST,
    function* deleteAdminStoreUser({ payload }) {
      try {
        yield call(deleteAdminStoreUserApi, payload);
        toast.success("User mapping with store has been deleted successfully");
        try {
          const updatedStoreUserList = yield call(
            getAdminStoreUsersApi,
            payload
          );
          if (updatedStoreUserList) {
            yield put({
              type: GET_ADMIN_STORE_USERS_SUCCESS,
              payload: updatedStoreUserList
            });
          }
        } catch (e) {
          yield put({ type: GET_ADMIN_STORE_USERS_FAILURE });
          toast.error("Something went wrong while fetching store user data");
        }
      } catch (e) {
        toast.error("Something went wrong while deleting store user");
      }
    }
  );
}

export default function* adminStoresUsersSaga() {
  yield call(watchAdminStoreUsersSaga);
}
