import { map } from "lodash";

import { getNumberInTwoDigit, getTime } from "../utils/helpers";

import axiosInstance from "../configs/axios";

const GET_ADMIN_FORECAST_ENDPOINT =
  "/api/admin/sales/Store15Sales";

function AdminForecast(data) {
  this.createDate = data.businessDay;
  this.salesTime = getTime(data.businessDay);
  this.lastYearSale = data.lastYearSale;
  this.forcastedSale = data.forcastedSale;
  this.adminEdit = data.adminEdit;
  this.actualAdminEdit = data.actualAdminEdit;
  this.salesType = data.salesType;
  this.dayPeriod = data.dayPeriod;
}

export const getAdminForecastApi = (payload) => {
  const dateObj = new Date(payload.date);
  const dateStr = `${dateObj.getFullYear()}-${
    dateObj.getMonth() + 1
  }-${dateObj.getDate()}`;
  return axiosInstance
    .get(
      `${GET_ADMIN_FORECAST_ENDPOINT}?currenDate=${dateStr}&storeCode=${payload.storeObj.value}`
    )
    .then(({ data }) =>  map(data, (obj) => new AdminForecast(obj))
    );
};

function GraphObject(data) {
  this.createDate = data.businessDay;
  this.salesTime = getTime(data.businessDay);
  this.lastYearSale = data.lastYearSale;
  this.forcastedSale = data.forcastedSale;
  this.adminEdit = data.adminEdit;
  this.actualAdminEdit = data.actualAdminEdit;
  this.salesType = data.salesType;
  this.dayPeriod = data.dayPeriod;
}

export const getGraphDataAPI = (selectedDate) => {
  const year = selectedDate.getFullYear();
  const month = getNumberInTwoDigit(selectedDate.getMonth() + 1);
  const date = getNumberInTwoDigit(selectedDate.getDate());
  const hours = getNumberInTwoDigit(selectedDate.getHours());
  const minutes = getNumberInTwoDigit(selectedDate.getMinutes());
  const seconds = getNumberInTwoDigit(selectedDate.getSeconds());
  return axiosInstance
    .post(`${GET_ADMIN_FORECAST_ENDPOINT}`, {
      salesDate: `${year}-${month}-${date}T${hours}:${minutes}:${seconds}Z`
    })
    .then(({ data }) => map(data, (obj) => new GraphObject(obj)));
};
