import {
  ADD_ADMIN_MANAGER_ACCOUNT_SUCCESS,
  ADD_STORE_ACCOUNT_SUCCESS,
  RESET_ADD_ACCOUNT_SUCCESS_STATE,
  RESET_ADD_MANAGER_ACCOUNT_SUCCESS_STATE
} from "../types/auth";

const initialState = {
  isStoreAccountCreated: false,
  isManagerAccountCreated: false
};

export const authReducer = (state = initialState, { type }) => {
  switch (type) {
    case ADD_STORE_ACCOUNT_SUCCESS:
      return { ...state, isStoreAccountCreated: true };
    case RESET_ADD_ACCOUNT_SUCCESS_STATE:
      return { ...state, isStoreAccountCreated: false };
    case ADD_ADMIN_MANAGER_ACCOUNT_SUCCESS:
      return { ...state, isManagerAccountCreated: true };
    case RESET_ADD_MANAGER_ACCOUNT_SUCCESS_STATE:
      return { ...state, isManagerAccountCreated: false };
    default:
      return state;
  }
};
