import React, { useState } from "react";
import { FiChevronRight } from "react-icons/fi";
import { AiOutlineArrowRight } from "react-icons/ai";
import PropTypes from "prop-types";
import "./Menu.scss";

const Menu = ({ icon, label, menuItems }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className="sidebar-menu">
      <div
        className="sidebar-menu__wrapper"
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        <div>
          {icon} {label}
        </div>{" "}
        <FiChevronRight
          className={`sidebar-menu__chevron ${
            isOpen && "sidebar-menu__chevron_open"
          }`}
        />
      </div>
      {isOpen && (
        <>
          {menuItems.map((item, index) => (
            <div
              className="sidebar-menu__menu_item"
              key={`${item}_${index}`}
              onClick={item.action}
            >
              <AiOutlineArrowRight style={{ marginRight: "12px" }} />
              <span>{item.label}</span>
            </div>
          ))}
        </>
      )}
    </div>
  );
};

Menu.propTypes = {
  icon: PropTypes.node,
  label: PropTypes.string,
  menuItems: PropTypes.array
};

export default Menu;
