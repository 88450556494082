import React from "react";
import PropTypes from "prop-types";
import { noop } from "lodash";
import DatePicker from "react-datepicker";
import { BsCaretLeftFill, BsCaretRightFill } from "react-icons/bs";
import Select from "react-select";

import "react-datepicker/dist/react-datepicker.css";
import "./AdminForecastHeader.scss";

const AdminForecastHeader = ({
  selectedDate,
  setSelectedDate,
  stores,
  selectedStore,
  setSelectedStore,
}) => {

  // Create store data for dropdown
  const storesOptions = stores.map((store) => {
    return { value: store, label: store };
  });

  Date.prototype.addDays = function add(days) {
    const date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
  };

  const goToPrevDate = () => {
    setSelectedDate(new Date(selectedDate.setDate(selectedDate.getDate() - 1)));
  };

  const goToNextDate = () => {
    const D1 = selectedDate.getDate();
    const D2 = new Date().addDays(10).getDate();
    if (D1 === D2) {
      return;
    }
    setSelectedDate(new Date(selectedDate.setDate(selectedDate.getDate() + 1)));
  };

  return (
    <div className="admin-forecast-header">
      <span className="d-flex align-items-center">
        <span
          className="admin-forecast-header__date-picker-arrows"
          onClick={goToPrevDate}
        >
          <BsCaretLeftFill />
        </span>
        <DatePicker
          className="form-control admin-forecast-header__date-picker"
          selected={selectedDate}
          onChange={(update) => {
            setSelectedDate(update);
          }}
          onChangeRaw={(e) => e.preventDefault()}
          maxDate={new Date().addDays(10)}
        />
        <span
          className="admin-forecast-header__date-picker-arrows"
          onClick={goToNextDate}
        >
          <BsCaretRightFill />
        </span>
      </span>
      <div>
        <Select
          value={selectedStore}
          options={storesOptions}
          className="admin-forecast-header__dropdown"
          onChange={(value) => setSelectedStore(value)}
        />
      </div>
    </div>
  );
};

AdminForecastHeader.defaultProps = {
  selectedDate: new Date(),
  setSelectedDate: noop,
  stores: [],
  selectedStore: {},
  setSelectedStore: noop
};

AdminForecastHeader.propTypes = {
  selectedDate: PropTypes.object,
  setSelectedDate: PropTypes.func,
  stores: PropTypes.array,
  selectedStore: PropTypes.object,
  setSelectedStore: PropTypes.func
};

export default AdminForecastHeader;
