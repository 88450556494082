export const API_BASE_URL = "https://admin.mpnc.ditl.in";
export const SIGNAL_R_BASE_URL = "https://admin.mpnc.ditl.in";

// export const API_BASE_URL = "http://localhost:7000";
// export const SIGNAL_R_BASE_URL = "http://localhost:7000";

export const UPDATE_BATCH_MODALS = {
  CONFIRM_COOKED: {
    TITLE: "Confirm the batch has finished cooking?",
    CONFIRM_TIME_COMPLETED_HEADER: "Confirm time completed?"
  }
};

export const USER_ROLE = [
  {
    type: "user",
    role: "StoreUser",
    initialPage: "/"
  },
  {
    type: "admin",
    role: "Store",
    initialPage: "//"
  }
];

const FIVE_MIN_IN_MILLISECONDS = 300000;
const TEN_MIN_IN_MILLISECONDS = 600000;
const FIFTEEN_MIN_IN_MILLISECONDS = 900000;

export const DATA_SYNC_FREQUENCIES = [
  { value: FIVE_MIN_IN_MILLISECONDS, label: "Resync in 5 Min" },
  { value: TEN_MIN_IN_MILLISECONDS, label: "Resync in 10 Min" },
  { value: FIFTEEN_MIN_IN_MILLISECONDS, label: "Resync in 15 Min" }
];

export const SIDEBAR_STYLING = {
  background: "white",
  width: "20%",
  position: "fixed",
  zIndex: 999
};
