import React from "react";
import PropTypes from "prop-types";
import { noop } from "lodash";

const NumberInput = ({ value, onChange }) => {
  const handleInputChange = (e) => {
    let inputValue = e.target.value;

    // Check if the input is a valid number using a regular expression
    if (/^\d+$/.test(inputValue) || inputValue === "") {
      onChange(parseInt(inputValue, 10) || 0);
    }
  };
  return (
    <input
      className="form-control"
      type="number"
      value={value === null ? 0 : value} // Handle null value
      onChange={handleInputChange}
    />
  );
};

NumberInput.defaultProps = {
  value: 0,
  onChange: noop
};

NumberInput.propTypes = {
  value: PropTypes.number,
  onChange: PropTypes.func
};

export default NumberInput;
