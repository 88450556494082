import React, { useEffect, useState } from "react";
import Sidebar from "react-sidebar";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes, { bool } from "prop-types";
import SidebarContent from "../../components/SidebarContent/SidebarContent";
import Navbar from "../../components/Navbar";
import { toggleSidebarAction } from "../../redux/actions/dashboard";
import { isSidebarOpenSelector } from "../../redux/selectors/dashboard";
import NotFound from "../../pages/NotFound/NotFound";
import AdminJobStatusTable from "../../containers/AdminJobStatusTable";
import {
  getCreateBatchJobStatusAction,
  getDataSyncJobStatusAction,
  getSalesHistoryJobStatusAction,
  getSalesIn15MinJobStatusAction
} from "../../redux/actions/admin.jobStatus";
import {
  createBatchJobStatusSelector,
  dataSyncJobStatusSelector,
  loadingCreateBatchJobStatusSelector,
  loadingSalesHistoryJobStatusSelector,
  loadingSalesIn15MinJobStatusSelector,
  loadingDataSyncJobStatusSelector,
  salesHistoryJobStatusSelector,
  salesIn15MinJobStatusSelector
} from "../../redux/selectors/admin.jobStatus";
import { PAGE_TITLES, VALID_JOB_TYPES } from "./constants";
import AdminJobStatusHeader from "../../components/AdminJobStatusHeader/AdminJobStatusHeader";
import { noop, orderBy } from "lodash";
import { isSubstringIgnoreCase } from "../../utils/helpers";
import { SIDEBAR_STYLING } from "../../configs/constants/constants";

const AdminJobStatus = ({
  toggleSidebar,
  isSidebarOpen,
  getCreateBatchJobStatus,
  getDataSyncJobStatus,
  getSalesIn15MinJobStatus,
  getSalesHistoryJobStatus,
  isCreateBatchJobStatusLoading,
  isDataSyncJobStatusLoading,
  isSalesIn15MinJobStatusLoading,
  isSalesHistoryJobStatusLoading,
  createBatchJobStatusData,
  dataSyncJobStatus,
  salesIn15MinJobStatus,
  salesHistoryJobStatus
}) => {
  const { jobType } = useParams();
  if (!VALID_JOB_TYPES.includes(jobType)) return <NotFound />;

  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedSortBy, setSelectedSortBy] = useState(null);
  const [search, setSearch] = useState("");

  useEffect(() => {
    switch (jobType) {
      case "create-batch":
        getCreateBatchJobStatus({
          date: new Date(selectedDate)
        });
        break;
      case "data-sync":
        getDataSyncJobStatus({
          date: new Date(selectedDate)
        });
        break;
      case "sales-15-min":
        getSalesIn15MinJobStatus({
          date: new Date(selectedDate)
        });
        break;
      case "sales-history":
        getSalesHistoryJobStatus({
          date: new Date(selectedDate)
        });
        break;
      default:
        break;
    }
  }, [jobType, selectedDate]);

  const getIsPageLoading = () => {
    switch (jobType) {
      case "create-batch":
        return isCreateBatchJobStatusLoading;
      case "data-sync":
        return isDataSyncJobStatusLoading;
      case "sales-15-min":
        return isSalesIn15MinJobStatusLoading;
      case "sales-history":
        return isSalesHistoryJobStatusLoading;
      default:
        return false;
    }
  };

  const getPageData = () => {
    switch (jobType) {
      case "create-batch":
        return createBatchJobStatusData;
      case "data-sync":
        return dataSyncJobStatus;
      case "sales-15-min":
        return salesIn15MinJobStatus;
      case "sales-history":
        return salesHistoryJobStatus;
      default:
        return [];
    }
  };

  const getSortedPageData = () => {
    const initialPageData = getPageData();
    switch (selectedSortBy?.value) {
      case "Success":
        return orderBy(initialPageData, ["success"], ["asc"]);

      default:
        return initialPageData;
    }
  };

  const filterUsingSearchInput = (item) =>
    isSubstringIgnoreCase(JSON.stringify(item), search);

  return (
    <div>
      <Navbar toggleSidebarAction={toggleSidebar} />
      {isSidebarOpen && (
        <Sidebar
          sidebar={<SidebarContent />}
          open={isSidebarOpen}
          pullRight
          styles={{
            sidebar: SIDEBAR_STYLING
          }}
          shadow={false}
        />
      )}
      <h1 className="mt-3 mb-3 text-center">{PAGE_TITLES[jobType]}</h1>
      <div className="container">
        <AdminJobStatusHeader
          setSelectedDate={setSelectedDate}
          selectedDate={selectedDate}
          setSelectedSortBy={setSelectedSortBy}
          selectedSortBy={selectedSortBy}
          setSearch={setSearch}
          search={search}
        />
      </div>
      <div className="container mt-3">
        <AdminJobStatusTable
          tableInitialData={getSortedPageData().filter(filterUsingSearchInput)}
          isPageLoading={getIsPageLoading()}
          jobType={jobType}
        />
      </div>
    </div>
  );
};

AdminJobStatus.defaultProps = {
  toggleSidebar: noop,
  isSidebarOpen: bool,
  getCreateBatchJobStatus: noop,
  getDataSyncJobStatus: noop,
  getSalesIn15MinJobStatus: noop,
  getSalesHistoryJobStatus: noop,
  isCreateBatchJobStatusLoading: bool,
  isDataSyncJobStatusLoading: bool,
  isSalesIn15MinJobStatusLoading: bool,
  isSalesHistoryJobStatusLoading: bool,
  createBatchJobStatusData: noop,
  dataSyncJobStatus: noop,
  salesIn15MinJobStatus: noop,
  salesHistoryJobStatus: noop
};

AdminJobStatus.propTypes = {
  toggleSidebar: PropTypes.func,
  isSidebarOpen: PropTypes.bool,
  getCreateBatchJobStatus: PropTypes.func,
  getDataSyncJobStatus: PropTypes.func,
  getSalesIn15MinJobStatus: PropTypes.func,
  getSalesHistoryJobStatus: PropTypes.func,
  isCreateBatchJobStatusLoading: PropTypes.bool,
  isDataSyncJobStatusLoading: PropTypes.bool,
  isSalesIn15MinJobStatusLoading: PropTypes.bool,
  isSalesHistoryJobStatusLoading: PropTypes.bool,
  createBatchJobStatusData: PropTypes.func,
  dataSyncJobStatus: PropTypes.func,
  salesIn15MinJobStatus: PropTypes.func,
  salesHistoryJobStatus: PropTypes.func
};

const mapStateToProps = (state) => ({
  isSidebarOpen: isSidebarOpenSelector(state),
  isCreateBatchJobStatusLoading: loadingCreateBatchJobStatusSelector(state),
  isDataSyncJobStatusLoading: loadingDataSyncJobStatusSelector(state),
  isSalesIn15MinJobStatusLoading: loadingSalesIn15MinJobStatusSelector(state),
  isSalesHistoryJobStatusLoading: loadingSalesHistoryJobStatusSelector(state),
  createBatchJobStatusData: createBatchJobStatusSelector(state),
  dataSyncJobStatus: dataSyncJobStatusSelector(state),
  salesIn15MinJobStatus: salesIn15MinJobStatusSelector(state),
  salesHistoryJobStatus: salesHistoryJobStatusSelector(state)
});

const mapDispatchToProps = {
  toggleSidebar: toggleSidebarAction,
  getCreateBatchJobStatus: getCreateBatchJobStatusAction,
  getDataSyncJobStatus: getDataSyncJobStatusAction,
  getSalesIn15MinJobStatus: getSalesIn15MinJobStatusAction,
  getSalesHistoryJobStatus: getSalesHistoryJobStatusAction
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminJobStatus);
