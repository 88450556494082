import React, { useEffect, useState } from "react";
import { BiMenu } from "react-icons/bi";
import { useHistory } from "react-router-dom";
import { noop } from "lodash";
import PropTypes from "prop-types";

import DEVYANI_BRAND_LOGO from "../../assets/images/devyani_logo.svg";

import { getUserRole, parseJwt } from "../../utils/helpers";

import { useCheckInternet } from "../../customHooks/useCheckInternet";
import LiveImg from "../../assets/images/live.gif";
import "./Navbar.scss";

const Navbar = ({ toggleSidebarAction, isStickyNavbar }) => {
  const [storeCode, setStoreCode] = useState("");
  const [headerText, setHeaderText] = useState("Digital MP&C");

  const userRole = getUserRole();
  const { internetConnected } = useCheckInternet();
  useEffect(() => {
    const user = parseJwt(localStorage.getItem("user"));
    setStoreCode(user.StoreCode);
    setHeaderText(
      userRole === "Ceo" ? "Daily Business Summary" : "Digital MP&C",

    );
  }, []);

  const history = useHistory();

  return (
    <div className={`navbar ${isStickyNavbar ? "navbar__sticky_navbar" : ""}`}>
      <div className="navbar__left-section">
        <span
          className="navbar__brand pointer"
          onClick={() =>
            history.push(userRole === "Ceo" ? "/daily-business-summary" : "/")
          }
        >
          <img src={DEVYANI_BRAND_LOGO} height="54px" />
          <span style={{ marginLeft: "16px", fontSize: "24px" }}>
            {storeCode.includes(",") ? null : storeCode}
          </span>
        </span>
      </div>
      <div className="navbar__center-section">
        <span>{headerText}</span>
      </div>

      <div className="navbar__right-section">
        {internetConnected && (
          <div
            className="daily-business-summary__live shadow d-flex justify-content-center align-items-center"
            style={{ borderRadius: "12px" }}
          >
            <img src={LiveImg} width={70} />
          </div>
        )}
        <span
          className="navbar__right-section__report-icon-container"
          onClick={() => toggleSidebarAction()}
        >
          <BiMenu />
        </span>
      </div>
    </div>
  );
};

Navbar.defaultProps = {
  toggleSidebarAction: noop
};

Navbar.propTypes = {
  toggleSidebarAction: PropTypes.func
};

export default Navbar;
