import { all, call } from "redux-saga/effects";

import dashboardSaga from "./dashboard.saga";
import updateBatchSaga from "./updateBatch.saga";
import addBatchSaga from "./addBatch.saga";
import forecastSaga from "./forecast.saga";
import authSaga from "./auth.saga";
import storeSummarySaga from "./storeSummary.saga";
import forecastAccuracySaga from "./forecastAccuracy.saga";
import adminDashboardSaga from "./admin.dashboard.saga";
import adminForecastSaga from "./admin.forecast.saga";
import adminStoreSummarySaga from "./admin.storeSummary";
import adminForecastAccuracySaga from "./admin.forecastAccuracy.saga";
import adminItemsSaga from "./admin.items.saga";
import adminStoresSaga from "./admin.stores.saga";
import adminStoresUsersSaga from "./admin.storeUsers.saga";
import adminUserListSaga from "./admin.userList.saga";
import jobStatusSaga from "./admin.jobStatus.saga";
import dailyBusinessSummarySaga from "./dailyBusinessSummary.saga";

export default function* rootSaga() {
  const sagas = [
    call(dashboardSaga),
    call(updateBatchSaga),
    call(addBatchSaga),
    call(forecastSaga),
    call(authSaga),
    call(storeSummarySaga),
    call(forecastAccuracySaga),
    call(adminDashboardSaga),
    call(adminForecastSaga),
    call(adminStoreSummarySaga),
    call(adminForecastAccuracySaga),
    call(adminItemsSaga),
    call(adminStoresSaga),
    call(adminStoresUsersSaga),
    call(adminUserListSaga),
    call(jobStatusSaga),
    call(dailyBusinessSummarySaga)
  ];

  yield all(sagas);
}
