import {
  GET_ADMIN_FORECAST_ACCURACY_FAILED,
  GET_ADMIN_FORECAST_ACCURACY_REQUEST,
  GET_ADMIN_FORECAST_ACCURACY_SUCCESS
} from "../types/admin.forecastAccuracy";

const initialState = {
  adminForecastAccuracy: [],
  loadingAdminForecastAccuracy: false
};

export const adminForecastAccuracyReducer = (
  state = initialState,
  { type, payload }
) => {
  switch (type) {
    case GET_ADMIN_FORECAST_ACCURACY_REQUEST:
      return {
        ...state,
        adminForecastAccuracy: [],
        loadingAdminForecastAccuracy: true
      };
    case GET_ADMIN_FORECAST_ACCURACY_SUCCESS:
      return {
        ...state,
        adminForecastAccuracy: payload,
        loadingAdminForecastAccuracy: false
      };
    case GET_ADMIN_FORECAST_ACCURACY_FAILED:
      return {
        ...state,
        adminForecastAccuracy: [],
        loadingAdminForecastAccuracy: false
      };
    default:
      return state;
  }
};
