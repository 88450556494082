import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const cellStyle = {
  border: "1px solid #ddd",
  padding: "10px",
  textAlign: "left"
};

const CurrentSalesTableLoader = () => {
  return (
    <tbody style={{ position: "relative", zIndex: -1 }}>
      <tr>
        <td style={cellStyle}>
          <Skeleton />
        </td>
        <td style={cellStyle}>
          <Skeleton />
        </td>
        <td style={cellStyle}>
          <Skeleton />
        </td>
        <td style={cellStyle}>
          <Skeleton />
        </td>
        <td style={cellStyle}>
          <Skeleton />
        </td>
        <td style={cellStyle}>
          <Skeleton />
        </td>
        <td style={cellStyle}>
          <Skeleton />
        </td>
        <td style={cellStyle}>
          <Skeleton />
        </td>
      </tr>
      <tr>
        <td style={cellStyle}>
          <Skeleton />
        </td>
        <td style={cellStyle}>
          <Skeleton />
        </td>
        <td style={cellStyle}>
          <Skeleton />
        </td>
        <td style={cellStyle}>
          <Skeleton />
        </td>
        <td style={cellStyle}>
          <Skeleton />
        </td>
        <td style={cellStyle}>
          <Skeleton />
        </td>
        <td style={cellStyle}>
          <Skeleton />
        </td>
        <td style={cellStyle}>
          <Skeleton />
        </td>
      </tr>
      <tr>
        <td style={cellStyle}>
          <Skeleton />
        </td>
        <td style={cellStyle}>
          <Skeleton />
        </td>
        <td style={cellStyle}>
          <Skeleton />
        </td>
        <td style={cellStyle}>
          <Skeleton />
        </td>
        <td style={cellStyle}>
          <Skeleton />
        </td>
        <td style={cellStyle}>
          <Skeleton />
        </td>
        <td style={cellStyle}>
          <Skeleton />
        </td>
        <td style={cellStyle}>
          <Skeleton />
        </td>
      </tr>
      <tr>
        <td style={cellStyle}>
          <Skeleton />
        </td>
        <td style={cellStyle}>
          <Skeleton />
        </td>
        <td style={cellStyle}>
          <Skeleton />
        </td>
        <td style={cellStyle}>
          <Skeleton />
        </td>
        <td style={cellStyle}>
          <Skeleton />
        </td>
        <td style={cellStyle}>
          <Skeleton />
        </td>
        <td style={cellStyle}>
          <Skeleton />
        </td>
        <td style={cellStyle}>
          <Skeleton />
        </td>
      </tr>
      <tr>
        <td style={cellStyle}>
          <Skeleton />
        </td>
        <td style={cellStyle}>
          <Skeleton />
        </td>
        <td style={cellStyle}>
          <Skeleton />
        </td>
        <td style={cellStyle}>
          <Skeleton />
        </td>
        <td style={cellStyle}>
          <Skeleton />
        </td>
        <td style={cellStyle}>
          <Skeleton />
        </td>
        <td style={cellStyle}>
          <Skeleton />
        </td>
        <td style={cellStyle}>
          <Skeleton />
        </td>
      </tr>
    </tbody>
  );
};

export default CurrentSalesTableLoader;
