import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getDate, getTime } from "../../utils/helpers";
import {
  updateCreateBatchJobStatusAction,
  updateDataSyncJobStatusAction,
  updateSalesHistoryJobStatusAction,
  updateSalesIn15MinJobStatusAction
} from "../../redux/actions/admin.jobStatus";

const TableRow = ({
  storeCode,
  createdDate,
  success,
  jobType,
  updateCreateBatchJobStatus,
  updateDataSyncJobStatus,
  updateSalesIn15MinJobStatus,
  updateSalesHistoryJobStatus
}) => {
  const cellStyleCenter = {
    border: "1px solid #ddd",
    padding: "10px",
    textAlign: "center"
  };
  const cellStyleLeft = {
    border: "1px solid #ddd",
    padding: "10px",
    textAlign: "left"
  };
  const handleSubmit = () => {
    switch (jobType) {
      case "create-batch":
        updateCreateBatchJobStatus({ storeCode });
        break;
      case "data-sync":
        updateDataSyncJobStatus({ storeCode });
        break;
      case "sales-15-min":
        updateSalesIn15MinJobStatus({ storeCode });
        break;
      case "sales-history":
        updateSalesHistoryJobStatus({ storeCode });
        break;
      default:
        break;
    }
  };
  return (
    <tr>
      <td style={cellStyleCenter}>{storeCode}</td>
      <td style={cellStyleLeft}>
        {getDate(createdDate)}, {getTime(createdDate)}
      </td>
      <td
        style={cellStyleLeft}
        className={`${success ? "text-success" : "text-danger"}`}
      >
        {success ? "Passed" : "Failed"}
      </td>
      <td style={cellStyleCenter}>
        {!success && (
          <button className="btn btn-dark" onClick={handleSubmit}>
            Execute
          </button>
        )}
      </td>
    </tr>
  );
};

TableRow.propTypes = {
  storeCode: PropTypes.string,
  createdDate: PropTypes.string,
  success: PropTypes.bool,
  jobType: PropTypes.string,
  updateCreateBatchJobStatus: PropTypes.func,
  updateDataSyncJobStatus: PropTypes.func,
  updateSalesIn15MinJobStatus: PropTypes.func,
  updateSalesHistoryJobStatus: PropTypes.func
};

const mapDispatchToProps = {
  updateCreateBatchJobStatus: updateCreateBatchJobStatusAction,
  updateDataSyncJobStatus: updateDataSyncJobStatusAction,
  updateSalesIn15MinJobStatus: updateSalesIn15MinJobStatusAction,
  updateSalesHistoryJobStatus: updateSalesHistoryJobStatusAction
};

export default connect(null, mapDispatchToProps)(TableRow);
