import React from "react";
import { Link } from "react-router-dom";

const AccessDenied = () => {
  return (
    <div className="vh-100 d-flex align-items-center flex-column p-5 mt-5">
      <h1 className="display-1">404</h1>
      <h2 className="display-2">Uh oh...</h2>
      <h4 className="text-muted">
        We can&apos;t find the page you are looking for.
      </h4>
      <h5 className="text-muted">
        You can go back to{" "}
        <Link className="text-warning" to={'/admin'}>
          dashboard
        </Link>
      </h5>
    </div>
  );
};

export default AccessDenied;
