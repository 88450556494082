import React, { useState } from "react";
import PropTypes, { bool } from "prop-types";
import { Button } from "react-bootstrap";
import { FiEdit } from "react-icons/fi";
import { RiDeleteBin7Line } from "react-icons/ri";

import useTablePagination from "../../customHooks/TablePagination";

import { API_BASE_URL } from "../../configs/constants/constants";

import "./AdminItemsTable.scss";
import AddAndEditItemModal from "./AddAndEditItemModal/AddAndEditItemModal";
import AdminItemsTableLoader from "../../components/SkeletonLoader/AdminItemsTableLoader";
import DeleteItemModal from "./DeleteItemModal/DeleteItemModal";
import { connect } from "react-redux";
import { noop } from "lodash";
import { deleteAdminItemAction } from "../../redux/actions/admin.items";
import {
  cellStyleCenter,
  cellStyleLeft,
  cellStyleRight,
  headerStyle
} from "./constants";

const AdminItemsTable = ({
  adminItems,
  isAdminItemsLoading,
  deleteAdminItem,
  isAddingNewItem,
  setIsAddingNewItem
}) => {
  const [isEditItemModalOpen, setIsEditItemModalOpen] = useState(false);
  const [isDeleteItemModalOpen, setIsDeleteItemModalOpen] = useState(false);
  const [itemSelectedForEdit, setItemSelectedForEdit] = useState({});
  const [itemIdSelectedToDelete, setItemIdSelectedToDelete] = useState("");

  const {
    currentPage,
    totalPages,
    getCurrentPageData,
    nextPage,
    prevPage,
    isFirstPage,
    isLastPage
  } = useTablePagination(adminItems, 5);

  const tableData = getCurrentPageData();

  const handleEditItemModalClose = () => {
    setIsEditItemModalOpen(false);
    setItemSelectedForEdit({});
    setIsAddingNewItem(false);
  };

  const handleItemEdit = (itemId) => {
    const item = tableData.find((item) => item.id === itemId);
    setIsEditItemModalOpen(true);
    setItemSelectedForEdit(item);
  };

  const handleItemDelete = () => {
    deleteAdminItem({ itemId: itemIdSelectedToDelete });
    setIsDeleteItemModalOpen(false);
  };

  const handleDeleteItemModalOpen = (itemId) => {
    setIsDeleteItemModalOpen(true);
    setItemIdSelectedToDelete(itemId);
  };

  const handleDeleteItemModalClose = () => {
    setIsDeleteItemModalOpen(false);
    setItemIdSelectedToDelete("");
  };

  return (
    <>
      <table style={{ width: "100%", borderCollapse: "collapse" }}>
        <thead>
          <tr>
            <th style={headerStyle}>Image</th>
            <th style={headerStyle}>ItemId</th>
            <th style={headerStyle}>Description</th>
            <th style={headerStyle}>Unit</th>
            <th style={headerStyle}>Batch Size</th>
            <th style={headerStyle}>Cook Time</th>
            <th style={headerStyle}>Stage Time</th>
            <th style={headerStyle}>On Hold Time</th>
            <th style={headerStyle}>Expiry Time</th>
            <th style={headerStyle}>Action</th>
          </tr>
        </thead>
        {isAdminItemsLoading ? (
          <AdminItemsTableLoader />
        ) : (
          <tbody>
            {tableData.map(
              ({
                id,
                imagePath,
                itemId,
                description,
                unit,
                batchSize,
                cookTime,
                stagingTime,
                onHoldTime,
                expiryTime
              }) => (
                <tr key={id}>
                  <td style={cellStyleCenter}>
                    {" "}
                    <img
                      height={80}
                      width={80}
                      src={
                        imagePath ? `${API_BASE_URL}/${imagePath}` : imagePath
                      }
                      className="batch-tile-with-image__img"
                    />
                  </td>
                  <td style={cellStyleCenter}>{itemId}</td>
                  <td style={cellStyleLeft}>{description}</td>
                  <td style={cellStyleCenter}>{unit}</td>
                  <td style={cellStyleRight}>{batchSize}</td>
                  <td style={cellStyleRight}>{cookTime}</td>
                  <td style={cellStyleRight}>{stagingTime}</td>
                  <td style={cellStyleRight}>{onHoldTime}</td>
                  <td style={cellStyleRight}>{expiryTime}</td>
                  <td style={cellStyleCenter}>
                    <FiEdit onClick={() => handleItemEdit(id)} />
                    <RiDeleteBin7Line
                      onClick={() => {
                        handleDeleteItemModalOpen(itemId);
                      }}
                    />
                  </td>
                </tr>
              )
            )}
          </tbody>
        )}
      </table>
      {tableData && tableData.length ? (
        <div className="mt-3 d-flex justify-content-center align-items-center">
          <Button variant="dark" disabled={isFirstPage} onClick={prevPage}>
            Previous Page
          </Button>
          <span className="mx-2">
            {currentPage}/{totalPages}
          </span>
          <Button variant="dark" disabled={isLastPage} onClick={nextPage}>
            Next Page
          </Button>
        </div>
      ) : null}
      {(isEditItemModalOpen || isAddingNewItem) && (
        <AddAndEditItemModal
          isEditItemModalOpen={isEditItemModalOpen || isAddingNewItem}
          setIsEditItemModalOpen={setIsEditItemModalOpen}
          handleModalClose={handleEditItemModalClose}
          item={itemSelectedForEdit}
          isAddingNewItem={isAddingNewItem}
        />
      )}
      {isDeleteItemModalOpen && (
        <DeleteItemModal
          isOpen={isDeleteItemModalOpen}
          onConfirm={handleItemDelete}
          onRequestClose={handleDeleteItemModalClose}
        />
      )}
    </>
  );
};

AdminItemsTable.defaultProps = {
  adminItems: [],
  isAdminItemsLoading: bool,
  deleteAdminItem: noop,
  isAddingNewItem: bool,
  setIsAddingNewItem: noop
};

AdminItemsTable.propTypes = {
  adminItems: PropTypes.array,
  isAdminItemsLoading: PropTypes.bool,
  deleteAdminItem: PropTypes.func,
  isAddingNewItem: PropTypes.bool,
  setIsAddingNewItem: PropTypes.func
};

const mapDispatchToProps = {
  deleteAdminItem: deleteAdminItemAction
};

export default connect(null, mapDispatchToProps)(AdminItemsTable);
