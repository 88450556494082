import { toast } from "react-toastify";
import { call, put, takeLatest } from "redux-saga/effects";

import {
  confirmCompleteAPI,
  deleteBatchAPI,
  updateConfirmedCookedTimeAPI,
  updateExpiryTimeAPI,
  updateQuantityAPI,
  updateWasteQuantityAPI
} from "../../api/updateBatch";
import { OPEN_UPDATE_BATCH_MODAL } from "../types/dashboard";

import {
  CONFIRM_COMPLETE_FAILURE,
  CONFIRM_COMPLETE_REQUEST,
  CONFIRM_COMPLETE_SUCCESS,
  DELETE_BATCH_FAILURE,
  DELETE_BATCH_REQUEST,
  DELETE_BATCH_SUCCESS,
  UPDATE_CONFIRMED_COOKED_TIME_FAILURE,
  UPDATE_CONFIRMED_COOKED_TIME_REQUEST,
  UPDATE_CONFIRMED_COOKED_TIME_SUCCESS,
  UPDATE_EXPIRY_TIME_FAILURE,
  UPDATE_EXPIRY_TIME_REQUEST,
  UPDATE_EXPIRY_TIME_SUCCESS,
  UPDATE_QUANTITY_FAILURE,
  UPDATE_QUANTITY_REQUEST,
  UPDATE_QUANTITY_SUCCESS,
  UPDATE_WASTE_QUANTITY_FAILURE,
  UPDATE_WASTE_QUANTITY_REQUEST,
  UPDATE_WASTE_QUANTITY_SUCCESS
} from "../types/updateBatch";

function* watchUpdateBatchSaga() {
  /**
   * UPDATE COOKED TIME
   */
  yield takeLatest(
    UPDATE_CONFIRMED_COOKED_TIME_REQUEST,
    function* updateConfirmedTimeCooked({ payload }) {
      try {
        const response = yield call(updateConfirmedCookedTimeAPI, payload);
        toast.success(response);
        yield put({ type: UPDATE_CONFIRMED_COOKED_TIME_SUCCESS });
        yield put({ type: OPEN_UPDATE_BATCH_MODAL, payload: { open: false } });
      } catch (err) {
        yield put({ type: UPDATE_CONFIRMED_COOKED_TIME_FAILURE });
        toast.error("Some error occured while updating time");
      }
    }
  );

  /**
   * UPDATE EXIRY TIME
   */
  yield takeLatest(
    UPDATE_EXPIRY_TIME_REQUEST,
    function* udpateExpiryTime({ payload }) {
      try {
        const response = yield call(updateExpiryTimeAPI, payload);
        toast.success(response);
        yield put({ type: UPDATE_EXPIRY_TIME_SUCCESS });
        yield put({ type: OPEN_UPDATE_BATCH_MODAL, payload: { open: false } });
      } catch (err) {
        yield put({ type: UPDATE_EXPIRY_TIME_FAILURE });
        toast.error("Some error occured while updating expiry time");
      }
    }
  );

  /**
   * UPDATE QUANTITY
   */
  yield takeLatest(
    UPDATE_QUANTITY_REQUEST,
    function* updateQuantity({ payload }) {
      try {
        const response = yield call(updateQuantityAPI, payload);
        toast.success(response);
        yield put({ type: UPDATE_QUANTITY_SUCCESS });
        yield put({ type: OPEN_UPDATE_BATCH_MODAL, payload: { open: false } });
      } catch (err) {
        yield put({ type: UPDATE_QUANTITY_FAILURE });
        toast.error("Some error occured while updating quantity");
      }
    }
  );

  /**
   * UPDATE WASTE QUANTITY
   */
  yield takeLatest(
    UPDATE_WASTE_QUANTITY_REQUEST,
    function* updateWasteQuantity({ payload }) {
      try {
        const response = yield call(updateWasteQuantityAPI, payload);
        toast.success(response);
        yield put({ type: UPDATE_WASTE_QUANTITY_SUCCESS });
        yield put({ type: OPEN_UPDATE_BATCH_MODAL, payload: { open: false } });
      } catch (err) {
        yield put({ type: UPDATE_WASTE_QUANTITY_FAILURE });
        toast.error("Some error occured while updating waste quantity");
      }
    }
  );

  /**
   * DELETE BATCH
   */
  yield takeLatest(DELETE_BATCH_REQUEST, function* deleteBatch({ payload }) {
    try {
      const response = yield call(deleteBatchAPI, payload);
      toast.success(response);
      yield put({ type: DELETE_BATCH_SUCCESS });
      yield put({ type: OPEN_UPDATE_BATCH_MODAL, payload: { open: false } });
    } catch (err) {
      yield put({ type: DELETE_BATCH_FAILURE });
      toast.error("Some error occured while deleting batch");
    }
  });

  /**
   * CONFIRM COMPLETE BATCH
   */
  yield takeLatest(
    CONFIRM_COMPLETE_REQUEST,
    function* confirmComplete({ payload }) {
      try {
        const response = yield call(confirmCompleteAPI, payload);
        toast.success(response);
        yield put({ type: CONFIRM_COMPLETE_SUCCESS, payload });
        yield put({ type: OPEN_UPDATE_BATCH_MODAL, payload: { open: false } });
      } catch (err) {
        yield put({ type: CONFIRM_COMPLETE_FAILURE });
        toast.error("Some error while confirm complete batch");
      }
    }
  );
}

export default function* updateBatchSaga() {
  yield call(watchUpdateBatchSaga);
}
