import { createSelector } from "reselect";

const dailyBusinessSummaryState = (state) => state.dailyBusinessSummary;

/**
 * FORECAST ACCURACY DATA : GET
 */
export const dailyBusinessSummarySelector = createSelector(
  dailyBusinessSummaryState,
  (FA) => FA.dailyBusinessSummary
);

/**
 * IS FORECAST ACCURACY LOADING
 */
export const isDailyBusinessSummaryLoadingSelector = createSelector(
  dailyBusinessSummaryState,
  (FA) => FA.loadingDailyBusinessSummary
);
