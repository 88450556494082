import { useEffect, useState } from "react";

export const useCheckInternet = () => {
  const [internetConnected, setInternetConnected] = useState(
    !!window?.navigator?.onLine
  );

  useEffect(() => {
    window.addEventListener("offline", () => setInternetConnected(false));
    window.addEventListener("online", () => setInternetConnected(true));
  }, []);

  return { internetConnected };
};
