export const VALID_JOB_TYPES = [
  "create-batch",
  "data-sync",
  "sales-15-min",
  "sales-history"
];

export const PAGE_TITLES = {
  "create-batch": "Create Batch Job Status",
  "data-sync": "Data Sync Job Status",
  "sales-15-min": "Sales 15 Min Job Status",
  "sales-history": "Sales History Job Status"
};
