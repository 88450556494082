import React from "react";
import PropTypes from "prop-types";
import Modal from "react-modal";
import { FaExclamationTriangle, FaCheck } from "react-icons/fa";
import { MdClose } from "react-icons/md";
import "./DeleteStoreUserModal.scss";

const DeleteStoreUserModal = ({ isOpen, onRequestClose, onConfirm, message }) => {
  const customEditModalStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      padding: 0,
      width: "480px"
    }
  };
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      ariaHideApp={false}
      shouldCloseOnOverlayClick
      style={customEditModalStyles}
    >
      <div className="modal-content admin-delete-item-modal">
        <div className="modal-header">
          <h5 className="modal-title">Delete Confirmation</h5>
          <MdClose
            className="admin-delete-item-modal__close_icon"
            size="1.5em"
            color="black"
            onClick={onRequestClose}
          />
        </div>
        <div className="modal-body">
          <div className="d-flex align-items-center">
            <FaExclamationTriangle className="text-warning mr-2" />
            <p className="m-0 mx-2">{message}</p>
          </div>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-secondary"
            onClick={onRequestClose}
          >
            Cancel
          </button>
          <button type="button" className="btn btn-danger" onClick={onConfirm}>
            <FaCheck className="mr-1" /> Confirm
          </button>
        </div>
      </div>
    </Modal>
  );
};

DeleteStoreUserModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired
};

DeleteStoreUserModal.defaultProps = {
  isOpen: false,
  onRequestClose: () => {},
  onConfirm: () => {},
  message: "Are you sure you want to delete this item?"
};

export default DeleteStoreUserModal;
