import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const cellStyle = {
  border: "1px solid #ddd",
  padding: "10px",
  textAlign: "left"
};

const AdminItemsTableLoader = () => {
  return (
    <tbody>
      {[...Array(10).keys()].map((row) => (
        <tr key={row}>
          {[...Array(10).keys()].map((column) => (
            <td style={cellStyle} key={column}>
              <Skeleton />
            </td>
          ))}
        </tr>
      ))}
    </tbody>
  );
};

export default AdminItemsTableLoader;
