import React, { useState } from "react";
import Modal from "react-modal";
import { MdClose } from "react-icons/md";
import PropTypes, { bool } from "prop-types";
import { noop } from "lodash";
import "./UpdateEmailModal.scss";

const UpdateEmailModal = ({
  isUpdateEmailModalOpen,
  handleModalClose,
  updateEmail,
  email
}) => {
  const [newEmail, setNewEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleSubmit = () => {
    if (!newEmail) {
      setErrorMessage("New Email is required.");
      return;
    }

    // Update email logic here
    updateEmail({
      oldEmail: email,
      newEmail
    });

    handleModalClose();
  };

  const customUpdateEmailModalStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      padding: 0,
      width: "380px"
    }
  };

  return (
    <Modal
      isOpen={isUpdateEmailModalOpen}
      style={customUpdateEmailModalStyles}
      ariaHideApp={false}
      shouldCloseOnOverlayClick
      onRequestClose={handleModalClose}
    >
      <div className="container p-3 update-email-modal">
        <MdClose
          className="update-email-modal__close_icon"
          size="1.5em"
          color="black"
          onClick={handleModalClose}
        />
        <div className="row mt-3">
          <p className="text-center">
            Update Email for: <strong>{email}</strong>
          </p>
          <div className="mb-3">
            <label className="form-label">
              New Email<span className="text-danger">*</span>
            </label>
            <input
              className="form-control"
              type="text"
              value={newEmail}
              onChange={(e) => setNewEmail(e.target.value)}
            />
          </div>
        </div>
        <p className="text-center text-danger update-email-modal__warning_text">
          {errorMessage}
        </p>
        <div className="row">
          <div className="col-md-12 text-center">
            <button className="btn btn-dark mx-2" onClick={handleSubmit}>
              Update Email
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

UpdateEmailModal.defaultProps = {
  updateEmail: noop,
  isUpdateEmailModalOpen: bool,
  handleModalClose: noop,
  email: ""
};

UpdateEmailModal.propTypes = {
  updateEmail: PropTypes.func,
  isUpdateEmailModalOpen: PropTypes.bool,
  handleModalClose: PropTypes.func,
  email: PropTypes.string
};

export default UpdateEmailModal;
