import { find, groupBy } from "lodash";
import { createSelector } from "reselect";

const dashboardState = (state) => state.dashboard;

export const getActiveItemsSelector = createSelector(
  dashboardState,
  (DS) => DS.activeItems
);

export const getOpenUpdateBatchModal = createSelector(
  dashboardState,
  (DS) => DS.openUpdateBatchModal
);

export const getOpenAddBatchModalSelector = createSelector(
  dashboardState,
  (DS) => DS.openAddBatchModal
);

export const isBatchLoadingSelector = createSelector(
  dashboardState,
  (DS) => DS.isBatchLoading
);

export const getBatchSelector = createSelector(dashboardState, (DS) => {
  const groupedBatch = groupBy(DS.batch, (batch) => batch.itemId);
  return groupedBatch;
});

export const getDetailsForAddBatchSelector = createSelector(
  dashboardState,
  (DS) => DS.batchDetailsToAdd
);

/**
 * GET selected batch item from store for update batch modal
 */
export const getSelectedBatchItemSelector = createSelector(
  dashboardState,
  (DS) => DS.selectedBatchItem
);

export const getSelectedBatchItemColorSelector = createSelector(
  dashboardState,
  (DS) => DS.selectedBatchItem.tileColor
);

export const getTrendByBatchIdSelector = createSelector(
  dashboardState,
  (DS) => (batchId) => {
    const trendData = DS.trend;
    return find(trendData, { itemNo: batchId });
  }
);

export const getActiveCollapseMenu = createSelector(
  dashboardState,
  (DS) => DS.activeItemCollapseMenu
);

export const getTrendTimeSelector = createSelector(
  dashboardState,
  (DS) => DS.trendTime
);

export const getDropQtySelector = createSelector(
  dashboardState,
  (DS) => DS.itemDropQty
);

export const getSignalRConnectionSelector = createSelector(
  dashboardState,
  (DS) => DS.signalR
);

export const isSidebarOpenSelector = createSelector(
  dashboardState,
  (DS) => DS.isSidebarOpen
);
