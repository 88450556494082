import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { addAdminAccountAction } from "../../redux/actions/auth";
import { isStoreAccountCreatedSelector } from "../../redux/selectors/auth";
import PropTypes, { bool } from "prop-types";
import { noop } from "lodash";

const AddStore = ({ addAdminAccount, isStoreAccountCreated }) => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    storeCodes: "",
    hostName: "",
    DbName: "",
    ServerIP: "",
    DBUserName: "",
    DBPassword: ""
  });

  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (isStoreAccountCreated) {
      setFormData({
        name: "",
        email: "",
        password: "",
        storeCodes: "",
        hostName: "",
        DbName: "",
        ServerIP: "",
        DBUserName: "",
        DBPassword: ""
      });
    }
  }, [isStoreAccountCreated]);

  const validateForm = () => {
    const newErrors = {};
    // Basic validation for required fields
    if (!formData.name) {
      newErrors.name = "Name is required";
    }
    if (!formData.email) {
      newErrors.email = "Email is required";
    } else if (!/^\S+@\S+\.\S+$/.test(formData.email)) {
      newErrors.email = "Invalid email address";
    }
    if (!formData.password) {
      newErrors.password = "Password is required";
    }
    if (!formData.storeCodes) {
      newErrors.storeCodes = "Store Codes are required";
    }
    if (!formData.hostName) {
      newErrors.hostName = "Host Name is required";
    }
    if (!formData.DbName) {
      newErrors.DbName = "Database Name is required";
    }
    if (!formData.ServerIP) {
      newErrors.ServerIP = "Server IP is required";
    }
    if (!formData.DBUserName) {
      newErrors.DBUserName = "Database Username is required";
    }
    if (!formData.DBPassword) {
      newErrors.DBPassword = "Database Password is required";
    }
    return newErrors;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
    // Clear validation error if the user starts typing again
    setErrors({ ...errors, [name]: "" });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const newErrors = validateForm();
    if (Object.keys(newErrors).length === 0) {
      // No validation errors, you can send the data to your server or perform actions here
      addAdminAccount(formData);
    } else {
      // Validation errors, display them to the user
      setErrors(newErrors);
    }
  };

  return (
    <div className="row mt-4">
      <div className="mb-3 col-6">
        <label htmlFor="name" className="form-label">
          Name<span className="text-danger">*</span>
        </label>
        <input
          type="text"
          className={`form-control ${errors.name ? "is-invalid" : ""}`}
          id="name"
          name="name"
          value={formData.name}
          onChange={handleChange}
        />
        {errors.name && <div className="invalid-feedback">{errors.name}</div>}
      </div>
      <div className="mb-3 col-6">
        <label htmlFor="email" className="form-label">
          Email<span className="text-danger">*</span>
        </label>
        <input
          type="text"
          className={`form-control ${errors.email ? "is-invalid" : ""}`}
          id="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
        />
        {errors.email && <div className="invalid-feedback">{errors.email}</div>}
      </div>
      <div className="mb-3 col-6">
        <label htmlFor="password" className="form-label">
          Password<span className="text-danger">*</span>
        </label>
        <input
          type="text"
          className={`form-control ${errors.password ? "is-invalid" : ""}`}
          id="password"
          name="password"
          value={formData.password}
          onChange={handleChange}
        />
        {errors.password && (
          <div className="invalid-feedback">{errors.password}</div>
        )}
      </div>
      <div className="mb-3 col-6">
        <label htmlFor="storeCodes" className="form-label">
          Store Code<span className="text-danger">*</span>
        </label>
        <input
          type="text"
          className={`form-control ${errors.storeCodes ? "is-invalid" : ""}`}
          id="storeCodes"
          name="storeCodes"
          value={formData.storeCodes}
          onChange={handleChange}
        />
        {errors.storeCodes && (
          <div className="invalid-feedback">{errors.storeCodes}</div>
        )}
      </div>
      <div className="mb-3 col-6">
        <label htmlFor="hostName" className="form-label">
          Host Name<span className="text-danger">*</span>
        </label>
        <input
          type="text"
          className={`form-control ${errors.hostName ? "is-invalid" : ""}`}
          id="hostName"
          name="hostName"
          value={formData.hostName}
          onChange={handleChange}
        />
        {errors.hostName && (
          <div className="invalid-feedback">{errors.hostName}</div>
        )}
      </div>
      <div className="mb-3 col-6">
        <label htmlFor="DbName" className="form-label">
          Db Name<span className="text-danger">*</span>
        </label>
        <input
          type="text"
          className={`form-control ${errors.DbName ? "is-invalid" : ""}`}
          id="DbName"
          name="DbName"
          value={formData.DbName}
          onChange={handleChange}
        />
        {errors.DbName && (
          <div className="invalid-feedback">{errors.DbName}</div>
        )}
      </div>
      <div className="mb-3 col-6">
        <label htmlFor="ServerIP" className="form-label">
          Server IP<span className="text-danger">*</span>
        </label>
        <input
          type="text"
          className={`form-control ${errors.ServerIP ? "is-invalid" : ""}`}
          id="ServerIP"
          name="ServerIP"
          value={formData.ServerIP}
          onChange={handleChange}
        />
        {errors.ServerIP && (
          <div className="invalid-feedback">{errors.ServerIP}</div>
        )}
      </div>
      <div className="mb-3 col-6">
        <label htmlFor="DBUserName" className="form-label">
          DB User Name<span className="text-danger">*</span>
        </label>
        <input
          type="text"
          className={`form-control ${errors.DBUserName ? "is-invalid" : ""}`}
          id="DBUserName"
          name="DBUserName"
          value={formData.DBUserName}
          onChange={handleChange}
        />
        {errors.DBUserName && (
          <div className="invalid-feedback">{errors.DBUserName}</div>
        )}
      </div>
      <div className="mb-3 col-6">
        <label htmlFor="DBPassword" className="form-label">
          DB Password<span className="text-danger">*</span>
        </label>
        <input
          type="text"
          className={`form-control ${errors.DBPassword ? "is-invalid" : ""}`}
          id="DBPassword"
          name="DBPassword"
          value={formData.DBPassword}
          onChange={handleChange}
        />
        {errors.DBPassword && (
          <div className="invalid-feedback">{errors.DBPassword}</div>
        )}
      </div>
      <div className="d-flex justify-content-center mt-3">
        <button onClick={handleSubmit} className="btn btn-dark">
          Add Account
        </button>
      </div>
    </div>
  );
};

AddStore.defaultProps = {
  isStoreAccountCreated: bool,
  addAdminAccount: noop
};

AddStore.propTypes = {
  isStoreAccountCreated: PropTypes.bool,
  addAdminAccount: PropTypes.func
};

const mapStateToProps = (state) => ({
  isStoreAccountCreated: isStoreAccountCreatedSelector(state)
});

const mapDispatchToProps = { addAdminAccount: addAdminAccountAction };

export default connect(mapStateToProps, mapDispatchToProps)(AddStore);
