import React, { useState } from "react";
import Modal from "react-modal";
import { MdClose } from "react-icons/md";
import { connect } from "react-redux";
import PropTypes, { bool } from "prop-types";
import { noop } from "lodash";
import "./AddAndEditStoreModal.scss";
import { updateAdminStoreAction } from "../../../redux/actions/admin.stores";

const AddAndEditStoreModal = ({
  isEditStoreModalOpen,
  handleModalClose,
  store,
  updateAdminStore,
  isAddingNewStore
}) => {
  const [address, setAddress] = useState(store.address ?? "");
  const [serverIP, setServerIP] = useState(store.serverIP ?? "");
  const [dbName, setDBName] = useState(store.dbName ?? "");
  const [userId, setUserId] = useState(store.userId ?? "");
  const [password, setPassword] = useState(store.password ?? "");
  const [serverName, setServerName] = useState(store.serverName ?? "");
  const [storeCode, setStoreCode] = useState(store.storeCode ?? "");
  const [errorMessage, setErrorMessage] = useState("");

  const isDataChanged = () => {
    // Compare each property to its initial value
    return (
      address !== store.address ||
      serverIP !== store.serverIP ||
      dbName !== store.dbName ||
      userId !== store.userId ||
      password !== store.password ||
      serverName !== store.serverName ||
      storeCode !== store.storeCode
    );
  };

  const handleSubmit = () => {
    if (!storeCode) {
      setErrorMessage("Store Code is required");
      return;
    }
    const updatedEntry = {
      ...store,
      storeCode,
      address,
      serverIP,
      dbName,
      userId,
      password,
      serverName
    };
    if (isDataChanged()) {
      updateAdminStore({ apiBody: updatedEntry, isNewStore: isAddingNewStore });
    }
    handleModalClose();
  };
  const customEditModalStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      padding: 0,
      width: "680px"
    }
  };

  return (
    <Modal
      isOpen={isEditStoreModalOpen}
      style={customEditModalStyles}
      ariaHideApp={false}
      shouldCloseOnOverlayClick
      onRequestClose={handleModalClose}
    >
      <div className="container p-3 admin-edit-store-modal">
        <MdClose
          className="admin-edit-store-modal__close_icon"
          size="1.5em"
          color="black"
          onClick={handleModalClose}
        />
        <div className="row mt-3">
          <div className="mb-3 col-md-6">
            <label className="form-label">
              Store Code<span className="text-danger">*</span>
            </label>
            <input
              className="form-control"
              type="text"
              value={storeCode}
              onChange={(e) => setStoreCode(e.target.value)}
              readOnly={!isAddingNewStore}
            />
          </div>
          <div className="mb-3 col-md-6">
            <label className="form-label">Address</label>
            <input
              className="form-control"
              type="text"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
            />
          </div>
          <div className="mb-3 col-md-6">
            <label className="form-label">User Name</label>
            <input
              className="form-control"
              type="text"
              value={userId}
              onChange={(e) => setUserId(e.target.value)}
            />
          </div>
          <div className="mb-3 col-md-6">
            <label className="form-label">Password</label>
            <input
              className="form-control"
              type="text"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div className="mb-3 col-md-6">
            <label className="form-label">Server IP</label>
            <input
              className="form-control"
              type="text"
              value={serverIP}
              onChange={(e) => setServerIP(e.target.value)}
            />
          </div>
          <div className="mb-3 col-md-6">
            <label className="form-label">Server Name</label>
            <input
              className="form-control"
              type="text"
              value={serverName}
              onChange={(e) => setServerName(e.target.value)}
            />
          </div>
          <div className="mb-3 col-md-6">
            <label className="form-label">DB Name</label>
            <input
              className="form-control"
              type="text"
              value={dbName}
              onChange={(e) => setDBName(e.target.value)}
            />
          </div>
        </div>
        <p className="text-center text-danger admin-edit-store-modal__warning_text">
          {errorMessage}
        </p>
        <div className="row">
          <div className="col-md-12 text-center">
            <button className="btn btn-dark  mx-2" onClick={handleSubmit}>
              Save
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

AddAndEditStoreModal.defaultProps = {
  updateAdminStore: noop,
  isEditStoreModalOpen: bool,
  store: {},
  handleModalClose: noop,
  isAddingNewStore: bool
};

AddAndEditStoreModal.propTypes = {
  updateAdminStore: PropTypes.func,
  isEditStoreModalOpen: PropTypes.bool,
  store: PropTypes.object,
  handleModalClose: PropTypes.func,
  isAddingNewStore: PropTypes.bool
};

const mapDispatchToProps = {
  updateAdminStore: updateAdminStoreAction
};

export default connect(null, mapDispatchToProps)(AddAndEditStoreModal);
