import { orderBy } from "lodash";
import moment from "moment";
import * as XLSX from "xlsx";

/**
 * Get time format
 * @param {Date} date
 * @returns {String} date
 */
export const getTime = (date) => {
  const D = moment(date);
  return D.format("hh:mm A");
};

export const getDate = (date, format) => {
  const D = moment(date);
  return D.format(format || "DD MMM YYYY");
};

/**
 * Validate hour /minute
 * @param {Number} number
 * @param {Number} limit
 * @returns {Boolean}
 */
export const validateTime = (number, limit) => {
  let validNumber = Math.abs(number);
  if (validNumber > limit) {
    validNumber = Math.abs(number.slice(0, -1));
  }
  return validNumber;
};

export const getNumberInTwoDigit = (number) => {
  return ("0" + number).slice(-2);
};

export const addMinutes = (date, minutes) => {
  return new Date(date.getTime() + minutes * 60000);
};

export const addSeconds = (date, seconds) => {
  return new Date(date.getTime() + seconds * 1000);
};

export const getLastRundedFifteenMinute = () => {
  const round = 1000 * 60 * 15;
  const date = new Date();
  return new Date(Math.floor(date.getTime() / round) * round);
};

export const sortBatch = (batch) => {
  return orderBy(batch, ["itemId"], ["asc"]);
};

export const getAccessTokenFromLocalStorage = () => {
  const tokenObj = localStorage.getItem("user");
  return tokenObj;
};

export const parseJwt = (token) => {
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map((c) => {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
};

export const getStoresFromLocalStorage = () => {
  const token = getAccessTokenFromLocalStorage();
  const decodedToken = parseJwt(token);
  return decodedToken.StoreCode.split(",");
};

export const getUserRole = () => {
  const token = getAccessTokenFromLocalStorage();
  if (token) {
    const parsedToken = parseJwt(token);
    return parsedToken.Role;
  }
  return null;
};

export const subtractDaysFromDateObj = (date, days) => {
  const result = new Date(date);
  result.setDate(result.getDate() - days);
  return result;
};

export const exportToExcel = (jsonData, fileName) => {
  const worksheet = XLSX.utils.json_to_sheet(jsonData);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
  XLSX.writeFile(workbook, fileName);
};

export const getUptoTwoDecimalPoints = (number) => {
  if (typeof number !== "number") return number;
  if (Number.isInteger(number)) return number;
  return number.toFixed(2);
};

export const getUptoZeroDecimalPoints = (number) => {
  if (typeof number !== "number") return number;
  if (Number.isInteger(number)) return number;
  return number.toFixed(0);
};

export const isSubstringIgnoreCase = (mainString, subString) => {
  // Convert both strings to lowercase
  if (!mainString) {
    return true;
  }
  if (!subString) {
    return true;
  }
  const lowerMainString = mainString.toLowerCase();
  const lowerSubString = subString.toLowerCase();

  // Check if the lowercased main string includes the lowercased sub string
  return lowerMainString.includes(lowerSubString);
};

export const addCommas = (number) => {
  if (!number) return number;
  const parts = number.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
};
