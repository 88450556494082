import React, { useEffect, useState } from "react";
import Sidebar from "react-sidebar";
import PropTypes, { bool } from "prop-types";
import { connect } from "react-redux";
// import { noop } from "lodash";
import SidebarContent from "../../components/SidebarContent/SidebarContent";
import Navbar from "../../components/Navbar";
import { toggleSidebarAction } from "../../redux/actions/dashboard";
import { isSidebarOpenSelector } from "../../redux/selectors/dashboard";
import { getAdminUserListAction } from "../../redux/actions/admin.userList";
import AdminUserListTable from "../../containers/AdminUserListTable";
import {
  adminUserListSelector,
  isAdminUserListLoadingSelector,
  totalUsersSelector
} from "../../redux/selectors/admin.userList";
import { noop } from "lodash";
import { SIDEBAR_STYLING } from "../../configs/constants/constants";

const AdminUserList = ({
  toggleSidebar,
  isSidebarOpen,
  getAdminUserList,
  adminUserListData,
  isAdminUserListLoading,
  totalUsers
}) => {
  const [isAddingNewStore, setIsAddingNewStore] = useState(false);
  const [search] = useState("");
  useEffect(() => {
    getAdminUserList({ startIndex: 0, itemsPerPage: 50 });
  }, []);

  return (
    <div>
      <Navbar toggleSidebarAction={toggleSidebar} />
      {isSidebarOpen && (
        <Sidebar
          sidebar={<SidebarContent />}
          open={isSidebarOpen}
          pullRight
          styles={{
            sidebar: SIDEBAR_STYLING
          }}
          shadow={false}
        />
      )}
      <div className="container mt-3">
        <AdminUserListTable
          adminUserList={adminUserListData}
          isAdminUserListLoading={isAdminUserListLoading}
          isAddingNewStore={isAddingNewStore}
          setIsAddingNewStore={setIsAddingNewStore}
          search={search}
          getAdminUserList={getAdminUserList}
          totalUsers={totalUsers}
        />
      </div>
    </div>
  );
};

AdminUserList.defaultProps = {
  toggleSidebar: noop,
  isSidebarOpen: bool,
  getAdminUserList: noop,
  adminUserListData: [],
  isAdminUserListLoading: bool,
  totalUsers: []
};

AdminUserList.propTypes = {
  toggleSidebar: PropTypes.func,
  isSidebarOpen: PropTypes.bool,
  getAdminUserList: PropTypes.func,
  adminUserListData: PropTypes.array,
  isAdminUserListLoading: PropTypes.bool,
  totalUsers: PropTypes.array
};

const mapStateToProps = (state) => ({
  isSidebarOpen: isSidebarOpenSelector(state),
  adminUserListData: adminUserListSelector(state),
  isAdminUserListLoading: isAdminUserListLoadingSelector(state),
  totalUsers: totalUsersSelector(state)
});

const mapDispatchToProps = {
  toggleSidebar: toggleSidebarAction,
  getAdminUserList: getAdminUserListAction
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminUserList);
