export const GET_ADMIN_USER_LIST_REQUEST = "GET_ADMIN_USER_LIST_REQUEST";
export const GET_ADMIN_USER_LIST_SUCCESS = "GET_ADMIN_USER_LIST_SUCCESS";
export const GET_ADMIN_USER_LIST_FAILURE = "GET_ADMIN_USER_LIST_FAILURE";

export const UPDATE_ADMIN_USER_STATUS = "UPDATE_ADMIN_USER_STATUS";
export const UPDATE_ADMIN_USER_PRINT_STATUS = "UPDATE_ADMIN_USER_PRINT_STATUS";

export const UPDATE_ADMIN_USER_PASSWORD_REQUEST =
  "UPDATE_ADMIN_USER_PASSWORD_REQUEST";

export const UPDATE_ADMIN_USER_EMAIL_REQUEST =
  "UPDATE_ADMIN_USER_EMAIL_REQUEST";
