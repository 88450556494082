import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";

import store from "./redux/store";

import Login from "./pages/Login";
import AdminDashboard from "./pages/AdminDashboard/AdminDashboard";
import AdminForecastEvaluator from "./pages/AdminForecastEvaluator/AdminForecastEvaluator";
import AdminStoreSummary from "./pages/AdminStoreSummary/AdminStoreSummary";
import AdminForecastAccuracy from "./pages/AdminForecastAccuracy/AdminForecastAccuracy";
import AccessDenied from "./pages/AccessDenied/AccessDenied";
import NotFound from "./pages/NotFound/NotFound";
import AdminCurrentSales from "./pages/AdminCurrentSales";
import AdminAddStoreAccount from "./pages/AdminAddStoreAccount";
import AdminAddManagerAccount from "./pages/AdminAddManagerAccount";

import ProtectedRoute from "./components/ProtectedRoute";

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/global.scss";
import "./App.scss";
import AdminItems from "./pages/AdminItems";
import AdminStores from "./pages/AdminStores";
import AdminStoreUsers from "./pages/AdminStoreUsers";
import AdminUserList from "./pages/AdminUserList";
import AdminJobStatus from "./pages/AdminJobStatus/AdminJobStatus";
import DailyBusinessSummary from "./pages/DailyBusinessSummary";

function App() {
  return (
    <Provider store={store}>
      <Router>
        <Switch>
          <Route exact path="/login" component={Login} />
          <ProtectedRoute exact path="/" component={AdminDashboard} />
          <ProtectedRoute
            exact
            path="/admin-forecast-evaluator"
            component={AdminForecastEvaluator}
          />
          <ProtectedRoute
            exact
            path="/admin-store-summary"
            component={AdminStoreSummary}
          />
          <ProtectedRoute
            exact
            path="/admin-forecast-accuracy"
            component={AdminForecastAccuracy}
          />
          <ProtectedRoute
            exact
            path="/admin-current-sales"
            component={AdminCurrentSales}
          />
          <ProtectedRoute exact path="/admin-items" component={AdminItems} />
          <ProtectedRoute exact path="/admin-stores" component={AdminStores} />
          <ProtectedRoute
            exact
            path="/admin-store-users"
            component={AdminStoreUsers}
          />
          <ProtectedRoute
            exact
            path="/add-store"
            component={AdminAddStoreAccount}
          />
          <ProtectedRoute
            exact
            path="/add-manager"
            component={AdminAddManagerAccount}
          />
          <ProtectedRoute
            exact
            path="/admin-list-users"
            component={AdminUserList}
          />
          <ProtectedRoute
            exact
            path="/job-status/:jobType"
            component={AdminJobStatus}
          />
          <ProtectedRoute
            exact
            path="/daily-business-summary"
            component={DailyBusinessSummary}
          />
          <Route exact path="/access-denied" component={AccessDenied} />
          <Route path="" component={NotFound} />
        </Switch>
      </Router>
      <ToastContainer position="top-right" />
    </Provider>
  );
}

export default App;
