import { toast } from "react-toastify";
import { call, takeLatest, put } from "redux-saga/effects";
import {
  GET_DAILY_BUSINESS_SUMMARY_FAILED,
  GET_DAILY_BUSINESS_SUMMARY_REQUEST,
  GET_DAILY_BUSINESS_SUMMARY_SUCCESS
} from "../types/dailyBusinessSummary";
import { getDailyBusinessSummaryAPI } from "../../api/dailyBusinessSummary";

function* watchDailyBusinessSummarySaga() {
  yield takeLatest(
    GET_DAILY_BUSINESS_SUMMARY_REQUEST,
    function* getDailyBusinessSummarySaga({ payload }) {
      try {
        const data = yield call(getDailyBusinessSummaryAPI, payload);
        if (data) {
          yield put({
            type: GET_DAILY_BUSINESS_SUMMARY_SUCCESS,
            payload: data
          });
        }
      } catch (e) {
        console.log("e ===>", e);
        yield put({ type: GET_DAILY_BUSINESS_SUMMARY_FAILED });
        toast.error(
          "Something went wrong while fetching daily business summary"
        );
      }
    }
  );
}

export default function* dailyBusinessSummarySaga() {
  yield call(watchDailyBusinessSummarySaga);
}
