import React, { useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { BsThreeDotsVertical } from "react-icons/bs";
import PropTypes from "prop-types";
import "./Dropdown.scss";
const CustomDropdown = ({ options }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Dropdown show={isOpen} onToggle={toggleDropdown} drop="down">
      <Dropdown.Toggle
        variant="light"
        id="dropdown-custom-toggle"
        className="admin-user-list-dropdown__button"
        split={false}
      >
        <BsThreeDotsVertical size={16} />
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {options.map((option, index) => (
          <Dropdown.Item onClick={option.action} key={index}>
            {option.label}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

CustomDropdown.propTypes = {
  options: PropTypes.array
};

export default CustomDropdown;
