import {
  GET_ADMIN_FORECAST_REQUEST,
  UPDATE_ADMIN_FORECAST_DATE,
  UPDATE_ADMIN_FORECAST_SELECTED_STORE
} from "../types/admin.forecast";

export const getAdminForecastAction = (payload) => {
  return {
    type: GET_ADMIN_FORECAST_REQUEST,
    payload
  };
};

export const updateAdminForecastDateAction = (payload) => ({
  type: UPDATE_ADMIN_FORECAST_DATE,
  payload
});

export const updateAdminForecastStoreAction = (payload) => ({
  type: UPDATE_ADMIN_FORECAST_SELECTED_STORE,
  payload
});
