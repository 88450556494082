import { toast } from "react-toastify";
import { call, takeLatest, put } from "redux-saga/effects";
import {
  GET_ADMIN_STORE_SUMMARY_FAILED,
  GET_ADMIN_STORE_SUMMARY_REQUEST,
  GET_ADMIN_STORE_SUMMARY_SUCCESS
} from "../types/admin.storeSummary";
import { getAdminStoreSummaryAPI } from "../../api/admin.storeSummary";

function* watchAdminStoreSummarySaga() {
  /**
   * GET STORE SUMMARY
   */
  yield takeLatest(
    GET_ADMIN_STORE_SUMMARY_REQUEST,
    function* storeSummarySaga({ payload }) {
      try {
        const data = yield call(getAdminStoreSummaryAPI, payload);
        if (data) {
          yield put({ type: GET_ADMIN_STORE_SUMMARY_SUCCESS, payload: data });
        }
      } catch (e) {
        console.warn("e ===>", e);
        yield put({ type: GET_ADMIN_STORE_SUMMARY_FAILED });
        toast.error(
          "Something went wrong while fetching admin store summary"
        );
      }
    }
  );
}

export default function* adminStoreSummarySaga() {
  yield call(watchAdminStoreSummarySaga);
}
