import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { noop } from "lodash";

import Button from "react-bootstrap/Button";
import TableLoader from "../../components/SkeletonLoader/CurrentSalesTableLoader";

import useTablePagination from "../../customHooks/TablePagination";

import { getStoreCurrentSalesAction } from "../../redux/actions/admin.dashboard";
import {
  getStoresCurrentSalesSelector,
  isFetchingStoreCurrentSalesSelector
} from "../../redux/selectors/dashboard.admin";
import {
  getUptoTwoDecimalPoints,
  isSubstringIgnoreCase
} from "../../utils/helpers";
import CustomDatePicker from "../../components/CustomDatePicker/CustomDatePicker";

const headerStyle = {
  backgroundColor: "#0A4091",
  color: "#fff",
  padding: "10px",
  textAlign: "left"
};

const cellStyleRight = {
  border: "1px solid #ddd",
  padding: "10px",
  textAlign: "right"
};

const cellStyleLeft = {
  border: "1px solid #ddd",
  padding: "10px",
  textAlign: "left"
};

const TableForAdminDashboard = ({
  getStoresCurrentSales,
  isFetchingStoresCurrentSales,
  storeCurrentSalesData
}) => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [search, setSearch] = useState("");
  const [pageData, setPageData] = useState(storeCurrentSalesData);

  useEffect(() => {
    getStoresCurrentSales({ date: selectedDate });
  }, [selectedDate]);

  const {
    currentPage,
    totalPages,
    getCurrentPageData,
    nextPage,
    prevPage,
    isFirstPage,
    isLastPage
  } = useTablePagination(pageData, 10);

  const tableData = getCurrentPageData();

  useEffect(() => {
    let filteredData = storeCurrentSalesData.filter((item) =>
      isSubstringIgnoreCase(item.storeCode, search)
    );
    setPageData(filteredData);
  }, [search, storeCurrentSalesData]);

  return (
    <div className="shadow p-4 mb-5">
      <div className="d-flex justify-content-between mb-3">
        <div style={{ width: "18%" }}>
          <input
            className="form-control"
            type="text"
            placeholder="Search by Store Code"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
        <h4 className="text-center">Store Current Sales</h4>
        <CustomDatePicker
          onChange={(newDate) => {
            setSelectedDate(newDate);
          }}
          value={selectedDate}
        />
      </div>
      <table style={{ width: "100%", borderCollapse: "collapse" }}>
        <thead>
          <tr>
            <th style={headerStyle}>Store Code</th>
            <th style={headerStyle}>Actual Sales</th>
            <th style={headerStyle}>Forecast Sales</th>
            <th style={headerStyle}>Manager Sales</th>
            <th style={headerStyle}>System Variance</th>
            <th style={headerStyle}>Manager Variance</th>
            <th style={headerStyle}>System Accuracy %</th>
            <th style={headerStyle}>Manager Accuracy %</th>
          </tr>
        </thead>
        {isFetchingStoresCurrentSales ? (
          <TableLoader />
        ) : (
          <tbody>
            {tableData
              .filter((item) => isSubstringIgnoreCase(item.storeCode, search))
              .map((item) => (
                <tr key={item.storeCode}>
                  <td style={cellStyleLeft}>{item.storeCode}</td>
                  <td style={cellStyleRight}>
                    {getUptoTwoDecimalPoints(item.actualSales)}
                  </td>
                  <td style={cellStyleRight}>
                    {getUptoTwoDecimalPoints(item.forecastSales)}
                  </td>
                  <td style={cellStyleRight}>
                    {getUptoTwoDecimalPoints(item.adminSales)}
                  </td>
                  <td style={cellStyleRight}>
                    {getUptoTwoDecimalPoints(item.systemVariance)}
                  </td>
                  <td style={cellStyleRight}>
                    {getUptoTwoDecimalPoints(item.adminVariance)}
                  </td>
                  <td style={cellStyleRight}>
                    {getUptoTwoDecimalPoints(item.systemAccuracy)}
                  </td>
                  <td style={cellStyleRight}>
                    {getUptoTwoDecimalPoints(item.adminAccuracy)}
                  </td>
                </tr>
              ))}
          </tbody>
        )}
        {!isFetchingStoresCurrentSales && tableData.length === 0 ? (
          <tbody>
            <tr>
              <td
                style={cellStyleRight}
                colSpan={9}
                className="text-center p-3"
              >
                No store current sales found
              </td>
            </tr>
          </tbody>
        ) : null}
      </table>
      {tableData && tableData.length ? (
        <div className="mt-3 d-flex justify-content-center align-items-center">
          <Button variant="dark" disabled={isFirstPage} onClick={prevPage}>
            Previous Page
          </Button>
          <span className="mx-2">
            {currentPage}/{totalPages}
          </span>
          <Button variant="dark" disabled={isLastPage} onClick={nextPage}>
            Next Page
          </Button>
        </div>
      ) : null}
    </div>
  );
};

TableForAdminDashboard.defaultProps = {
  isFetchingStoresCurrentSales: false,
  storeCurrentSalesData: [],
  getStoresCurrentSales: noop
};

TableForAdminDashboard.propTypes = {
  isFetchingStoresCurrentSales: PropTypes.bool,
  storeCurrentSalesData: PropTypes.array,
  getStoresCurrentSales: PropTypes.func
};

const mapStateToProps = (state) => ({
  isFetchingStoresCurrentSales: isFetchingStoreCurrentSalesSelector(state),
  storeCurrentSalesData: getStoresCurrentSalesSelector(state)
});

const mapDispatchToProps = {
  getStoresCurrentSales: getStoreCurrentSalesAction
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TableForAdminDashboard);
