import { createSelector } from "reselect";
import { sortBy, isEqual, uniqWith } from "lodash";

const adminStoresState = (state) => state.adminStoreUsers;

export const adminStoreUsersSelector = createSelector(
  adminStoresState,
  (state) => {
    const sortedData = sortBy(state.users, (obj) => obj.storeCode);
    return uniqWith(sortedData, isEqual);
  }
);

export const isAdminStoreUsersLoadingSelector = createSelector(
  adminStoresState,
  (state) => state.loadingAdminStoreUsers
);
