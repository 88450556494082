import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import OverviewCard from "../../components/OverviewCard/OverviewCard";
import { connect } from "react-redux";
import {
  getActiveInactiveStoreAction,
  getTotalStoreSalesAction
} from "../../redux/actions/admin.dashboard";
import {
  activeInactiveStoresSelector,
  getStoreLastTxSelector,
  getTotalStoreSalesSelector,
  isFetchingActiveInactiveSelector,
  isFetchingTotalStoreSalesSelector
} from "../../redux/selectors/dashboard.admin";
import { noop } from "lodash";
import Modal from "react-modal";

import "./DashboardOverview.scss";
import { addCommas, getUptoTwoDecimalPoints } from "../../utils/helpers";
import CustomDatePicker from "../../components/CustomDatePicker/CustomDatePicker";

const headerStyle = {
  backgroundColor: "lightgray",
  color: "black",
  padding: "10px",
  textAlign: "left"
};

const cellStyle = {
  border: "1px solid #ddd",
  padding: "10px",
  textAlign: "left"
};

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: 0,
    width: "680px"
  }
};

const DashboardOverview = ({
  getActiveInactiveStore,
  activeInactiveStores,
  storeLastTx,
  getTotalStoreSales,
  totalStoreSales,
  isFetchingTotalStoreSales,
  isFetchingActiveInactive
}) => {
  const [activeCard, setActiveCard] = useState(null);
  const [storeData, setStoreData] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());

  useEffect(() => {
    const { activeStores, inactiveStores } = activeInactiveStores;
    if (activeCard) {
      let arr;
      if (activeCard === "Total Stores") {
        const fullStr = activeStores + ", " + inactiveStores;
        arr = fullStr.split(", ");
      } else if (activeCard === "Active Stores") {
        arr = activeStores.split(", ");
      } else {
        arr = inactiveStores.split(", ");
      }
      const newArr = [];
      arr.forEach((storeCode) => {
        const findObj = storeLastTx.find((obj) => obj.storeCode === storeCode);
        newArr.push(findObj);
        setStoreData(newArr);
      });
    } else {
      setStoreData([]);
    }
  }, [activeCard]);

  useEffect(() => {
    getActiveInactiveStore({ date: selectedDate });
    getTotalStoreSales({ date: selectedDate });
  }, [selectedDate]);

  return (
    <>
      <div className="d-flex justify-content-end mb-3 mx-4">
        <CustomDatePicker
          onChange={(newDate) => {
            setSelectedDate(newDate);
          }}
          value={selectedDate}
        />
      </div>
      <div className="d-flex justify-content-start mb-4 flex-wrap dashboard-overview-modal__row-gap-24">
        <OverviewCard
          title="Total Actual Sales"
          setActiveCard={() => {}}
          count={
            addCommas(
              getUptoTwoDecimalPoints(totalStoreSales?.totalActualSales)
            ) || 0
          }
          isLoading={isFetchingTotalStoreSales}
        />
        <OverviewCard
          title="Total System Forecast Sales"
          setActiveCard={() => {}}
          count={
            addCommas(
              getUptoTwoDecimalPoints(totalStoreSales?.totalSystemForcastSales)
            ) || 0
          }
          isLoading={isFetchingTotalStoreSales}
        />
        <OverviewCard
          title="Total Manager Forecast Sales"
          setActiveCard={() => {}}
          count={
            addCommas(
              getUptoTwoDecimalPoints(totalStoreSales?.totalManagerForcastSales)
            ) || 0
          }
          isLoading={isFetchingTotalStoreSales}
        />
      </div>
      <div className="d-flex justify-content-start mb-4 flex-wrap dashboard-overview-modal__row-gap-24">
        <OverviewCard
          title="System Accuracy"
          setActiveCard={() => {}}
          count={
            addCommas(
              getUptoTwoDecimalPoints(totalStoreSales?.systemAccuracy)
            ) || 0
          }
          isLoading={isFetchingTotalStoreSales}
        />
        <OverviewCard
          title="Manager Accuracy"
          setActiveCard={() => {}}
          count={
            addCommas(
              getUptoTwoDecimalPoints(totalStoreSales?.managerAccuracy)
            ) || 0
          }
          isLoading={isFetchingTotalStoreSales}
        />
      </div>
      <hr className="dashboard-overview-modal__hr" />
      <div className="d-flex justify-content-start">
        <OverviewCard
          title="Total Stores"
          setActiveCard={setActiveCard}
          count={addCommas(activeInactiveStores.totalStore) || 0}
          isLoading={isFetchingActiveInactive}
        />
        <OverviewCard
          title="Active Stores"
          setActiveCard={setActiveCard}
          count={addCommas(activeInactiveStores.activeStoresCount) || 0}
          isLoading={isFetchingActiveInactive}
        />
        <OverviewCard
          title="Inactive Stores"
          setActiveCard={setActiveCard}
          count={addCommas(activeInactiveStores.inactiveStoresCount) || 0}
          isLoading={isFetchingActiveInactive}
        />
        <Modal
          isOpen={!!activeCard}
          style={customStyles}
          ariaHideApp={false}
          shouldCloseOnOverlayClick
          onRequestClose={() => {
            setActiveCard(null);
          }}
        >
          <header className="dashboard-overview-modal__header">
            <span>{activeCard}</span>
          </header>
          <main className="dashboard-overview-modal__content">
            <table style={{ width: "100%", borderCollapse: "collapse" }}>
              <thead>
                <tr>
                  <th style={headerStyle}>Store Code</th>
                  <th style={headerStyle}>Name</th>
                </tr>
              </thead>
              <tbody>
                {storeData
                  .filter((item) => !!item)
                  .map((item) => (
                    <tr key={item.storeCode}>
                      <td style={cellStyle}>{item.storeCode}</td>
                      <td style={cellStyle}>{item.address}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </main>
          <footer className="mx-2 my-2 d-flex justify-content-center">
            <button
              onClick={() => {
                setActiveCard(null);
              }}
              className="btn btn-dark"
            >
              Close
            </button>
          </footer>
        </Modal>
      </div>
    </>
  );
};

DashboardOverview.defaultProps = {
  getActiveInactiveStore: noop,
  activeInactiveStores: [],
  storeLastTx: []
};

DashboardOverview.propTypes = {
  getActiveInactiveStore: PropTypes.func,
  activeInactiveStores: PropTypes.object,
  storeLastTx: PropTypes.array
};

const mapStateToProps = (state) => ({
  activeInactiveStores: activeInactiveStoresSelector(state),
  storeLastTx: getStoreLastTxSelector(state),
  totalStoreSales: getTotalStoreSalesSelector(state),
  isFetchingTotalStoreSales: isFetchingTotalStoreSalesSelector(state),
  isFetchingActiveInactive: isFetchingActiveInactiveSelector(state)
});

const mapDispatchToProps = {
  getActiveInactiveStore: getActiveInactiveStoreAction,
  getTotalStoreSales: getTotalStoreSalesAction
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardOverview);
