import {
  GET_ADMIN_USER_LIST_REQUEST,
  UPDATE_ADMIN_USER_EMAIL_REQUEST,
  UPDATE_ADMIN_USER_PASSWORD_REQUEST,
  UPDATE_ADMIN_USER_PRINT_STATUS,
  UPDATE_ADMIN_USER_STATUS
} from "../types/admin.userList";

export const getAdminUserListAction = (payload) => {
  return {
    type: GET_ADMIN_USER_LIST_REQUEST,
    payload
  };
};

export const updateAdminUserStatusAction = (payload) => {
  return {
    type: UPDATE_ADMIN_USER_STATUS,
    payload
  };
};

export const updateAdminUserPrintStatusAction = (payload) => {
  return {
    type: UPDATE_ADMIN_USER_PRINT_STATUS,
    payload
  };
};

export const updateAdminUserPasswordAction = (payload) => {
  return {
    type: UPDATE_ADMIN_USER_PASSWORD_REQUEST,
    payload
  };
};

export const updateAdminUserEmailAction = (payload) => {
  return {
    type: UPDATE_ADMIN_USER_EMAIL_REQUEST,
    payload
  };
};
