import {
  GET_DAILY_BUSINESS_SUMMARY_FAILED,
  GET_DAILY_BUSINESS_SUMMARY_REQUEST,
  GET_DAILY_BUSINESS_SUMMARY_SUCCESS
} from "../types/dailyBusinessSummary";

const initialState = {
  dailyBusinessSummary: [],
  loadingDailyBusinessSummary: false
};

export const dailyBusinessSummaryReducer = (
  state = initialState,
  { type, payload }
) => {
  switch (type) {
    case GET_DAILY_BUSINESS_SUMMARY_REQUEST:
      console.log(payload, "payload");
      return {
        ...state,
        loadingDailyBusinessSummary: payload.skipLoader ? false : true
      };
    case GET_DAILY_BUSINESS_SUMMARY_SUCCESS:
      return {
        ...state,
        dailyBusinessSummary: payload,
        loadingDailyBusinessSummary: false
      };
    case GET_DAILY_BUSINESS_SUMMARY_FAILED:
      return {
        ...state,
        dailyBusinessSummary: [],
        loadingDailyBusinessSummary: false
      };
    default:
      return state;
  }
};
