import {
  GET_ADMIN_CREATE_BATCH_JOB_STATUS_REQUEST,
  GET_ADMIN_DATA_SYNC_JOB_STATUS_REQUEST,
  GET_ADMIN_SALES_15_MIN_JOB_STATUS_REQUEST,
  GET_ADMIN_SALES_HISTORY_JOB_STATUS_REQUEST,
  UPDATE_ADMIN_CREATE_BATCH_JOB_STATUS_REQUEST,
  UPDATE_ADMIN_DATA_SYNC_JOB_STATUS_REQUEST,
  UPDATE_ADMIN_SALES_15_MIN_JOB_STATUS_REQUEST,
  UPDATE_ADMIN_SALES_HISTORY_JOB_STATUS_REQUEST
} from "../types/admin.jobStatus";

export const getCreateBatchJobStatusAction = (payload) => {
  return {
    type: GET_ADMIN_CREATE_BATCH_JOB_STATUS_REQUEST,
    payload
  };
};
export const getDataSyncJobStatusAction = (payload) => {
  return {
    type: GET_ADMIN_DATA_SYNC_JOB_STATUS_REQUEST,
    payload
  };
};
export const getSalesIn15MinJobStatusAction = (payload) => {
  return {
    type: GET_ADMIN_SALES_15_MIN_JOB_STATUS_REQUEST,
    payload
  };
};
export const getSalesHistoryJobStatusAction = (payload) => {
  return {
    type: GET_ADMIN_SALES_HISTORY_JOB_STATUS_REQUEST,
    payload
  };
};
export const updateCreateBatchJobStatusAction = (payload) => {
  return {
    type: UPDATE_ADMIN_CREATE_BATCH_JOB_STATUS_REQUEST,
    payload
  };
};
export const updateDataSyncJobStatusAction = (payload) => {
  return {
    type: UPDATE_ADMIN_DATA_SYNC_JOB_STATUS_REQUEST,
    payload
  };
};
export const updateSalesIn15MinJobStatusAction = (payload) => {
  return {
    type: UPDATE_ADMIN_SALES_15_MIN_JOB_STATUS_REQUEST,
    payload
  };
};
export const updateSalesHistoryJobStatusAction = (payload) => {
  return {
    type: UPDATE_ADMIN_SALES_HISTORY_JOB_STATUS_REQUEST,
    payload
  };
};
