import {
  GET_ADMIN_FORECAST_FAILURE,
  GET_ADMIN_FORECAST_REQUEST,
  GET_ADMIN_FORECAST_SUCCESS,
  UPDATE_ADMIN_FORECAST_DATE,
  UPDATE_ADMIN_FORECAST_SELECTED_STORE
} from "../types/admin.forecast";

const initialState = {
  isLoadingAdminForecast: false,
  adminForecastData: [],
  adminForecastDate: new Date(),
  selectedStore: null
};

export const adminForecastReducer = (
  state = initialState,
  { type, payload }
) => {
  switch (type) {
    case GET_ADMIN_FORECAST_REQUEST:
      return {
        ...state,
        isLoadingAdminForecast: true,
        adminForecastData: []
      };
    case GET_ADMIN_FORECAST_SUCCESS:
      return {
        ...state,
        isLoadingAdminForecast: false,
        adminForecastData: payload
      };
    case GET_ADMIN_FORECAST_FAILURE:
      return {
        ...state,
        isLoadingAdminForecast: false,
        adminForecastData: []
      };
    case UPDATE_ADMIN_FORECAST_DATE:
      return { ...state, adminForecastDate: payload };
    case UPDATE_ADMIN_FORECAST_SELECTED_STORE:
      return { ...state, selectedStore: payload };
    default:
      return state;
  }
};
