import { createSelector } from "reselect";

const adminStoresState = (state) => state.adminUserList;

export const adminUserListSelector = createSelector(
  adminStoresState,
  (state) => {
    return state.users;
  }
);

export const isAdminTotalUsersSelector = createSelector(
  adminStoresState,
  (state) => state.totalUsers
);

export const isAdminUserListLoadingSelector = createSelector(
  adminStoresState,
  (state) => state.loadingAdminUserList
);

export const totalUsersSelector = createSelector(
  adminStoresState,
  (state) => state.totalUsers
);
