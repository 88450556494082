import axiosInstance from "../configs/axios";

const ADMIN_FORECAST_ACCURACY_API_ENDPOINT = "/api/admin/sales/StoreAccuracy";

export function ForecastAccuracy(item) {
  this.salesType = item.salesType;
  this.actualSales = item.actualSales;
  this.adminAccuracy = item.adminAccuracy;
  this.adminForcast = item.adminForcast;
  this.adminVariance = item.adminVariance;
  this.systemAccuracy = item.systemAccuracy;
  this.systemForcast = item.systemForcast;
  this.systemVariance = item.systemVariance;
}

export const getAdminForecastAccuracyAPI = (payload) => {
  const dateObj = new Date(payload.date);
  const dateStr = `${dateObj.getFullYear()}-${
    dateObj.getMonth() + 1
  }-${dateObj.getDate()}`;
  return axiosInstance
    .get(
      `${ADMIN_FORECAST_ACCURACY_API_ENDPOINT}?storeCode=${payload.store.value}&currenDate=${dateStr}`
    )
    .then(({ data }) => data.map((item) => new ForecastAccuracy(item)));
};
