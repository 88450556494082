import React from "react";
import Dropdown from "./Dropdown/Dropdown";
import { getDate, getTime } from "../../utils/helpers";
import { connect } from "react-redux";
import {
  updateAdminUserPrintStatusAction,
  updateAdminUserStatusAction
} from "../../redux/actions/admin.userList";
import PropTypes from "prop-types";

const TableRow = ({
  email,
  storeCode,
  createDate,
  isEnable,
  isPrintAllow,
  updateAdminUserStatus,
  startIndex,
  handleResetModalOpen,
  handleUpdateEmailModalOpen,
  updateAdminUserPrintStatus
}) => {
  const cellStyleCenter = {
    border: "1px solid #ddd",
    padding: "10px",
    textAlign: "center"
  };
  const cellStyleLeft = {
    border: "1px solid #ddd",
    padding: "10px",
    textAlign: "left"
  };
  const actionItems = [
    {
      label: "Reset Password",
      action: () => {
        // Handle Option 1 action here
        handleResetModalOpen(email);
      }
    },
    {
      label: isEnable ? "Disable User" : "Enable User",
      action: () => {
        updateAdminUserStatus({
          email,
          isEnable: !isEnable,
          startIndex
        });
      }
    },
    {
      label: isPrintAllow ? "Disable Print" : "Enable Print",
      action: () => {
        updateAdminUserPrintStatus({
          email,
          isPrintAllow: !isPrintAllow,
          startIndex
        });
      }
    },
    {
      label: "Update Email",
      action: () => {
        // Handle Option 3 action here
        handleUpdateEmailModalOpen(email);
      }
    }
  ];

  return (
    <tr>
      <td style={cellStyleCenter}>{storeCode}</td>
      <td style={cellStyleLeft}>{email}</td>
      <td style={cellStyleLeft}>
        {getDate(createDate)}, {getTime(createDate)}
      </td>
      <td
        style={cellStyleLeft}
        className={`${isEnable ? "text-success" : "text-danger"}`}
      >
        {isEnable ? "Enabled" : "Disabled"}
      </td>
      <td
        style={cellStyleLeft}
        className={`${isPrintAllow ? "text-success" : "text-danger"}`}
      >
        {isPrintAllow ? "Enabled" : "Disabled"}
      </td>
      <td style={cellStyleCenter}>
        <Dropdown options={actionItems} key={email} />
      </td>
    </tr>
  );
};

TableRow.propTypes = {
  email: PropTypes.string,
  storeCode: PropTypes.string,
  createDate: PropTypes.string,
  isEnable: PropTypes.bool,
  updateAdminUserStatus: PropTypes.func,
  startIndex: PropTypes.number,
  handleResetModalOpen: PropTypes.func,
  handleUpdateEmailModalOpen: PropTypes.func
};

const mapDispatchToProps = {
  updateAdminUserStatus: updateAdminUserStatusAction,
  updateAdminUserPrintStatus: updateAdminUserPrintStatusAction
};

export default connect(null, mapDispatchToProps)(TableRow);
