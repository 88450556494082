import axiosInstance from "../configs/axios";
import { v4 as uuidv4 } from "uuid";

const GET_ADMIN_ITEMS_ENDPOINT = "/api/admin/sales/Items";
const UPDATE_ADMIN_ITEM_ENDPOINT = "/api/admin/sales/Item";
const DELETE_ADMIN_ITEM_ENDPOINT = "/api/admin/sales/Item?itemCode=";

function AdminItem(item) {
  this.id = uuidv4();
  this.imagePath = item.imagePath;
  this.itemId = item.itemId;
  this.description = item.description;
  this.unit = item.unit;
  this.batchSize = item.batchSize;
  this.cookTime = item.cookTime;
  this.stagingTime = item.stagingTime;
  this.onHoldTime = item.onHoldTime;
  this.expiryTime = item.expiryTime;
}

export const getAdminItemsApi = () => {
  return axiosInstance
    .get(GET_ADMIN_ITEMS_ENDPOINT)
    .then(({ data }) => data.map((item) => new AdminItem(item)));
};

export const updateAdminItemApi = (payload) => {
  const {
    itemId,
    description,
    unit,
    imagePath,
    cookTime,
    stagingTime,
    onHoldTime,
    expiryTime,
    batchSize
  } = payload;

  return axiosInstance
    .post(UPDATE_ADMIN_ITEM_ENDPOINT, {
      itemId,
      description,
      unit,
      imagePath,
      cookTime,
      stagingTime,
      onHoldTime,
      expiryTime,
      batchSize
    })
    .then(({ data }) => data);
};

export const deleteAdminItemApi = (payload) => {
  const { itemId } = payload;

  return axiosInstance
    .delete(`${DELETE_ADMIN_ITEM_ENDPOINT}${itemId}`)
    .then(({ data }) => data);
};
