import React, { useState } from "react";
import Modal from "react-modal";
import { MdClose } from "react-icons/md";
import { connect } from "react-redux";
import PropTypes, { bool } from "prop-types";
import { noop } from "lodash";
// import { updateAdminStoreAction } from "../../../redux/actions/admin.stores";
import { AddAdminStoreUserAction } from "../../../redux/actions/admin.storeUsers";
import "./AddStoreUserModal.scss";

const AddStoreUserModal = ({
  isEditStoreModalOpen,
  handleModalClose,
  store,
  AddAdminStoreUser,
  isAddingNewStore
}) => {
  const [storeCode, setStoreCode] = useState(store.storeCode ?? "");
  const [email, setEmail] = useState(store.email ?? "");
  const [errorMessage, setErrorMessage] = useState("");

  const handleSubmit = () => {
    if (!storeCode) {
      setErrorMessage("Store Code is required");
      return;
    }
    const updatedEntry = {
      ...store,
      storeCode,
      email
    };
    AddAdminStoreUser({
      apiBody: updatedEntry,
      isNewStore: isAddingNewStore
    });
    handleModalClose();
  };
  const customEditModalStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      padding: 0,
      width: "380px"
    }
  };

  return (
    <Modal
      isOpen={isEditStoreModalOpen}
      style={customEditModalStyles}
      ariaHideApp={false}
      shouldCloseOnOverlayClick
      onRequestClose={handleModalClose}
    >
      <div className="container p-3 admin-edit-store-modal">
        <MdClose
          className="admin-edit-store-modal__close_icon"
          size="1.5em"
          color="black"
          onClick={handleModalClose}
        />
        <div className="row mt-3">
          <div className="mb-3">
            <label className="form-label">
              Store Code<span className="text-danger">*</span>
            </label>
            <input
              className="form-control"
              type="text"
              value={storeCode}
              onChange={(e) => setStoreCode(e.target.value)}
              readOnly={!isAddingNewStore}
            />
          </div>
          <div className="mb-3">
            <label className="form-label">Email</label>
            <input
              className="form-control"
              type="text"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
        </div>
        <p className="text-center text-danger admin-edit-store-modal__warning_text">
          {errorMessage}
        </p>
        <div className="row">
          <div className="col-md-12 text-center">
            <button className="btn btn-dark  mx-2" onClick={handleSubmit}>
              Save
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

AddStoreUserModal.defaultProps = {
  AddAdminStoreUser: noop,
  isEditStoreModalOpen: bool,
  store: {},
  handleModalClose: noop,
  isAddingNewStore: bool
};

AddStoreUserModal.propTypes = {
  AddAdminStoreUser: PropTypes.func,
  isEditStoreModalOpen: PropTypes.bool,
  store: PropTypes.object,
  handleModalClose: PropTypes.func,
  isAddingNewStore: PropTypes.bool
};

const mapDispatchToProps = {
  AddAdminStoreUser: AddAdminStoreUserAction
};

export default connect(null, mapDispatchToProps)(AddStoreUserModal);
