import axiosInstance from "../configs/axios";
import _ from "lodash";

const DAILY_BUSINESS_SUMMARY_API_ENDPOINT = "/api/manager/GetPatchSales";

const getProcessedData = (data) => {
  // Group data by state
  const groupedData = _.groupBy(data, "state");

  // Map grouped data to get required format
  const result = _.map(groupedData, (stores, state) => {
    // Calculate required values for each state
    const storeCount = stores.length;
    const todaySales = _.sumBy(stores, "todaySales");
    const lastWeekSales = _.sumBy(stores, "lastWeekSales");
    const todayTx = _.sumBy(stores, "todayTx");
    const lastWeekTx = _.sumBy(stores, "lastWeekTx");
    const todayAPC = calculateAPC(todaySales, todayTx);//((todaySales * 100)/todayTx) - 100;// _.sumBy(stores, "todayAPC");
    const lastWeekAPC = calculateAPC(lastWeekSales, lastWeekTx);//((lastWeekSales * 100)/lastWeekTx) - 100;//_.sumBy(stores, "lastWeekAPC");
    const salesSeq = calculateExpression(todaySales, lastWeekSales);//((todaySales * 100) / lastWeekSales) -100;// _.meanBy(stores, "salesSeq");
    const apcSeq = calculateExpression(todayAPC, lastWeekAPC);// ((todayAPC * 100) / lastWeekAPC) -100;// _.meanBy(stores, "apcSeq");
    const txSeq = calculateExpression(todayTx, lastWeekTx);// ((todayTx * 100) / lastWeekTx) -100;// _.meanBy(stores, "txSeq");
    const currentADS = _.meanBy(stores, "todaySales");
    const sdlwADS = _.meanBy(stores, "lastWeekSales");
    // Return object with required format
    return {
      state,
      storeCount,
      todaySales,
      lastWeekSales,
      todayAPC,
      lastWeekAPC,
      todayTx,
      lastWeekTx,
      salesSeq,
      apcSeq,
      txSeq,
      currentADS,
      sdlwADS
    };
  });

  return result.sort((a, b) => a.storeCount - b.storeCount).reverse();
};

export const getDailyBusinessSummaryAPI = (payload) => {
  const dateObj = new Date(payload.date);
  const dateStr = `${dateObj.getFullYear()}-${dateObj.getMonth() + 1
    }-${dateObj.getDate()}`;
  const result = axiosInstance
    .get(`${DAILY_BUSINESS_SUMMARY_API_ENDPOINT}?currentDate=${dateStr}`)
    .then(({ data }) => {
      return getProcessedData(data);
    });
  return result;
};

export const calculateExpression = (firstValue, secondValue) => {
  if (firstValue === 0 || secondValue === 0)
    return 0;
  return ((firstValue * 100) / secondValue) - 100;
}

export const calculateAPC = (firstValue, secondValue) => {
  if (firstValue === 0 || secondValue === 0)
    return 0;
  return (firstValue / secondValue);
}