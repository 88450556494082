import React, { useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import {
  getAdminForecastSelector,
  getAdminForecastTableTotalRowSelector,
  isLoadingAdminForecastSelector
} from "../../redux/selectors/admin.forecast";

import { getAdminForecastAction } from "../../redux/actions/admin.forecast";

import "./AdminForecast.scss";
import CollapsableRowParentForAdminForecast from "../../components/CollapsableRowParentForAdminForecast/CollapsableRowParentForAdminForecast";
import AdminForecastTableLoader from "../../components/SkeletonLoader/AdminForecastTableLoader";
import { noop } from "lodash";

const adminForecastTable = ({
  selectedDate,
  getAdminForecast,
  totalRow,
  collapseData,
  adminForecastSelectedStore,
  isLoadingAdminForecast
}) => {
  useEffect(() => {
    if (getAdminForecast && selectedDate && adminForecastSelectedStore) {
      getAdminForecast({
        date: selectedDate,
        storeObj: adminForecastSelectedStore
      });
    }
  }, [selectedDate, adminForecastSelectedStore]);

  return (
    <div className="px-5">
      <table className="table table-bordered" style={{ fontSize: "14px" }}>
        <thead>
          <tr style={{ background: "#350822", color: "#fff" }}>
            <th style={{ width: "25%" }}>TIME</th>
            <th style={{ width: "25%" }}>LAST WEEK</th>
            <th style={{ width: "25%" }}>SYSTEM FORECAST</th>
            <th className="d-flex justify-content-between">
              <div>ACTUAL FORECAST </div>
            </th>
          </tr>
        </thead>
        {isLoadingAdminForecast ? (
          <AdminForecastTableLoader />
        ) : (
          <tbody>
            <tr style={{ background: "#000000", color: "#FFFFFF" }}>
              <td>{totalRow.parentLabel}</td>
              <td style={{ textAlign: "right" }}>
                Rs. {totalRow.lastYearSale}
              </td>
              <td style={{ textAlign: "right" }}>
                Rs. {totalRow.forcastedSale}
              </td>
              <td style={{ textAlign: "right" }}>Rs. {totalRow.adminEdit}</td>
            </tr>
            {collapseData.map((data, index) => (
              <CollapsableRowParentForAdminForecast key={index} data={data} />
            ))}
          </tbody>
        )}
      </table>
    </div>
  );
};

adminForecastTable.defaultProps = {
  getAdminForecast: noop,
  selectedDate: new Date(),
  collapseData: [],
  totalRow: {},
  adminForecastSelectedStore: {},
  isLoadingAdminForecast: false
};

adminForecastTable.propTypes = {
  getAdminForecast: PropTypes.func,
  selectedDate: PropTypes.object.isRequired,
  collapseData: PropTypes.array,
  totalRow: PropTypes.object,
  adminForecastSelectedStore: PropTypes.object,
  isLoadingAdminForecast: PropTypes.bool
};

const mapStateToProps = (state) => ({
  collapseData: getAdminForecastSelector(state),
  totalRow: getAdminForecastTableTotalRowSelector(state),
  isLoadingAdminForecast: isLoadingAdminForecastSelector(state)
});

export default connect(mapStateToProps, {
  getAdminForecast: getAdminForecastAction
})(adminForecastTable);
