import {
  GET_ADMIN_STORE_SUMMARY_REQUEST,
} from "../types/admin.storeSummary";

export const getAdminStoreSummaryAction = (payload) => {
  return {
    type: GET_ADMIN_STORE_SUMMARY_REQUEST,
    payload
  };
};
