import axiosInstance from "../configs/axios";
import { getNumberInTwoDigit } from "../utils/helpers";

// {{baseUrl}}/api/sales/GetStoreSummaryReport?currentDate=2022-12-11T22:45:58

const STORE_SUMMARY_API_ENDPOINT = "/api/sales/GetStoreSummaryReport";

export function StoreSummary(item) {
  this.itemId = item.itemId;
  this.productionUnit = item.productionUnit;
  this.forcast = item.forcast;
  this.cooked = item.cooked;
  this.wasted = item.wasted;
  this.perWasted = item.perWasted;
  this.sold = item.sold;
  this.underOverProduce = item.under_Over_Produce;
  this.perUnderOverProduce = item.perUnder_Over_Produce;
  this.perSold = item.perSold;
  this.soldFresh = item.soldFresh;
  this.perSoldFresh = item.perSoldFresh;
  this.perProduced = item.perProduced;
  this.itemName = item.itemName;
  this.adminForcast = item.adminForcast;
}

export const getStoreSummaryAPI = (Date) => {
  const year = Date.getFullYear();
  const month = getNumberInTwoDigit(Date.getMonth() + 1);
  const date = getNumberInTwoDigit(Date.getDate());
  const hours = getNumberInTwoDigit(Date.getHours());
  const minutes = getNumberInTwoDigit(Date.getMinutes());
  const seconds = getNumberInTwoDigit(Date.getSeconds());
  return axiosInstance
    .get(
      `${STORE_SUMMARY_API_ENDPOINT}?currentDate=${year}-${month}-${date}T${hours}:${minutes}:${seconds}Z`
    )
    .then(({ data }) => data.map((item) => new StoreSummary(item)));
};
