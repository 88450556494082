import axiosInstance from "../configs/axios";

const GET_ADMIN_STORES_ENDPOINT = "/api/admin/store/connection";
const UPDATE_ADMIN_STORE_ENDPOINT = "/api/admin/store/connection";
const DELETE_ADMIN_STORE_ENDPOINT = "/api/admin/store/connection?storeCode=";

function AdminStore(item) {
  this.storeCode = item.storeCode;
  this.serverName = item.serverName;
  this.address = item.address;
  this.serverIP = item.serverIP;
  this.dbName = item.dbName;
  this.userId = item.userId;
  this.password = item.password;
}

export const getAdminStoresApi = () => {
  return axiosInstance
    .get(GET_ADMIN_STORES_ENDPOINT)
    .then(({ data }) => data.map((store) => new AdminStore(store)));
};

export const updateAdminStoreApi = (payload) => {
  const { storeCode, address, serverIP, dbName, userId, password, serverName } =
    payload;

  return axiosInstance
    .post(UPDATE_ADMIN_STORE_ENDPOINT, {
      storeCode,
      address,
      serverIP,
      dbName,
      userId,
      password,
      serverName
    })
    .then(({ data }) => data);
};

export const deleteAdminStoreApi = (payload) => {
  const { storeCode } = payload;

  return axiosInstance
    .delete(`${DELETE_ADMIN_STORE_ENDPOINT}${storeCode}`)
    .then(({ data }) => data);
};
