import { takeLatest, call, put } from "redux-saga/effects";
import { toast } from "react-toastify";
import {
  GET_ADMIN_FORECAST_FAILURE,
  GET_ADMIN_FORECAST_REQUEST,
  GET_ADMIN_FORECAST_SUCCESS
} from "../types/admin.forecast";
import { getAdminForecastApi } from "../../api/admin.forecast";

function* watchAdminForecastSaga() {
  yield takeLatest(
    GET_ADMIN_FORECAST_REQUEST,
    function* getAdminForecast({ payload }) {
      try {
        const data = yield call(getAdminForecastApi, payload);
        if (data) {
          yield put({ type: GET_ADMIN_FORECAST_SUCCESS, payload: data });
        }
      } catch (e) {
        yield put({ type: GET_ADMIN_FORECAST_FAILURE });
        toast.error("Something went wrong while fetching graph data");
      }
    }
  );
}

export default function* adminForecastSaga() {
  yield call(watchAdminForecastSaga);
}
