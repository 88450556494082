import { takeLatest, call, put, select } from "redux-saga/effects";
import {
  getGraphDataAPI,
  getHistoricalDataAPI,
  getSalesTableDataAPI,
  reset15MinSalesDataAPI,
  update15MinSalesData,
  updateCurrentSalesAPI,
  updateDayPeriodSalesAPI,
  updateHourlySalesAPI
} from "../../api/forecast";
import {
  GET_GRAPH_DATA_FAILURE,
  GET_GRAPH_DATA_REQUEST,
  GET_GRAPH_DATA_SUCCESS,
  GET_HISTORICAL_DATA_FAILURE,
  GET_HISTORICAL_DATA_REQUEST,
  GET_HISTORICAL_DATA_SUCCESS,
  GET_SALES_TABLE_DATA_FAILURE,
  GET_SALES_TABLE_DATA_REQUEST,
  GET_SALES_TABLE_DATA_SUCCESS,
  RESET_15_MIN_SALES_DATA_REQUEST,
  UPDATE_15_MIN_SALES_REQUEST,
  UPDATE_CURRENT_SALE_REQUEST,
  UPDATE_DAY_PERIOD_SALES_REQUEST,
  UPDATE_HOURLY_SALES_REQUEST
} from "../types/forecast";

import { getForecastDateSelector } from "../selectors/forecast";
import { toast } from "react-toastify";
import { getNumberInTwoDigit } from "../../utils/helpers";

function* watchForecastSaga() {
  yield takeLatest(GET_GRAPH_DATA_REQUEST, function* getGraphData({ payload }) {
    try {
      const data = yield call(getGraphDataAPI, payload);
      if (data) {
        yield put({ type: GET_GRAPH_DATA_SUCCESS, payload: data });
      }
    } catch (e) {
      yield put({ type: GET_GRAPH_DATA_FAILURE });
      toast.error("Somthing went wrong while fetching graph data");
    }
  });

  yield takeLatest(GET_SALES_TABLE_DATA_REQUEST, function* getSalesTableData() {
    try {
      const forecastDate = yield select(getForecastDateSelector);
      const data = yield call(getSalesTableDataAPI, forecastDate);
      if (data) {
        yield put({ type: GET_SALES_TABLE_DATA_SUCCESS, payload: data });
      }
    } catch (e) {
      yield put({ type: GET_SALES_TABLE_DATA_FAILURE });
      toast.error("Somthing went wrong while fetching graph table data");
    }
  });

  yield takeLatest(
    GET_HISTORICAL_DATA_REQUEST,
    function* getHistoricalData(payload) {
      try {
        const data = yield call(getHistoricalDataAPI, payload.payload);
        if (data) {
          yield put({ type: GET_HISTORICAL_DATA_SUCCESS, payload: data });
        }
      } catch (e) {
        yield put({ type: GET_HISTORICAL_DATA_FAILURE });
        toast.error("Somthing went wrong while fetching historical data");
      }
    }
  );

  yield takeLatest(
    UPDATE_CURRENT_SALE_REQUEST,
    function* updateCurrentSale(request) {
      try {
        const { response, error } = yield call(
          updateCurrentSalesAPI,
          request.payload
        );
        if (error !== undefined) {
          if (error.response.status !== 200) {
            toast.error(error.response.status + ":" + error.response.data);
          }
        }
        if (response !== undefined) {
          if (response.status === 200) {
            toast.success(response.data.value);
          }
        }
        const forecastDate = yield select(getForecastDateSelector);
        const data = yield call(getSalesTableDataAPI, forecastDate);
        if (data) {
          yield put({ type: GET_SALES_TABLE_DATA_SUCCESS, payload: data });
        }

        yield put({ type: GET_GRAPH_DATA_REQUEST, payload: forecastDate });
      } catch (e) {
        toast.error("Somthing went wrong while updating sales data");
      }
    }
  );

  yield takeLatest(
    UPDATE_15_MIN_SALES_REQUEST,
    function* udpate15MinSale(request) {
      try {
        const data = yield call(update15MinSalesData, request.payload);
        if (data) {
          const forecastDate = yield select(getForecastDateSelector);
          yield put({ type: GET_GRAPH_DATA_REQUEST, payload: forecastDate });
        }
      } catch (e) {
        toast.error("Somthing went wrong while updating sales data");
      }
    }
  );

  yield takeLatest(
    UPDATE_DAY_PERIOD_SALES_REQUEST,
    function* updateDaySales(request) {
      try {
        const selectedDate = yield select(getForecastDateSelector);
        const year = selectedDate.getFullYear();
        const month = getNumberInTwoDigit(selectedDate.getMonth() + 1);
        const date = getNumberInTwoDigit(selectedDate.getDate());
        const hours = getNumberInTwoDigit(selectedDate.getHours());
        const minutes = getNumberInTwoDigit(selectedDate.getMinutes());
        const seconds = getNumberInTwoDigit(selectedDate.getSeconds());

        const { response, error } = yield call(updateDayPeriodSalesAPI, {
          ...request.payload,
          date: `${year}-${month}-${date}T${hours}:${minutes}:${seconds}Z`
        });
        if (error !== undefined) {
          if (error.response.status !== 200) {
            toast.error(
              error.response.status + ":" + error.response.data.value
            );
          }
        }
        if (response !== undefined) {
          const forecastDate = yield select(getForecastDateSelector);
          yield put({ type: GET_GRAPH_DATA_REQUEST, payload: forecastDate });
        }
      } catch (e) {
        toast.error("Somthing went wrong while updating day sales data");
      }
    }
  );

  yield takeLatest(
    UPDATE_HOURLY_SALES_REQUEST,
    function* updateHourlySales(request) {
      try {
        const { response, error } = yield call(
          updateHourlySalesAPI,
          request.payload
        );
        if (error !== undefined) {
          if (error.response.status !== 200) {
            toast.error(
              error.response.status + ":" + error.response.data.value
            );
          }
        }
        if (response !== undefined) {
          const forecastDate = yield select(getForecastDateSelector);
          yield put({ type: GET_GRAPH_DATA_REQUEST, payload: forecastDate });
        }
      } catch (e) {
        toast.error("Somthing went wrong while updating day sales data");
      }
    }
  );

  yield takeLatest(
    RESET_15_MIN_SALES_DATA_REQUEST,
    function* reset15MinSales(request) {
      if (request) {
        try {
          const { response, error } = yield call(
            reset15MinSalesDataAPI,
            request.payload
          );
          if (error !== undefined) {
            if (error.response.status !== 200) {
              toast.error(
                error.response.status + ":" + error.response.data.value
              );
            }
          }
          if (response !== undefined) {
            if (response.status === 200) {
              const forecastDate = yield select(getForecastDateSelector);
              yield put({
                type: GET_GRAPH_DATA_REQUEST,
                payload: forecastDate
              });
            }
          }
        } catch (e) {
          toast.error("Somthing went wrong while resetting data");
        }
      }
    }
  );
}

export default function* forecastSaga() {
  yield call(watchForecastSaga);
}
