import { createSelector } from "reselect";

const adminItemsState = (state) => state.adminItems;

export const adminItemsSelector = createSelector(
  adminItemsState,
  (state) => state.adminItems
);

export const isAdminItemsLoadingSelector = createSelector(
  adminItemsState,
  (state) => state.loadingAdminItems
);
