import { useState, useEffect } from "react";

const useApiTablePagination = (
  initialStartIndex = 0,
  itemsPerPage = 10,
  totalCount = 0,
  onPageChange
) => {
  const [startIndex, setStartIndex] = useState(initialStartIndex);

  const fetchData = () => {
    onPageChange(startIndex, itemsPerPage);
  };

  useEffect(() => {
    fetchData();
  }, [startIndex, itemsPerPage]);

  const totalPages = Math.ceil(totalCount / itemsPerPage);

  const goToPage = (page) => {
    // Calculate the new startIndex based on the page number
    const newStartIndex = (page - 1) * itemsPerPage;
    setStartIndex(newStartIndex);
  };

  const nextPage = () => {
    // Calculate the next page's startIndex and make sure it doesn't exceed the totalCount
    const newStartIndex = Math.min(startIndex + itemsPerPage, totalCount - 1);
    setStartIndex(newStartIndex);
  };

  const prevPage = () => {
    // Calculate the previous page's startIndex and make sure it doesn't go below 0
    const newStartIndex = Math.max(startIndex - itemsPerPage, 0);
    setStartIndex(newStartIndex);
  };

  const isFirstPage = startIndex === 0;
  const isLastPage = startIndex + itemsPerPage >= totalCount;

  return {
    startIndex,
    totalPages,
    goToPage,
    nextPage,
    prevPage,
    isFirstPage,
    isLastPage,
    currentPage: Math.ceil(startIndex / itemsPerPage) + 1
  };
};

export default useApiTablePagination;
