import {
  DELETE_ADMIN_STORE_USER_REQUEST,
  GET_ADMIN_STORE_USERS_REQUEST,
  ADD_ADMIN_STORE_USER_REQUEST
} from "../types/admin.storeUsers";

export const getAdminStoreUsersAction = () => {
  return {
    type: GET_ADMIN_STORE_USERS_REQUEST
  };
};

export const AddAdminStoreUserAction = (payload) => {
  return {
    type: ADD_ADMIN_STORE_USER_REQUEST,
    payload
  };
};

export const deleteAdminStoreUserAction = (payload) => {
  return {
    type: DELETE_ADMIN_STORE_USER_REQUEST,
    payload
  };
};
