import React from "react";
import PropTypes from "prop-types";
import { noop } from "lodash";
import DatePicker from "react-datepicker";
import { BsCaretLeftFill, BsCaretRightFill } from "react-icons/bs";
import Select from "react-select";

import "react-datepicker/dist/react-datepicker.css";
import "./AdminJobStatusHeader.scss";

const AdminJobStatusHeader = ({
  selectedDate,
  setSelectedDate,
  selectedSortBy,
  setSelectedSortBy,
  setSearch,
  search
}) => {
  // Create store data for dropdown
  const storesOptions = ["Success"].map((store) => {
    return { value: store, label: store };
  });

  Date.prototype.addDays = function add(days) {
    const date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
  };

  const goToPrevDate = () => {
    setSelectedDate(new Date(selectedDate.setDate(selectedDate.getDate() - 1)));
  };

  const goToNextDate = () => {
    const D1 = selectedDate.getDate();
    const D2 = new Date().getDate();
    if (D1 === D2) {
      return;
    }
    setSelectedDate(new Date(selectedDate.setDate(selectedDate.getDate() + 1)));
  };

  return (
    <div className="job-status-header">
      <div className="d-flex col-8">
        <div className="job-status-header__search-bar">
          <input
            className="form-control"
            type="text"
            placeholder="Search by store code"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
        <span className="d-flex align-items-center">
          <span
            className="job-status-header__date-picker-arrows"
            onClick={goToPrevDate}
          >
            <BsCaretLeftFill />
          </span>
          <DatePicker
            className="form-control job-status-header__date-picker"
            selected={selectedDate}
            onChange={(update) => {
              setSelectedDate(update);
            }}
            onChangeRaw={(e) => e.preventDefault()}
            maxDate={new Date()}
            dateFormat="dd/MM/yyyy"
          />
          <span
            className="job-status-header__date-picker-arrows"
            onClick={goToNextDate}
          >
            <BsCaretRightFill />
          </span>
        </span>
      </div>

      <div>
        <Select
          value={selectedSortBy}
          options={storesOptions}
          className="job-status-header__dropdown"
          onChange={(value) => setSelectedSortBy(value)}
          placeholder="Sort by"
          isClearable
        />
      </div>
    </div>
  );
};

AdminJobStatusHeader.defaultProps = {
  selectedDate: new Date(),
  setSelectedDate: noop
};

AdminJobStatusHeader.propTypes = {
  selectedDate: PropTypes.object,
  setSelectedDate: PropTypes.func,
  selectedSortBy: PropTypes.string,
  setSelectedSortBy: PropTypes.func
};

export default AdminJobStatusHeader;
