import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { noop } from "lodash";
import { addAdminManagerAccountAction } from "../../redux/actions/auth";
import { isManagerAccountCreatedSelector } from "../../redux/selectors/auth";

const AdminAddManagerAccountForm = ({
  createManagerAccount,
  isManagerAccountCreated
}) => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    storeCodes: ""
  });

  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (isManagerAccountCreated) {
      setFormData({
        name: "",
        email: "",
        password: "",
        storeCodes: ""
      });
    }
  }, [isManagerAccountCreated]);

  const validateForm = () => {
    const newErrors = {};
    if (!formData.name) {
      newErrors.name = "Name is required";
    }
    if (!formData.email) {
      newErrors.email = "Email is required";
    } else if (!/^\S+@\S+\.\S+$/.test(formData.email)) {
      newErrors.email = "Invalid email address";
    }
    if (!formData.password) {
      newErrors.password = "Password is required";
    }
    if (!formData.storeCodes) {
      newErrors.storeCodes = "Store Codes are required";
    }
    return newErrors;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
    setErrors({ ...errors, [name]: "" });
  };

  const handleSubmit = () => {
    const newErrors = validateForm();
    if (Object.keys(newErrors).length === 0) {
      createManagerAccount(formData);
    } else {
      setErrors(newErrors);
    }
  };

  return (
    <div className="row mt-4">
      <div className="mb-3">
        <label htmlFor="name" className="form-label">
          Name<span className="text-danger">*</span>
        </label>
        <input
          type="text"
          className={`form-control ${errors.name ? "is-invalid" : ""}`}
          id="name"
          name="name"
          value={formData.name}
          onChange={handleChange}
          placeholder="Enter your name"
        />
        {errors.name && <div className="invalid-feedback">{errors.name}</div>}
      </div>
      <div className="mb-3">
        <label htmlFor="email" className="form-label">
          Email<span className="text-danger">*</span>
        </label>
        <input
          type="text"
          className={`form-control ${errors.email ? "is-invalid" : ""}`}
          id="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          placeholder="Enter your email"
        />
        {errors.email && <div className="invalid-feedback">{errors.email}</div>}
      </div>
      <div className="mb-3">
        <label htmlFor="password" className="form-label">
          Password<span className="text-danger">*</span>
        </label>
        <input
          type="text"
          className={`form-control ${errors.password ? "is-invalid" : ""}`}
          id="password"
          name="password"
          value={formData.password}
          onChange={handleChange}
          placeholder="Enter your password"
        />
        {errors.password && (
          <div className="invalid-feedback">{errors.password}</div>
        )}
      </div>
      <div className="mb-3">
        <label htmlFor="storeCodes" className="form-label">
          Store Codes<span className="text-danger">*</span>
        </label>
        <textarea
          className={`form-control ${errors.storeCodes ? "is-invalid" : ""}`}
          id="storeCodes"
          name="storeCodes"
          value={formData.storeCodes}
          onChange={handleChange}
          placeholder="Enter store codes (comma separated) e.g., 316,361,396,K097,K191"
          rows="4" // Adjust the number of rows as needed
        />
        {errors.storeCodes && (
          <div className="invalid-feedback">{errors.storeCodes}</div>
        )}
      </div>
      <div className="d-flex justify-content-center mt-3">
        <button onClick={handleSubmit} className="btn btn-dark">
          Create Manager Account
        </button>
      </div>
    </div>
  );
};

AdminAddManagerAccountForm.defaultProps = {
  isManagerAccountCreated: PropTypes.bool,
  createManagerAccount: noop
};

AdminAddManagerAccountForm.propTypes = {
  isManagerAccountCreated: PropTypes.bool,
  createManagerAccount: PropTypes.func
};

const mapStateToProps = (state) => ({
  isManagerAccountCreated: isManagerAccountCreatedSelector(state)
});

const mapDispatchToProps = {
  createManagerAccount: addAdminManagerAccountAction
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminAddManagerAccountForm);
