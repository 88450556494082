import {
  GET_ADMIN_USER_LIST_FAILURE,
  GET_ADMIN_USER_LIST_REQUEST,
  GET_ADMIN_USER_LIST_SUCCESS
} from "../types/admin.userList";

const initialState = {
  loadingAdminUserList: false,
  users: [],
  totalUsers: 0
};

export const adminUserListReducer = (
  state = initialState,
  { type, payload }
) => {
  switch (type) {
    case GET_ADMIN_USER_LIST_REQUEST:
      return {
        ...state,
        loadingAdminUserList: true,
        users: []
      };
    case GET_ADMIN_USER_LIST_SUCCESS:
      return {
        ...state,
        loadingAdminUserList: false,
        users: payload.users,
        totalUsers: payload.totalUsers
      };
    case GET_ADMIN_USER_LIST_FAILURE:
      return {
        ...state,
        loadingAdminUserList: false,
        users: payload.users,
        totalUsers: payload.totalUsers
      };
    default:
      return state;
  }
};
