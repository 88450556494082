import axiosInstance from "../configs/axios";
import { getDate } from "../utils/helpers";

const GET_CREATE_BATCH_JOB_STATUS = "/api/admin/sales/CreateBatch";
const GET_DATA_SYNC_JOB_STATUS = "/api/admin/sales/DataSync";
const GET_SALES_15_MIN_JOB_STATUS = "/api/admin/sales/Sales15Min";
const GET_SALES_HISTORY_JOB_STATUS = "/api/admin/sales/SalesHistory";
const UPDATE_CREATE_BATCH_JOB_STATUS = "/api/admin/sales/ExecuteCreateBatchJob";
const UPDATE_DATA_SYNC_JOB_STATUS = "/api/admin/sales/ExecuteSyncStoreDataJob";
const UPDATE_SALES_15_MIN_JOB_STATUS = "/api/admin/sales/ExecuteSales15MinJob";
const UPDATE_SALES_HISTORY_JOB_STATUS =
  "/api/admin/sales/ExecuteSalesHistoryBatchJob";

function JobStatus(job) {
  this.storeCode = job.storeCode;
  this.success = job.success;
  this.createdDate = job.createdDate;
  this.error = job.error;
}

export const getCreateBatchJobStatusApi = (payload) => {
  const { date } = payload;
  return axiosInstance
    .get(
      `${GET_CREATE_BATCH_JOB_STATUS}?currentDate=${getDate(
        date,
        "YYYY-MM-DD"
      )}`
    )
    .then(({ data }) => data?.map((store) => new JobStatus(store)));
};
export const getDataSyncJobStatusApi = (payload) => {
  const { date } = payload;
  return axiosInstance
    .get(
      `${GET_DATA_SYNC_JOB_STATUS}?currentDate=${getDate(date, "YYYY-MM-DD")}`
    )
    .then(({ data }) => data?.map((store) => new JobStatus(store)));
};
export const getSalesIn15MinJobStatusApi = (payload) => {
  const { date } = payload;
  return axiosInstance
    .get(
      `${GET_SALES_15_MIN_JOB_STATUS}?currentDate=${getDate(
        date,
        "YYYY-MM-DD"
      )}`
    )
    .then(({ data }) => data?.map((store) => new JobStatus(store)));
};
export const getSalesHistoryJobStatusApi = (payload) => {
  const { date } = payload;
  return axiosInstance
    .get(
      `${GET_SALES_HISTORY_JOB_STATUS}?currentDate=${getDate(
        date,
        "YYYY-MM-DD"
      )}`
    )
    .then(({ data }) => data?.map((store) => new JobStatus(store)));
};
export const updateCreateBatchJobStatusApi = (payload) => {
  const { storeCode } = payload;
  return axiosInstance
    .post(UPDATE_CREATE_BATCH_JOB_STATUS, { storeCode })
    .then(({ data }) => data);
};
export const updateDataSyncJobStatusApi = (payload) => {
  const { storeCode } = payload;
  return axiosInstance
    .post(UPDATE_DATA_SYNC_JOB_STATUS, { storeCode })
    .then(({ data }) => data);
};
export const updateSalesIn15MinJobStatusApi = (payload) => {
  const { storeCode } = payload;
  return axiosInstance
    .post(UPDATE_SALES_15_MIN_JOB_STATUS, { storeCode })
    .then(({ data }) => data);
};
export const updateSalesHistoryJobStatusApi = (payload) => {
  const { storeCode } = payload;
  return axiosInstance
    .post(UPDATE_SALES_HISTORY_JOB_STATUS, { storeCode })
    .then(({ data }) => data);
};
