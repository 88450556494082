import React from "react";
import PropTypes from "prop-types";

import Navbar from "../../components/Navbar";
import Sidebar from "react-sidebar";
import SidebarContent from "../../components/SidebarContent/SidebarContent";
import { connect } from "react-redux";
import { isSidebarOpenSelector } from "../../redux/selectors/dashboard";
import { toggleSidebarAction } from "../../redux/actions/dashboard";

import "./AdminAddStoreAccount.scss";
import AdminAddStoreAccountForm from "../../containers/AdminAddStoreAccountForm/AdminAddStoreAccountForm";
import { SIDEBAR_STYLING } from "../../configs/constants/constants";

const AdminDashboard = ({ isSidebarOpen, toggleSidebarAction }) => {
  return (
    <div className="admin-dashboard">
      <Navbar toggleSidebarAction={toggleSidebarAction} />
      {isSidebarOpen && (
        <Sidebar
          sidebar={<SidebarContent />}
          open={isSidebarOpen}
          pullRight
          styles={{ sidebar: SIDEBAR_STYLING }}
          shadow={false}
        />
      )}
      <main>
        <div className="container">
          <h1 className="mt-3 mb-3 text-center">Create Store Account</h1>
          <div className="col-8 m-auto">
            <AdminAddStoreAccountForm />
          </div>
        </div>
      </main>
    </div>
  );
};

AdminDashboard.defaultProps = {
  isSidebarOpen: false,
  toggleSidebarAction: () => {}
};

AdminDashboard.propTypes = {
  isSidebarOpen: PropTypes.bool,
  toggleSidebarAction: PropTypes.func
};

const mapStateToProps = (state) => ({
  isSidebarOpen: isSidebarOpenSelector(state)
});

const mapDispatchToProps = {
  toggleSidebarAction: toggleSidebarAction
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminDashboard);
