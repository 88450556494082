import { createSelector } from "reselect";

const adminDashboardState = (state) => state.adminDashboard;

export const isFetchingActiveInactiveStoresSelector = createSelector(
  adminDashboardState,
  (MDS) => MDS.loadingActiveInactive
);

export const activeInactiveStoresSelector = createSelector(
  adminDashboardState,
  (MDS) => MDS.activeInactiveStores
);

export const isFetchingStoreSalesHistorySelector = createSelector(
  adminDashboardState,
  (MDS) => MDS.loadingStoreSalesHistory
);

export const getStoreSalesHistorySelector = createSelector(
  adminDashboardState,
  (MDS) => MDS.storeSalesHistory
);

export const isFetchingStoresLastTxSelector = createSelector(
  adminDashboardState,
  (MDS) => MDS.loadingStoresLastTx
);

export const getStoreLastTxSelector = createSelector(
  adminDashboardState,
  (MDS) => MDS.storesLastTx
);

export const isFetchingStoreCurrentSalesSelector = createSelector(
  adminDashboardState,
  (MDS) => MDS.loadingStoresCurrentSales
);

export const getStoresCurrentSalesSelector = createSelector(
  adminDashboardState,
  (MDS) => MDS.storesCurrentSales
);

export const getAvailableStoreCodesSelector = createSelector(
  adminDashboardState,
  (MDS) => MDS.availableStoreCodes
);

export const getTotalStoreSalesSelector = createSelector(
  adminDashboardState,
  (MDS) => MDS.totalStoreSales
);

export const isFetchingTotalStoreSalesSelector = createSelector(
  adminDashboardState,
  (MDS) => MDS.loadingTotalStoreSales
);

export const isFetchingActiveInactiveSelector = createSelector(
  adminDashboardState,
  (MDS) => MDS.loadingActiveInactive
);
