import axiosInstance from "../configs/axios";

const GET_ADMIN_STORE_USERS_ENDPOINT = "/api/admin/auth/managerstore";
const UPDATE_ADMIN_STORE_USER_ENDPOINT = "/api/admin/auth/managerstore";
const DELETE_ADMIN_STORE_USER_ENDPOINT = "/api/admin/auth/managerstore";

function AdminStore(user) {
  this.storeCode = user.storeCode;
  this.email = user.email;
}

export const getAdminStoreUsersApi = () => {
  return axiosInstance
    .get(GET_ADMIN_STORE_USERS_ENDPOINT)
    .then(({ data }) => data.map((store) => new AdminStore(store)));
};

export const updateAdminStoreUserApi = (payload) => {
  const { storeCode, email } = payload;

  return axiosInstance
    .post(
      `${UPDATE_ADMIN_STORE_USER_ENDPOINT}?email=${email}&storeCode=${storeCode}`
    )
    .then(({ data }) => data);
};

export const deleteAdminStoreUserApi = (payload) => {
  const { storeCode, email } = payload;
  if (!storeCode || !email) {
    throw "Missing store code or email";
  }
  return axiosInstance
    .delete(
      `${DELETE_ADMIN_STORE_USER_ENDPOINT}?email=${email}&storeCode=${storeCode}`
    )
    .then(({ data }) => data);
};
