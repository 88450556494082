import { takeLatest, call, put } from "redux-saga/effects";
import { toast } from "react-toastify";
import {
  DELETE_ADMIN_STORE_REQUEST,
  GET_ADMIN_STORES_FAILURE,
  GET_ADMIN_STORES_REQUEST,
  GET_ADMIN_STORES_SUCCESS,
  UPDATE_ADMIN_STORE_REQUEST
} from "../types/admin.stores";
import {
  deleteAdminStoreApi,
  getAdminStoresApi,
  updateAdminStoreApi
} from "../../api/admin.stores";

function* watchAdminStoresSaga() {
  yield takeLatest(
    GET_ADMIN_STORES_REQUEST,
    function* getAdminForecast({ payload }) {
      try {
        const data = yield call(getAdminStoresApi, payload);
        if (data) {
          yield put({ type: GET_ADMIN_STORES_SUCCESS, payload: data });
        }
      } catch (e) {
        yield put({ type: GET_ADMIN_STORES_FAILURE });
        toast.error("Something went wrong while fetching graph data");
      }
    }
  );
  yield takeLatest(
    UPDATE_ADMIN_STORE_REQUEST,
    function* updateAdminStore({ payload }) {
      try {
        yield call(updateAdminStoreApi, payload.apiBody);
        try {
          const updatedStoreList = yield call(getAdminStoresApi, payload);
          toast.success(
            `Store has been ${
              payload.isNewStore ? "added" : "updated"
            } successfully`
          );
          if (updatedStoreList) {
            yield put({
              type: GET_ADMIN_STORES_SUCCESS,
              payload: updatedStoreList
            });
          }
        } catch (e) {
          yield put({ type: GET_ADMIN_STORES_FAILURE });
          toast.error("Something went wrong while fetching store data");
        }
      } catch (e) {
        toast.error("Something went wrong while updating store");
      }
    }
  );
  yield takeLatest(
    DELETE_ADMIN_STORE_REQUEST,
    function* deleteAdminItem({ payload }) {
      try {
        yield call(deleteAdminStoreApi, payload);
        toast.success("Store has been deleted successfully");
        try {
          const updatedStoreList = yield call(getAdminStoresApi, payload);
          if (updatedStoreList) {
            yield put({
              type: GET_ADMIN_STORES_SUCCESS,
              payload: updatedStoreList
            });
          }
        } catch (e) {
          yield put({ type: GET_ADMIN_STORES_FAILURE });
          toast.error("Something went wrong while fetching store data");
        }
      } catch (e) {
        toast.error("Something went wrong while deleting store");
      }
    }
  );
}

export default function* adminStoresSaga() {
  yield call(watchAdminStoresSaga);
}
