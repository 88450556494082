import React, { useState } from "react";
import Modal from "react-modal";
import { MdClose } from "react-icons/md";
import { connect } from "react-redux";
import PropTypes, { bool } from "prop-types";
import { noop } from "lodash";
import { updateAdminItemAction } from "../../../redux/actions/admin.items";
import NumberInput from "../../../components/NumberInput/NumberInput";
import "./AddAndEditItemModal.scss";

const EditItemModal = ({
  isEditItemModalOpen,
  handleModalClose,
  item,
  updateAdminItem,
  isAddingNewItem
}) => {
  const [description, setDescription] = useState(item.description ?? "");
  const [unit, setUnit] = useState(item.unit ?? "");
  const [imagePath, setImagePath] = useState(item.imagePath ?? "");
  const [itemId, setItemId] = useState(item.itemId ?? "");
  const [cookTime, setCookTime] = useState(item.cookTime ?? 0);
  const [batchSize, setBatchSize] = useState(item.batchSize ?? 0);
  const [stagingTime, setStagingTime] = useState(item.stagingTime ?? 0);
  const [onHoldTime, setOnHoldTime] = useState(item.onHoldTime ?? 0);
  const [expiryTime, setExpiryTime] = useState(item.expiryTime ?? 0);
  const [errorMessage, setErrorMessage] = useState("");

  const handleSubmit = () => {
    if (!itemId) {
      setErrorMessage("Item Id is required");
      return;
    }
    const updatedEntry = {
      ...item,
      itemId,
      imagePath,
      description,
      unit,
      batchSize,
      cookTime,
      stagingTime,
      onHoldTime,
      expiryTime
    };
    updateAdminItem({ apiBody: updatedEntry, isNewItem: isAddingNewItem });
    handleModalClose();
  };
  const customEditModalStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      padding: 0,
      width: "680px"
    }
  };

  return (
    <Modal
      isOpen={isEditItemModalOpen}
      style={customEditModalStyles}
      ariaHideApp={false}
      shouldCloseOnOverlayClick
      onRequestClose={handleModalClose}
    >
      <div className="container p-3 admin-edit-item-modal">
        <MdClose
          className="admin-edit-item-modal__close_icon"
          size="1.5em"
          color="black"
          onClick={handleModalClose}
        />
        <div className="row mt-3">
          <div className="mb-3">
            <label className="form-label">Image URL</label>
            <input
              className="form-control"
              type="text"
              value={imagePath}
              onChange={(e) => setImagePath(e.target.value)}
            />
          </div>
          <div className="col-md-6">
            <div className="mb-3">
              <label className="form-label">
                Item ID<span className="text-danger">*</span>
              </label>
              <input
                className="form-control"
                type="text"
                value={itemId}
                onChange={(e) => {
                  setItemId(e.target.value);
                }}
                readOnly={!isAddingNewItem}
              />
            </div>
            <div className="mb-3">
              <label className="form-label">Description</label>
              <input
                className="form-control"
                type="text"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </div>
            <div className="mb-3">
              <label className="form-label">Unit</label>
              <input
                className="form-control"
                type="text"
                value={unit}
                onChange={(e) => setUnit(e.target.value)}
              />
            </div>
            <div className="mb-3">
              <label className="form-label">On Hold Time</label>
              <NumberInput
                value={onHoldTime}
                onChange={(value) => setOnHoldTime(value)}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="mb-3">
              <label className="form-label">Batch Size</label>
              <NumberInput
                value={batchSize}
                onChange={(value) => setBatchSize(value)}
              />
            </div>
            <div className="mb-3">
              <label className="form-label">Cook Time</label>
              <NumberInput
                value={cookTime}
                onChange={(value) => setCookTime(value)}
              />
            </div>
            <div className="mb-3">
              <label className="form-label">Stage Time</label>
              <NumberInput
                value={stagingTime}
                onChange={(value) => setStagingTime(value)}
              />
            </div>
            <div className="mb-3">
              <label className="form-label">Expiry Time</label>
              <NumberInput
                value={expiryTime}
                onChange={(value) => setExpiryTime(value)}
              />
            </div>
          </div>
        </div>
        <p className="text-center text-danger admin-edit-item-modal__warning_text">
          {errorMessage}
        </p>
        <div className="row">
          <div className="col-md-12 text-center">
            <button className="btn btn-dark  mx-2" onClick={handleSubmit}>
              {isAddingNewItem ? "Add" : "Save"}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

EditItemModal.defaultProps = {
  updateAdminItem: noop,
  isEditItemModalOpen: bool,
  item: {},
  handleModalClose: noop,
  isAddingNewItem: bool
};

EditItemModal.propTypes = {
  updateAdminItem: PropTypes.func,
  isEditItemModalOpen: PropTypes.bool,
  item: PropTypes.object,
  handleModalClose: PropTypes.func,
  isAddingNewItem: PropTypes.bool
};

const mapDispatchToProps = {
  updateAdminItem: updateAdminItemAction
};

export default connect(null, mapDispatchToProps)(EditItemModal);
