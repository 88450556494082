import { createSelector } from "reselect";

const jobStatusStoreState = (state) => state.adminJobStatus;

export const createBatchJobStatusSelector = createSelector(
  jobStatusStoreState,
  (state) => {
    return state.createBatchJobStatus;
  }
);

export const loadingCreateBatchJobStatusSelector = createSelector(
  jobStatusStoreState,
  (state) => {
    return state.loadingCreateBatchJobStatus;
  }
);

export const dataSyncJobStatusSelector = createSelector(
  jobStatusStoreState,
  (state) => {
    return state.dataSyncJobStatus;
  }
);

export const loadingDataSyncJobStatusSelector = createSelector(
  jobStatusStoreState,
  (state) => {
    return state.loadingSataSyncJobStatus;
  }
);

export const salesIn15MinJobStatusSelector = createSelector(
  jobStatusStoreState,
  (state) => {
    return state.salesIn15MinJobStatus;
  }
);

export const loadingSalesIn15MinJobStatusSelector = createSelector(
  jobStatusStoreState,
  (state) => {
    return state.loadingSalesIn15MinJobStatus;
  }
);

export const salesHistoryJobStatusSelector = createSelector(
  jobStatusStoreState,
  (state) => {
    return state.salesHistoryJobStatus;
  }
);

export const loadingSalesHistoryJobStatusSelector = createSelector(
  jobStatusStoreState,
  (state) => {
    return state.loadingSalesHistoryJobStatus;
  }
);
