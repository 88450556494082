import React from "react";
import PropTypes from "prop-types";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const cellStyle = {
  border: "1px solid #ddd",
  padding: "10px",
  textAlign: "left"
};

const TableLoader = ({ noOfRow, noOfColumn }) => {
  return (
    <tbody>
      {[...Array(noOfRow).keys()].map((row) => (
        <tr key={row}>
          {[...Array(noOfColumn).keys()].map((column) => (
            <td style={cellStyle} key={column}>
              <Skeleton style={{ zIndex: "-1" }} />
            </td>
          ))}
        </tr>
      ))}
    </tbody>
  );
};

TableLoader.defaultProps = {
  noOfRow: 10,
  noOfColumn: 10
};

TableLoader.propTypes = {
  noOfRow: PropTypes.number,
  noOfColumn: PropTypes.number
};

export default TableLoader;
