import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { noop } from "lodash";
import {
  AiOutlinePoweroff,
  AiOutlineDashboard,
  AiOutlineShop,
  AiOutlineUser
} from "react-icons/ai";

import { BsClock } from "react-icons/bs";

import { GiChickenOven } from "react-icons/gi";
import { HiOutlineDocumentReport } from "react-icons/hi";

import DEVYANI_BRAND_LOGO from "../../assets/images/devyani_logo.svg";

import "./SidebarContent.scss";
import { connect } from "react-redux";
import { toggleSidebarAction } from "../../redux/actions/dashboard";
import Menu from "./Menu/Menu";
import { getUserRole } from "../../utils/helpers";

const SidebarContent = ({ toggleSidebarAction }) => {
  const router = useHistory();
  const [userRole, setUserRole] = useState("");

  useEffect(() => {
    setUserRole(getUserRole()?.toLowerCase());
  }, []);

  return (
    <div className="sidebar-content">
      <header>
        <img
          src={DEVYANI_BRAND_LOGO}
          className="sidebar-content__logo"
          height="48px"
        />
      </header>
      <main>
        {userRole !== "ceo" && (
          <>
            {" "}
            <div
              className="sidebar-content__item"
              onClick={() => {
                toggleSidebarAction();
                router.push("/");
              }}
            >
              <AiOutlineDashboard
                size={18}
                style={{ marginRight: "8px", marginTop: "-6px" }}
              />{" "}
              Dashboard
            </div>
            <div
              className="sidebar-content__item"
              onClick={() => {
                toggleSidebarAction();
                router.push("/admin-items");
              }}
            >
              <GiChickenOven
                size={18}
                style={{ marginRight: "8px", marginTop: "-6px" }}
              />{" "}
              Items
            </div>
            <div
              className="sidebar-content__item"
              onClick={() => {
                toggleSidebarAction();
                router.push("/admin-stores");
              }}
            >
              <AiOutlineShop
                size={18}
                style={{ marginRight: "8px", marginTop: "-6px" }}
              />{" "}
              Stores
            </div>
            <Menu
              icon={
                <AiOutlineUser
                  size={18}
                  style={{ marginRight: "8px", marginTop: "-6px" }}
                />
              }
              label={"User"}
              menuItems={[
                {
                  label: "Users List",
                  action: () => {
                    toggleSidebarAction();
                    router.push("/admin-list-users");
                  }
                },
                {
                  label: "User Mapping",
                  action: () => {
                    toggleSidebarAction();
                    router.push("/admin-store-users");
                  }
                },
                {
                  label: "Add Store",
                  action: () => {
                    toggleSidebarAction();
                    router.push("/add-store");
                  }
                },
                {
                  label: "Add Manager",
                  action: () => {
                    toggleSidebarAction();
                    router.push("/add-manager");
                  }
                }
              ]}
            />
            <Menu
              icon={
                <HiOutlineDocumentReport
                  size={18}
                  style={{ marginRight: "8px", marginTop: "-6px" }}
                />
              }
              label={"Report"}
              menuItems={[
                {
                  label: "Day Forecast",
                  action: () => {
                    toggleSidebarAction();
                    router.push("/admin-forecast-evaluator");
                  }
                },
                {
                  label: "Store Summary",
                  action: () => {
                    toggleSidebarAction();
                    router.push("/admin-store-summary");
                  }
                },
                {
                  label: "Forecast Accuracy",
                  action: () => {
                    toggleSidebarAction();
                    router.push("/admin-forecast-accuracy");
                  }
                },
                {
                  label: "Stores Current Sales",
                  action: () => {
                    toggleSidebarAction();
                    router.push("/admin-current-sales");
                  }
                }
              ]}
            />
            <Menu
              icon={
                <BsClock
                  size={18}
                  style={{ marginRight: "8px", marginTop: "-6px" }}
                />
              }
              label={"Job status"}
              menuItems={[
                {
                  label: "Create Batch Job status",
                  action: () => {
                    toggleSidebarAction();
                    router.push("/job-status/create-batch");
                  }
                },
                {
                  label: "Data Sync Job status",
                  action: () => {
                    toggleSidebarAction();
                    router.push("/job-status/data-sync");
                  }
                },
                {
                  label: "Sales 15 Min Job status",
                  action: () => {
                    toggleSidebarAction();
                    router.push("/job-status/sales-15-min");
                  }
                },
                {
                  label: "Sales History Job status",
                  action: () => {
                    toggleSidebarAction();
                    router.push("/job-status/sales-history");
                  }
                }
              ]}
            />
          </>
        )}

        <div
          className="sidebar-content__item"
          onClick={() => {
            toggleSidebarAction();
            localStorage.clear();
            router.push("/login");
          }}
        >
          <AiOutlinePoweroff
            size={20}
            style={{ marginRight: "8px", marginTop: "-2px" }}
          />{" "}
          Logout
        </div>
      </main>
      <footer>
        <button
          onClick={toggleSidebarAction}
          className="sidebar-content__footer-button"
        >
          CLOSE
        </button>
      </footer>
    </div>
  );
};

SidebarContent.defaultProps = {
  toggleSidebarAction: noop
};

SidebarContent.propTypes = {
  toggleSidebarAction: PropTypes.func
};

const mapDispatchToProps = {
  toggleSidebarAction: toggleSidebarAction
};

export default connect(null, mapDispatchToProps)(SidebarContent);
