import React, { useState } from "react";
import PropTypes, { bool } from "prop-types";
import { Button } from "react-bootstrap";
import { FiEdit } from "react-icons/fi";
import { RiDeleteBin7Line } from "react-icons/ri";

import useTablePagination from "../../customHooks/TablePagination";

import "./AdminStoresTable.scss";
import AddAndEditStoreModal from "./AddAndEditStoreModal/AddAndEditStoreModal";
import DeleteStoreModal from "./DeleteStoreModal/DeleteStoreModal";
import { connect } from "react-redux";
import { deleteAdminStoreAction } from "../../redux/actions/admin.stores";
import { noop } from "lodash";
import TableLoader from "../../components/SkeletonLoader/TableLoader";
// import EditItemModal from "./EditItemModal/EditItemModal";

const headerStyle = {
  backgroundColor: "#0A4091",
  color: "#fff",
  padding: "10px",
  textAlign: "left"
};

const cellStyleRight = {
  border: "1px solid #ddd",
  padding: "10px",
  textAlign: "right"
};

const cellStyleCenter = {
  border: "1px solid #ddd",
  padding: "10px",
  textAlign: "center"
};

const cellStyleLeft = {
  border: "1px solid #ddd",
  padding: "10px",
  textAlign: "left"
};

const AdminStoresTable = ({
  adminStores,
  deleteAdminStoreAction,
  isAdminStoresLoading,
  isAddingNewStore,
  setIsAddingNewStore
}) => {
  const [isEditStoreModalOpen, setIsEditStoreModalOpen] = useState(false);
  const [StoreSelectedForEdit, setStoreSelectedForEdit] = useState({});
  const [isDeleteStoreModalOpen, setIsDeleteStoreModalOpen] = useState(false);
  const [storeIdSelectedToDelete, setStoreIdSelectedToDelete] = useState("");

  const {
    currentPage,
    totalPages,
    getCurrentPageData,
    nextPage,
    prevPage,
    isFirstPage,
    isLastPage
  } = useTablePagination(adminStores, 10);

  const tableData = getCurrentPageData();
  const handleEditStoreModalClose = () => {
    setIsEditStoreModalOpen(false);
    setStoreSelectedForEdit({});
    setIsAddingNewStore(false);
  };

  const handleStoreEdit = (storeCode) => {
    const store = tableData.find((store) => store.storeCode === storeCode);
    setIsEditStoreModalOpen(true);
    setStoreSelectedForEdit(store);
  };

  const handleStoreDelete = () => {
    deleteAdminStoreAction({ storeCode: storeIdSelectedToDelete });
    setIsDeleteStoreModalOpen(false);
  };

  const handleDeleteStoreModalOpen = (storeCode) => {
    setIsDeleteStoreModalOpen(true);
    setStoreIdSelectedToDelete(storeCode);
  };

  const handleDeleteStoreModalClose = () => {
    setIsDeleteStoreModalOpen(false);
    setStoreIdSelectedToDelete("");
  };

  return (
    <>
      <table style={{ width: "100%", borderCollapse: "collapse" }}>
        <thead>
          <tr>
            <th style={headerStyle}>Store Code</th>
            <th style={headerStyle}>Address</th>
            <th style={headerStyle}>Server IP</th>
            <th style={headerStyle}>Server Name</th>
            <th style={headerStyle}>DB Name</th>
            <th style={headerStyle}>User Name</th>
            <th style={headerStyle}>Password</th>
            <th style={headerStyle}>Action</th>
          </tr>
        </thead>
        {isAdminStoresLoading ? (
          <TableLoader noOfColumn={8} noOfRow={10} />
        ) : (
          <tbody>
            {tableData.map(
              ({
                storeCode,
                address,
                serverIP,
                serverName,
                dbName,
                userId,
                password
              }) => (
                <tr key={storeCode}>
                  <td style={cellStyleCenter}>{storeCode}</td>
                  <td style={cellStyleLeft}>{address}</td>
                  <td style={cellStyleCenter}>{serverIP}</td>
                  <td style={cellStyleRight}>{serverName}</td>
                  <td style={cellStyleRight}>{dbName}</td>
                  <td style={cellStyleRight}>{userId}</td>
                  <td style={cellStyleRight}>{password}</td>
                  <td style={cellStyleCenter}>
                    <span className="admin-store-table__edit_icon">
                      <FiEdit
                        onClick={() => {
                          handleStoreEdit(storeCode);
                        }}
                      />
                    </span>
                    <span className="m-1 admin-store-table__delete_icon">
                      <RiDeleteBin7Line
                        onClick={() => {
                          handleDeleteStoreModalOpen(storeCode);
                        }}
                      />
                    </span>
                  </td>
                </tr>
              )
            )}
          </tbody>
        )}
      </table>
      {tableData && tableData.length ? (
        <div className="mt-3 d-flex justify-content-center align-items-center">
          <Button variant="dark" disabled={isFirstPage} onClick={prevPage}>
            Previous Page
          </Button>
          <span className="mx-2">
            {currentPage}/{totalPages}
          </span>
          <Button variant="dark" disabled={isLastPage} onClick={nextPage}>
            Next Page
          </Button>
        </div>
      ) : null}
      {(isEditStoreModalOpen || isAddingNewStore) && (
        <AddAndEditStoreModal
          isEditStoreModalOpen={isEditStoreModalOpen || isAddingNewStore}
          handleModalClose={handleEditStoreModalClose}
          store={StoreSelectedForEdit}
          isAddingNewStore={isAddingNewStore}
        />
      )}
      {isDeleteStoreModalOpen && (
        <DeleteStoreModal
          isOpen={isDeleteStoreModalOpen}
          onConfirm={handleStoreDelete}
          onRequestClose={handleDeleteStoreModalClose}
        />
      )}
    </>
  );
};

AdminStoresTable.defaultProps = {
  adminStores: [],
  deleteAdminStoreAction: noop,
  isAdminStoresLoading: bool,
  isAddingNewStore: bool,
  setIsAddingNewStore: noop
};

AdminStoresTable.propTypes = {
  adminStores: PropTypes.array,
  deleteAdminStoreAction: PropTypes.func,
  isAdminStoresLoading: PropTypes.bool,
  isAddingNewStore: PropTypes.bool,
  setIsAddingNewStore: PropTypes.func
};

const mapDispatchToProps = {
  deleteAdminStoreAction: deleteAdminStoreAction
};

export default connect(null, mapDispatchToProps)(AdminStoresTable);
