import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Sidebar from "react-sidebar";

import Navbar from "../../components/Navbar";
import SidebarContent from "../../components/SidebarContent/SidebarContent";
import { isSidebarOpenSelector } from "../../redux/selectors/dashboard";
import { toggleSidebarAction } from "../../redux/actions/dashboard";
import AdminAddManagerAccountForm from "../../containers/AdminAddManagerAccountForm/AdminAddManagerAccountForm";

import "./AdminAddManagerAccount.scss";
import { SIDEBAR_STYLING } from "../../configs/constants/constants";

const AdminAddManagerAccount = ({ isSidebarOpen, toggleSidebarAction }) => {
  return (
    <div className="admin-dashboard">
      <Navbar toggleSidebarAction={toggleSidebarAction} />
      {isSidebarOpen && (
        <Sidebar
          sidebar={<SidebarContent />}
          open={isSidebarOpen}
          pullRight
          styles={{ sidebar: SIDEBAR_STYLING }}
          shadow={false}
        />
      )}
      <main>
        <div className="container">
          <h1 className="mt-3 mb-3 text-center">Create Manager Account</h1>
          <div className="col-5 m-auto">
            <AdminAddManagerAccountForm />
          </div>
        </div>
      </main>
    </div>
  );
};

AdminAddManagerAccount.defaultProps = {
  isSidebarOpen: false,
  toggleSidebarAction: () => {}
};

AdminAddManagerAccount.propTypes = {
  isSidebarOpen: PropTypes.bool,
  toggleSidebarAction: PropTypes.func
};

const mapStateToProps = (state) => ({
  isSidebarOpen: isSidebarOpenSelector(state)
});

const mapDispatchToProps = {
  toggleSidebarAction: toggleSidebarAction
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminAddManagerAccount);
