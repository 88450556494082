import React, { useEffect, useState } from "react";
import Sidebar from "react-sidebar";
import PropTypes from "prop-types";
import DatePicker from "react-datepicker";

import { connect } from "react-redux";
import { noop } from "lodash";

import { BsCaretLeftFill, BsCaretRightFill } from "react-icons/bs";

import Navbar from "../../components/Navbar";
import SidebarContent from "../../components/SidebarContent/SidebarContent";
import { isSidebarOpenSelector } from "../../redux/selectors/dashboard";
import { toggleSidebarAction } from "../../redux/actions/dashboard";
// import PageLoader from "../../components/PageLoader/PageLoader";
import Select from "react-select";

import "./AdminStoreSummary.scss";
import { getAdminStoreSummaryAction } from "../../redux/actions/admin.storeSummary";
import {
  isAdminStoreSummaryLoadingSelector,
  adminStoreSummarySelector
} from "../../redux/selectors/admin.storeSummary";
import { exportToExcel } from "../../utils/helpers";
import AdminStoreSummaryTableLoader from "../../components/SkeletonLoader/AdminStoreSummaryTableLoader";
import { getAvailableStoreCodesAction } from "../../redux/actions/admin.dashboard";
import { getAvailableStoreCodesSelector } from "../../redux/selectors/dashboard.admin";
import { SIDEBAR_STYLING } from "../../configs/constants/constants";

const AdminStoreSummary = ({
  isSidebarOpen,
  toggleSidebarAction,
  getStoreSummary,
  isStoreSummaryLoading,
  storeSummary,
  getAvailableStoreCodes,
  availableStoreCodes
}) => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedStore, setSelectedStore] = useState("");
  useEffect(() => {
    getStoreSummary({ date: selectedDate, store: selectedStore });
    getAvailableStoreCodes();
  }, [selectedDate, selectedStore]);

  Date.prototype.addDays = function add(days) {
    const date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
  };

  const goToPrevDate = () => {
    setSelectedDate(new Date(selectedDate.setDate(selectedDate.getDate() - 1)));
  };

  const goToNextDate = () => {
    const D1 = selectedDate.getDate();
    const D2 = new Date().addDays(10).getDate();
    if (D1 === D2) {
      return;
    }
    setSelectedDate(new Date(selectedDate.setDate(selectedDate.getDate() + 1)));
  };

  const storesFromLS = availableStoreCodes;
  const storesOptions = storesFromLS.map((store) => {
    return { value: store, label: store };
  });

  if (storesFromLS && storesFromLS.length && !selectedStore) {
    setSelectedStore({ value: storesFromLS[0], label: storesFromLS[0] });
  }

  return (
    <div>
      {/* {isStoreSummaryLoading && <PageLoader />} */}
      {isSidebarOpen && (
        <Sidebar
          sidebar={<SidebarContent />}
          open={isSidebarOpen}
          pullRight
          styles={{ sidebar: SIDEBAR_STYLING }}
          shadow={false}
        />
      )}
      <Navbar toggleSidebarAction={toggleSidebarAction} />
      <div className="mt-3 d-flex justify-content-between container">
        <div className="d-flex align-items-center">
          <div className="admin-store-summary__date-picker">
            <span
              className="admin-store-summary__date-picker-arrows"
              onClick={goToPrevDate}
            >
              <BsCaretLeftFill />
            </span>
            <DatePicker
              className="form-control forecast-header__date-picker"
              selected={selectedDate}
              onChange={(update) => {
                setSelectedDate(update);
              }}
              onChangeRaw={(e) => e.preventDefault()}
              maxDate={new Date().addDays(10)}
            />
            <span
              className="admin-store-summary__date-picker-arrows"
              onClick={goToNextDate}
            >
              <BsCaretRightFill />
            </span>
          </div>

          <div>
            <Select
              value={selectedStore}
              options={storesOptions}
              className="admin-forecast-header__dropdown"
              style={{ zIndex: 9999 }}
              onChange={(value) => setSelectedStore(value)}
            />
          </div>
        </div>

        <div>
          <button
            disabled={storeSummary.length === 0}
            className="btn btn-dark"
            onClick={() => {
              // CUSTOM HEADER
              const header = {
                itemId: "Item Id",
                itemName: "Item Name",
                productionUnit: "Production Unit",
                forcast: "Actual Forecast",
                adminForcast: "Admin Forecast",
                cooked: "Cooked",
                wasted: "Wasted(declared)",
                perWasted: "% Wasted (declared)",
                sold: "Sold",
                underOverProduce: "Underproduced / Overproduced",
                perUnderOverProduce: "% Underproduced / Overproduced",
                perSold: "% Sold Fresh / Produced",
                soldFresh: "Sold Fresh",
                perSoldFresh: "% Fresh / Sold",
                perProduced: "% Produced / Forecast"
              };

              const data = storeSummary.map((obj) => {
                const newObj = {};
                Object.keys(header).forEach((item) => {
                  newObj[header[item]] = obj[item];
                });
                return newObj;
              });

              exportToExcel(data, "admin_store_summary.xlsx");
            }}
          >
            Export to Excel
          </button>
        </div>
      </div>

      <div className="d-flex justify-content-center align-items-center mt-3">
        <table
          className="table table-striped table-bordered admin-store-summary__table-container"
          style={{ fontSize: "14px" }}
        >
          <thead>
            <tr>
              <th>Item Id</th>
              <th>Item Name</th>
              <th>Production Unit</th>
              <th>Actual Forecast</th>
              <th>Admin Forcast</th>
              <th>Cooked </th>
              <th>Wasted(declared)</th>
              <th>% Wasted (declared)</th>
              <th>Sold</th>
              <th>Underproduced / Overproduced</th>
              <th>% Underproduced / Overproduced</th>
              <th>% Sold Fresh / Produced</th>
              <th>Sold Fresh</th>
              <th>% Fresh / Sold</th>
              <th>% Produced / Forecast</th>
            </tr>
          </thead>
          {isStoreSummaryLoading ? (
            <AdminStoreSummaryTableLoader />
          ) : (
            <tbody>
              {storeSummary.length ? (
                storeSummary.map((item, index) => (
                  <tr key={index}>
                    <td>{item.itemId}</td>
                    <td>{item.itemName}</td>
                    <td>{item.productionUnit}</td>
                    <td style={{ textAlign: "right" }}>{item.forcast}</td>
                    <td style={{ textAlign: "right" }}>{item.adminForcast}</td>
                    <td style={{ textAlign: "right" }}>{item.cooked}</td>
                    <td style={{ textAlign: "right" }}>{item.wasted} </td>
                    <td style={{ textAlign: "right" }}>{item.perWasted} %</td>
                    <td style={{ textAlign: "right" }}>{item.sold}</td>
                    <td style={{ textAlign: "right" }}>
                      {item.underOverProduce}
                    </td>
                    <td style={{ textAlign: "right" }}>
                      {item.perUnderOverProduce} %
                    </td>
                    <td style={{ textAlign: "right" }}>{item.perSold} %</td>
                    <td style={{ textAlign: "right" }}>{item.soldFresh}</td>
                    <td style={{ textAlign: "right" }}>
                      {item.perSoldFresh} %
                    </td>
                    <td style={{ textAlign: "right" }}>{item.perProduced} %</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={15} align="center">
                    No data available
                  </td>
                </tr>
              )}
            </tbody>
          )}
        </table>
      </div>
    </div>
  );
};

AdminStoreSummary.defaultProps = {
  toggleSidebarAction: noop,
  isSidebarOpen: false,
  getStoreSummary: noop,
  isStoreSummaryLoading: false,
  storeSummary: []
};

AdminStoreSummary.propTypes = {
  toggleSidebarAction: PropTypes.func,
  isSidebarOpen: PropTypes.bool,
  getStoreSummary: PropTypes.func,
  isStoreSummaryLoading: PropTypes.bool,
  storeSummary: PropTypes.array,
  getAvailableStoreCodes: PropTypes.func,
  availableStoreCodes: PropTypes.array
};

const mapStateToProps = (state) => ({
  isSidebarOpen: isSidebarOpenSelector(state),
  isStoreSummaryLoading: isAdminStoreSummaryLoadingSelector(state),
  storeSummary: adminStoreSummarySelector(state),
  availableStoreCodes: getAvailableStoreCodesSelector(state)
});

const mapDispatchToProps = {
  toggleSidebarAction: toggleSidebarAction,
  getStoreSummary: getAdminStoreSummaryAction,
  getAvailableStoreCodes: getAvailableStoreCodesAction
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminStoreSummary);
