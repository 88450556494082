export const GET_ACTIVE_INACTIVE_STORES = "GET_ACTIVE_INACTIVE_STORES";
export const GET_ACTIVE_INACTIVE_STORES_REQUEST =
  "GET_ACTIVE_INACTIVE_STORES_REQUEST";
export const GET_ACTIVE_INACTIVE_STORES_SUCCESS =
  "GET_ACTIVE_INACTIVE_STORES_SUCCESS";
export const GET_ACTIVE_INACTIVE_STORES_FAILURE =
  "GET_ACTIVE_INACTIVE_STORES_FAILURE";

export const GET_STORE_SALES_HISTORY_REQUEST =
  "GET_STORE_SALES_HISTORY_REQUEST";
export const GET_STORE_SALES_HISTORY_SUCCESS =
  "GET_STORE_SALES_HISTORY_SUCCESS";
export const GET_STORE_SALES_HISTORY_FAILURE =
  "GET_STORE_SALES_HISTORY_FAILURE";

export const GET_STORES_LAST_TX_REQUEST = "GET_STORES_LAST_TX_REQUEST";
export const GET_STORES_LAST_TX_SUCCESS = "GET_STORES_LAST_TX_SUCCESS";
export const GET_STORES_LAST_TX_FAILURE = "GET_STORES_LAST_TX_FAILURE";

export const GET_TOTAL_STORE_SALES_REQUEST = "GET_TOTAL_STORE_SALES_REQUEST";
export const GET_TOTAL_STORE_SALES_SUCCESS = "GET_TOTAL_STORE_SALES_SUCCESS";
export const GET_TOTAL_STORE_SALES_FAILURE = "GET_TOTAL_STORE_SALES_FAILURE";

export const GET_STORES_CURRENT_SALES_REQUEST =
  "GET_STORES_CURRENT_SALES_REQUEST";
export const GET_STORES_CURRENT_SALES_SUCCESS =
  "GET_STORES_CURRENT_SALES_SUCCESS";
export const GET_STORES_CURRENT_SALES_FAILURE =
  "GET_STORES_CURRENT_SALES_FAILURE";

export const GET_AVAILABLE_STORE_CODES_REQUEST =
  "GET_AVAILABLE_STORE_CODES_REQUEST";
export const GET_AVAILABLE_STORE_CODES_SUCCESS =
  "GET_AVAILABLE_STORE_CODES_SUCCESS";
export const GET_AVAILABLE_STORE_CODES_FAILURE =
  "GET_AVAILABLE_STORE_CODES_FAILURE";
