export const GET_ADMIN_CREATE_BATCH_JOB_STATUS_REQUEST = "GET_ADMIN_CREATE_BATCH_JOB_STATUS_REQUEST";
export const GET_ADMIN_CREATE_BATCH_JOB_STATUS_SUCCESS = "GET_ADMIN_CREATE_BATCH_JOB_STATUS_SUCCESS";
export const GET_ADMIN_CREATE_BATCH_JOB_STATUS_FAILURE = "GET_ADMIN_CREATE_BATCH_JOB_STATUS_FAILURE";

export const UPDATE_ADMIN_CREATE_BATCH_JOB_STATUS_REQUEST = "UPDATE_ADMIN_CREATE_BATCH_JOB_STATUS_REQUEST";

export const GET_ADMIN_DATA_SYNC_JOB_STATUS_REQUEST = "GET_ADMIN_DATA_SYNC_JOB_STATUS_REQUEST";
export const GET_ADMIN_DATA_SYNC_JOB_STATUS_SUCCESS = "GET_ADMIN_DATA_SYNC_JOB_STATUS_SUCCESS";
export const GET_ADMIN_DATA_SYNC_JOB_STATUS_FAILURE = "GET_ADMIN_DATA_SYNC_JOB_STATUS_FAILURE";

export const UPDATE_ADMIN_DATA_SYNC_JOB_STATUS_REQUEST = "UPDATE_ADMIN_DATA_SYNC_JOB_STATUS_REQUEST";

export const GET_ADMIN_SALES_15_MIN_JOB_STATUS_REQUEST = "GET_ADMIN_SALES_15_MIN_JOB_STATUS_REQUEST";
export const GET_ADMIN_SALES_15_MIN_JOB_STATUS_SUCCESS = "GET_ADMIN_SALES_15_MIN_JOB_STATUS_SUCCESS";
export const GET_ADMIN_SALES_15_MIN_JOB_STATUS_FAILURE = "GET_ADMIN_SALES_15_MIN_JOB_STATUS_FAILURE";

export const UPDATE_ADMIN_SALES_15_MIN_JOB_STATUS_REQUEST = "UPDATE_ADMIN_SALES_15_MIN_JOB_STATUS_REQUEST";

export const GET_ADMIN_SALES_HISTORY_JOB_STATUS_REQUEST = "GET_ADMIN_SALES_HISTORY_JOB_STATUS_REQUEST";
export const GET_ADMIN_SALES_HISTORY_JOB_STATUS_SUCCESS = "GET_ADMIN_SALES_HISTORY_JOB_STATUS_SUCCESS";
export const GET_ADMIN_SALES_HISTORY_JOB_STATUS_FAILURE = "GET_ADMIN_SALES_HISTORY_JOB_STATUS_FAILURE";

export const UPDATE_ADMIN_SALES_HISTORY_JOB_STATUS_REQUEST = "UPDATE_ADMIN_SALES_HISTORY_JOB_STATUS_REQUEST";
