import {
  GET_ADMIN_ITEMS_FAILURE,
  GET_ADMIN_ITEMS_REQUEST,
  GET_ADMIN_ITEMS_SUCCESS
} from "../types/admin.items";

const initialState = {
  loadingAdminItems: false,
  adminItems: []
};

export const adminItemsReducer = (
  state = initialState,
  { type, payload }
) => {
  switch (type) {
    case GET_ADMIN_ITEMS_REQUEST:
      return {
        ...state,
        loadingAdminItems: true,
        adminItems: []
      };
    case GET_ADMIN_ITEMS_SUCCESS:
      return { ...state, loadingAdminItems: false, adminItems: payload };
    case GET_ADMIN_ITEMS_FAILURE:
      return { ...state, loadingAdminItems: false, adminItems: payload };
    default:
      return state;
  }
};
