import React, { useEffect, useState } from "react";
import Navbar from "../../components/Navbar";
import Sidebar from "react-sidebar";
import PropTypes, { bool } from "prop-types";
import SidebarContent from "../../components/SidebarContent/SidebarContent";
import { connect } from "react-redux";
import { noop } from "lodash";
import { toggleSidebarAction } from "../../redux/actions/dashboard";
import { isSidebarOpenSelector } from "../../redux/selectors/dashboard";
import AdminStoresTable from "../../containers/AdminStoresTable/AdminStoresTable";
import {
  adminStoresSelector,
  isAdminStoresLoadingSelector
} from "../../redux/selectors/admin.stores";
import { getAdminStoresAction } from "../../redux/actions/admin.stores";
import { isSubstringIgnoreCase } from "../../utils/helpers";
import { SIDEBAR_STYLING } from "../../configs/constants/constants";

const AdminStores = ({
  toggleSidebar,
  isSidebarOpen,
  adminStoresData,
  getAdminStores,
  isAdminStoresLoading
}) => {
  const [isAddingNewStore, setIsAddingNewStore] = useState(false);
  const [search, setSearch] = useState("");
  const [tableData, setTableData] = useState(adminStoresData);

  useEffect(() => {
    getAdminStores();
  }, []);

  useEffect(() => {
    let filteredData = adminStoresData.filter((item) =>
      isSubstringIgnoreCase(JSON.stringify(item), search)
    );
    setTableData(filteredData);
  }, [search, adminStoresData]);

  return (
    <div>
      <Navbar toggleSidebarAction={toggleSidebar} />
      {isSidebarOpen && (
        <Sidebar
          sidebar={<SidebarContent />}
          open={isSidebarOpen}
          pullRight
          styles={{ sidebar: SIDEBAR_STYLING }}
          shadow={false}
        />
      )}
      <div className="mt-3 d-flex justify-content-between container">
        <div style={{ width: "20%" }}>
          <input
            className="form-control"
            type="text"
            placeholder="Search"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
        <button
          disabled={isAdminStoresLoading}
          className="btn btn-dark"
          onClick={() => {
            setIsAddingNewStore(true);
          }}
        >
          Add new store
        </button>
      </div>
      <div className="container mt-3">
        <AdminStoresTable
          adminStores={tableData}
          isAdminStoresLoading={isAdminStoresLoading}
          isAddingNewStore={isAddingNewStore}
          setIsAddingNewStore={setIsAddingNewStore}
        />
      </div>
    </div>
  );
};

AdminStores.defaultProps = {
  toggleSidebar: noop,
  isSidebarOpen: false,
  adminStoresData: [],
  getAdminStores: noop,
  isAdminStoresLoading: bool
};

AdminStores.propTypes = {
  toggleSidebar: PropTypes.func,
  isSidebarOpen: PropTypes.bool,
  adminStoresData: PropTypes.array,
  getAdminStores: PropTypes.func,
  isAdminStoresLoading: PropTypes.bool
};

const mapStateToProps = (state) => ({
  isSidebarOpen: isSidebarOpenSelector(state),
  adminStoresData: adminStoresSelector(state),
  isAdminStoresLoading: isAdminStoresLoadingSelector(state)
});

const mapDispatchToProps = {
  toggleSidebar: toggleSidebarAction,
  getAdminStores: getAdminStoresAction
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminStores);
