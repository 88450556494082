import {
  DELETE_ADMIN_ITEM_REQUEST,
  GET_ADMIN_ITEMS_REQUEST,
  UPDATE_ADMIN_ITEM_REQUEST
} from "../types/admin.items";

export const getAdminItemsAction = () => {
  return {
    type: GET_ADMIN_ITEMS_REQUEST
  };
};

export const updateAdminItemAction = (payload) => {
  return {
    type: UPDATE_ADMIN_ITEM_REQUEST,
    payload
  };
};

export const deleteAdminItemAction = (payload) => {
  return {
    type: DELETE_ADMIN_ITEM_REQUEST,
    payload
  };
};
