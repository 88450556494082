import {
  CONNECT_SIGNALR,
  GET_ACTIVE_ITEMS_FAILURE,
  GET_ACTIVE_ITEMS_REQUEST,
  GET_ACTIVE_ITEMS_SUCCESS,
  GET_BATCH,
  GET_BATCH_FAILURE,
  GET_BATCH_SUCCESS,
  GET_SALES_TREND_REQUEST,
  GET_WASTE_REASON_REQUEST,
  GET_WASTE_REASON_SUCCESS,
  SAVE_SIGNALR_CONNECTION,
  SAVE_TREND_DATA,
  UPDATE_QTY_IN_BATCH,
  UPDATE_TO_DROP_DATA_IN_ACTIVE_ITEMS
} from "../types/dashboard";
import { call, takeLatest, put, select, takeEvery } from "redux-saga/effects";
import {
  getActiveItemsApi,
  getBatchAPI,
  getItemDropQtyAPI,
  getSalesTrendAPI,
  getWasteReasonAPI
} from "../../api/dashboard";
import { getAccessTokenFromLocalStorage, sortBatch } from "../../utils/helpers";
import { toast } from "react-toastify";
import { find } from "lodash";
import { HubConnectionBuilder } from "@microsoft/signalr";
import { SIGNAL_R_BASE_URL } from "../../configs/constants/constants";
import { getActiveItemsSelector } from "../selectors/dashboard";

function* watchDashboardSaga() {
  /**
   * GET ACTIVE BATCH
   */

  yield takeLatest(GET_ACTIVE_ITEMS_REQUEST, function* getActiveBatch() {
    try {
      const activeItemData = yield call(getActiveItemsApi);
      if (activeItemData) {
        // Get Item Drop Qty
        const itemDropQty = yield call(getItemDropQtyAPI);
        activeItemData.forEach((item) => {
          const itemDropObj = itemDropQty.find(
            (obj) => obj.itemId === item.itemId
          );
          item.toDrop = itemDropObj.itemQty;
        });

        yield put({ type: GET_ACTIVE_ITEMS_SUCCESS, payload: activeItemData });
        try {
          const data = yield call(getBatchAPI);
          if (data) {
            data.forEach((object) => {
              const findObj = find(activeItemData, { itemId: object.itemId });
              findObj.data.push(object);
            });

            yield put({ type: GET_BATCH_SUCCESS, payload: sortBatch(data) });
          }
        } catch (e) {
          yield put({ type: GET_BATCH_FAILURE });
          toast.error("Somthing went wrong while fetching active items");
        }
      }
    } catch (e) {
      yield put({ type: GET_ACTIVE_ITEMS_FAILURE });
      toast.error("Somthing went wrong while fetching active batch");
    }
  });

  /**
   * UPDATE TO DROP DATA FROM SIGNAL R
   */
  yield takeEvery(
    UPDATE_TO_DROP_DATA_IN_ACTIVE_ITEMS,
    function* updateToDrop({ payload }) {
      try {
        // Update to drop data in redux
        const activeItems = yield select(getActiveItemsSelector);
        const indexOfActiveItem = activeItems.findIndex(
          (obj) => obj.itemId === payload.itemId
        );
        const latestQty = payload.batchQty;
        if (indexOfActiveItem !== -1) {
          activeItems[indexOfActiveItem].toDrop = latestQty > 0 ? latestQty : 0;
          yield put({ type: GET_ACTIVE_ITEMS_SUCCESS, payload: activeItems });
        }
      } catch (e) {
        yield toast.error("Error while updating to drop qty");
      }
    }
  );

  /**
   * GET BATCH DATA SAGA
   */
  yield takeLatest(GET_BATCH, function* getBatch() {
    try {
      const data = yield call(getBatchAPI);
      if (data) {
        yield put({ type: GET_BATCH_SUCCESS, payload: sortBatch(data) });
      }
    } catch (e) {
      yield put({ type: GET_BATCH_FAILURE });
      toast.error("Somthing went wrong while fetching batch");
    }
  });

  /**
   * GET WASTE REASONS
   */
  yield takeLatest(GET_WASTE_REASON_REQUEST, function* getWasteReasons() {
    try {
      const data = yield call(getWasteReasonAPI);
      if (data && data.length) {
        yield put({ type: GET_WASTE_REASON_SUCCESS, payload: data });
      }
    } catch (e) {
      // yield put({ type: GET_WASTE_REASON_FAILURE });
      toast.error("Somthing went wrong while fetching waste reason");
    }
  });

  /**
   * CONNECT TO SIGNALR
   */
  yield takeLatest(CONNECT_SIGNALR, function* connectSignalR() {
    try {
      const userToken = getAccessTokenFromLocalStorage();
      const newConnection = new HubConnectionBuilder()
        .withUrl(`${SIGNAL_R_BASE_URL}/Notification`, {
          accessTokenFactory: () => userToken
        })
        .withAutomaticReconnect()
        .build();

      yield put({ type: SAVE_SIGNALR_CONNECTION, payload: newConnection });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error("Error while connecting to SignalR", e);
    }
  });

  /**
   * UPDATE QTY IN BATCH
   */
  yield takeLatest(UPDATE_QTY_IN_BATCH, function* updateQty(payload) {
    const payloadObj = payload.payload;
    const activeItems = yield select(getActiveItemsSelector);
    const activeItemsCopy = JSON.parse(JSON.stringify(activeItems));
    activeItemsCopy.forEach((activeItem) => {
      if (activeItem.data && activeItem.data.length) {
        activeItem.data.forEach((itemObj) => {
          if (itemObj.batchNo === Number(payloadObj.batchID)) {
            const newQuantity = itemObj.batchQty - Number(payloadObj.batchQty);
            itemObj.batchQty = newQuantity;
          }
        });
      }
    });
    yield put({ type: GET_ACTIVE_ITEMS_SUCCESS, payload: activeItemsCopy });
  });

  /**
   * GET SALES TREND DATA
   */
  yield takeLatest(GET_SALES_TREND_REQUEST, function* getSalesTrend() {
    const data = yield call(getSalesTrendAPI);
    yield put({ type: SAVE_TREND_DATA, payload: data });
  });
}

export default function* dashboardSaga() {
  yield call(watchDashboardSaga);
}
