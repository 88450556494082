import React from "react";
import { Redirect, Route } from "react-router-dom";
import PropTypes from "prop-types";
import { getUserRole, parseJwt } from "../../utils/helpers";

const ceoRoutes = ["/", "/daily-business-summary"];
const adminRoutes = [
  "/",
  "/admin-forecast-evaluator",
  "/admin-store-summary",
  "/admin-forecast-accuracy",
  "/admin-current-sales",
  "/admin-items",
  "/admin-stores",
  "/admin-store-users",
  "/add-store",
  "/add-manager",
  "/admin-list-users",
  "/job-status/:jobType"
];

function ProtectedRoute({ component: Component, path, ...restOfProps }) {
  let isAuthenticated = localStorage.getItem("user");

  // CHECK IF TOKEN EXPIRED
  if (isAuthenticated) {
    const parsedToken = parseJwt(isAuthenticated);

    if (parsedToken.exp * 1000 < new Date().getTime()) {
      localStorage.clear();
      isAuthenticated = null;
    }
  }

  const userRole = getUserRole();
  { { console.log("userRole==>", userRole) } }
  if (userRole?.toLowerCase() === "admin") {
    if (!adminRoutes.includes(path)) {
      return (
        <Route
          {...restOfProps}
          render={() => <Redirect to="/access-denied" />}
        />
      );
    }
  }

  if (userRole?.toLowerCase() === "ceo") {
    if (path === "/") {
      return (
        <Route
          {...restOfProps}
          render={() => <Redirect to="/daily-business-summary" />}
        />
      );
    }
    if (!ceoRoutes.includes(path)) {
      return (
        <Route
          {...restOfProps}
          render={() => <Redirect to="/access-denied" />}
        />
      );
    }
  }


  return (
    <Route
      {...restOfProps}
      render={(props) =>
        isAuthenticated ? <Component {...props} /> : <Redirect to="/login" />
      }
    />
  );
}

ProtectedRoute.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  component: PropTypes.any
};

export default ProtectedRoute;
