import React, { useState } from "react";
import Modal from "react-modal";
import { MdClose } from "react-icons/md";
import PropTypes, { bool } from "prop-types";
import { noop } from "lodash";
import "./ResetPasswordModal.scss";

const ResetPasswordModal = ({
  isResetPasswordModalOpen,
  handleModalClose,
  email,
  resetPassword
}) => {
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleSubmit = () => {
    if (!newPassword || !confirmPassword) {
      setErrorMessage("Both fields are required.");
      return;
    }

    if (newPassword !== confirmPassword) {
      setErrorMessage("Passwords do not match.");
      return;
    }

    resetPassword({
      email, // Assuming you have a user ID in your user object
      password: newPassword
    });

    handleModalClose();
  };

  const customResetModalStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      padding: 0,
      width: "380px"
    }
  };

  return (
    <Modal
      isOpen={isResetPasswordModalOpen}
      style={customResetModalStyles}
      ariaHideApp={false}
      shouldCloseOnOverlayClick
      onRequestClose={handleModalClose}
    >
      <div className="container p-3 reset-password-modal">
        <MdClose
          className="reset-password-modal__close_icon"
          size="1.5em"
          color="black"
          onClick={handleModalClose}
        />
        <div className="row mt-3">
          <p className="text-center">
            Reset Password for: <strong>{email}</strong>
          </p>
          <div className="mb-3">
            <label className="form-label">
              New Password<span className="text-danger">*</span>
            </label>
            <input
              className="form-control"
              type="text"
              id="new-password"
              name="new-password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
          </div>
          <div className="mb-3">
            <label className="form-label">
              Confirm Password<span className="text-danger">*</span>
            </label>
            <input
              className="form-control"
              type="password"
              id="confirm-password"
              name="confirm-password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
          </div>
        </div>
        <p className="text-center text-danger reset-password-modal__warning_text">
          {errorMessage}
        </p>
        <div className="row">
          <div className="col-md-12 text-center">
            <button className="btn btn-dark mx-2" onClick={handleSubmit}>
              Reset Password
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

ResetPasswordModal.defaultProps = {
  resetPassword: noop,
  isResetPasswordModalOpen: bool,
  handleModalClose: noop,
  email: ""
};

ResetPasswordModal.propTypes = {
  resetPassword: PropTypes.func,
  isResetPasswordModalOpen: PropTypes.bool,
  handleModalClose: PropTypes.func,
  email: PropTypes.string
};

export default ResetPasswordModal;
