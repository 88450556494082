import React from "react";
import PropTypes from "prop-types";

import { flatten } from "lodash";

import CollapsableRowForAdminForecast from "../CollapsableRowForAdminForecast/CollapsableRowForAdminForecast";

const CollapsableRowParentForAdminForecast = ({
  data
}) => {
  const flattenData = flatten(data);

  return (
    <>
      <tr
        style={{ paddingLeft: "16px", color: "#FFFFFF" }}
        className={`graph-table-view__${flattenData[0].dayPeriod}`}
      >
        <td>{flattenData[0].dayPeriod}</td>
        <td style={{ textAlign: "right" }}>
          Rs.{" "}
          {flattenData.reduce((a, b) => {
            return a + b.lastYearSale;
          }, 0)}
        </td>
        <td style={{ textAlign: "right" }}>
          Rs.{" "}
          {flattenData.reduce((a, b) => {
            return a + b.forcastedSale;
          }, 0)}
        </td>
        <td style={{ textAlign: "right" }}>
          {`Rs. ${flattenData.reduce((a, b) => {
            return a + b.adminEdit;
          }, 0)}`}
        </td>
      </tr>
      {data.map((obj, index) => (
        <CollapsableRowForAdminForecast
          key={index}
          data={obj}
        />
      ))}
    </>
  );
};

CollapsableRowParentForAdminForecast.defaultProps = {
  data: []
};

CollapsableRowParentForAdminForecast.propTypes = {
  data: PropTypes.array
};

export default CollapsableRowParentForAdminForecast;
