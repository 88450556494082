import {
  GET_ADMIN_STORES_FAILURE,
  GET_ADMIN_STORES_REQUEST,
  GET_ADMIN_STORES_SUCCESS
} from "../types/admin.stores";

const initialState = {
  loadingAdminStores: false,
  adminStores: []
};

export const adminStoresReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_ADMIN_STORES_REQUEST:
      return {
        ...state,
        loadingAdminStores: true,
        adminStores: []
      };
    case GET_ADMIN_STORES_SUCCESS:
      return { ...state, loadingAdminStores: false, adminStores: payload };
    case GET_ADMIN_STORES_FAILURE:
      return { ...state, loadingAdminStores: false, adminStores: payload };
    default:
      return state;
  }
};
