import { call, takeLatest, put } from "redux-saga/effects";
import { toast } from "react-toastify";

import {
  GET_ACTIVE_INACTIVE_STORES_FAILURE,
  GET_ACTIVE_INACTIVE_STORES_REQUEST,
  GET_ACTIVE_INACTIVE_STORES_SUCCESS,
  GET_AVAILABLE_STORE_CODES_FAILURE,
  GET_AVAILABLE_STORE_CODES_REQUEST,
  GET_AVAILABLE_STORE_CODES_SUCCESS,
  GET_STORES_CURRENT_SALES_FAILURE,
  GET_STORES_CURRENT_SALES_REQUEST,
  GET_STORES_CURRENT_SALES_SUCCESS,
  GET_STORES_LAST_TX_FAILURE,
  GET_STORES_LAST_TX_REQUEST,
  GET_STORES_LAST_TX_SUCCESS,
  GET_STORE_SALES_HISTORY_FAILURE,
  GET_STORE_SALES_HISTORY_REQUEST,
  GET_STORE_SALES_HISTORY_SUCCESS,
  GET_TOTAL_STORE_SALES_FAILURE,
  GET_TOTAL_STORE_SALES_REQUEST,
  GET_TOTAL_STORE_SALES_SUCCESS
} from "../types/admin.dashboard";
import {
  getActiveInactiveStoresApi,
  getAvailableStoreCodesApi,
  getStoreLastTxApi,
  getStoreSalesHistoryApi,
  getStoresCurrentSalesApi,
  getTotalStoreSalesApi
} from "../../api/admin.dashboard";

function* watchAdminDashboardSaga() {
  yield takeLatest(
    GET_ACTIVE_INACTIVE_STORES_REQUEST,
    function* getActiveInactive({ payload }) {
      try {
        const data = yield call(getActiveInactiveStoresApi, payload);
        yield put({ type: GET_ACTIVE_INACTIVE_STORES_SUCCESS, payload: data });
      } catch (e) {
        yield put({ type: GET_ACTIVE_INACTIVE_STORES_FAILURE });
        toast.error(
          e?.response?.data?.value ||
            "Error while fetching data for active inactive stores"
        );
      }
    }
  );

  yield takeLatest(
    GET_STORE_SALES_HISTORY_REQUEST,
    function* getStoreSalesHistory() {
      try {
        const data = yield call(getStoreSalesHistoryApi);
        yield put({ type: GET_STORE_SALES_HISTORY_SUCCESS, payload: data });
      } catch (e) {
        yield put({ type: GET_STORE_SALES_HISTORY_FAILURE });
        toast.error(
          e?.response?.data?.value ||
            "Error while fetching data for sales history of stores"
        );
      }
    }
  );

  yield takeLatest(GET_STORES_LAST_TX_REQUEST, function* getStoreLastTx() {
    try {
      const data = yield call(getStoreLastTxApi);
      yield put({ type: GET_STORES_LAST_TX_SUCCESS, payload: data });
    } catch (e) {
      yield put({ type: GET_STORES_LAST_TX_FAILURE });
      toast.error(
        e?.response?.data?.value ||
          "Error while fetching data for stores transactions"
      );
    }
  });

  yield takeLatest(
    GET_STORES_CURRENT_SALES_REQUEST,
    function* getStoresCurrentSales({ payload }) {
      try {
        const data = yield call(getStoresCurrentSalesApi, payload);
        yield put({ type: GET_STORES_CURRENT_SALES_SUCCESS, payload: data });
      } catch (e) {
        console.error(e);
        yield put({ type: GET_STORES_CURRENT_SALES_FAILURE });
        toast.error(
          e?.response?.data?.value ||
            "Error while fetching data for stores current sales"
        );
      }
    }
  );

  yield takeLatest(
    GET_TOTAL_STORE_SALES_REQUEST,
    function* getStoreSales({ payload }) {
      try {
        const data = yield call(getTotalStoreSalesApi, payload);
        yield put({ type: GET_TOTAL_STORE_SALES_SUCCESS, payload: data });
      } catch (e) {
        console.error("log error", e);
        yield put({ type: GET_TOTAL_STORE_SALES_FAILURE });
        toast.error(
          e?.response?.data?.value ||
            "Error while fetching data for store sales"
        );
      }
    }
  );

  yield takeLatest(
    GET_AVAILABLE_STORE_CODES_REQUEST,
    function* getAvailableStoreCodes() {
      try {
        const data = yield call(getAvailableStoreCodesApi);
        yield put({ type: GET_AVAILABLE_STORE_CODES_SUCCESS, payload: data });
      } catch (e) {
        console.error(e);
        yield put({ type: GET_AVAILABLE_STORE_CODES_FAILURE });
        toast.error(
          e?.response?.data?.value || "Error while fetching store codes"
        );
      }
    }
  );
}

export default function* adminDashboardSaga() {
  yield call(watchAdminDashboardSaga);
}
