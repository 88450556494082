import { createSelector } from "reselect";

const adminStoreSummaryState = (state) => state.adminStoreSummary;

/**
 * STORE SUMMARY DATA : GET
 */
export const adminStoreSummarySelector = createSelector(
    adminStoreSummaryState,
  (SS) => SS.adminStoreSummary
);

/**
 * IS STORE SUMMARY LOADING
 */
export const isAdminStoreSummaryLoadingSelector = createSelector(
    adminStoreSummaryState,
  (SS) => SS.loadingAdminStoreSummary
);
